import { useQuery } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { useSnackbars } from '@amalia/design-system/components';
import { PeriodsApiClient } from '@amalia/payout-definition/state';

import { PERIOD_QUERY_KEYS } from './query-keys';

export const usePeriods = () => {
  const { snackError } = useSnackbars();

  const {
    data: periods,
    isError,
    error,
    ...rest
  } = useQuery({
    queryKey: [PERIOD_QUERY_KEYS.PERIODS],
    queryFn: () => PeriodsApiClient.getAllPeriods(),

    staleTime: Infinity,
  });

  useEffect(() => {
    if (isError) {
      snackError(error.message);
    }
  }, [isError, error, snackError]);

  const { periodsMap, periodsList } = useMemo(
    () => ({
      periodsMap: keyBy(periods || [], 'id'),
      periodsList: (periods || []).sort((p1, p2) => (p1.startDate > p2.startDate ? 1 : -1)),
    }),
    [periods],
  );

  return {
    periodsMap,
    periodsList,
    ...rest,
  };
};
