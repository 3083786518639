import { memo, useRef, type ReactNode } from 'react';

import { AlertBanner } from '@amalia/design-system/components';
import { useMountEffect } from '@amalia/ext/react/hooks';

export type SubmissionErrorBannerProps = {
  readonly children: ReactNode;
};

export const SubmissionErrorBanner = memo(function SubmissionErrorBanner({ children }: SubmissionErrorBannerProps) {
  const ref = useRef<HTMLDivElement>(null);

  // User might be at the bottom of the modal, so scroll to the top to show the error banner.
  // :david-goodenough:
  useMountEffect(() => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
    });
  });

  return (
    <AlertBanner
      ref={ref}
      inline
      variant={AlertBanner.Variant.ERROR}
    >
      {children}
    </AlertBanner>
  );
});
