import { type Node } from 'prosemirror-model';

import { TokenType } from '@amalia/amalia-lang/tokens/types';

import { FORMULA_TOKEN_NODE_NAME } from '../../components/formula-editor-content/token-extension/TokenExtension';
import { type FormulaEditorToken } from '../../types/formulaEditorToken';

import { traverseTextNodes } from './traverse-text-nodes';

export type FunctionBoundaries = {
  functionNode?: Node;
  functionNodePosition?: number;
  openingParenthesisPosition: { nodePosition: number; index: number };
  closingParenthesisPosition?: { nodePosition: number; index: number };
};

/**
 * Parse editor document to find function boundaries (function node positions with their respective parenthesis).
 */
export class FunctionBoundariesParser {
  private readonly parenthesisBoundaries: FunctionBoundaries[] = [];

  public constructor(
    private readonly doc: Node,
    private readonly tokens: FormulaEditorToken[],
  ) {}

  public parse() {
    traverseTextNodes(this.doc, (node, nodePosition) => {
      node.text!.split('').forEach((c, index) => {
        // Open function boundary.
        // Check if c is not between quotes.
        if (c === '(' && !this.isCharAtIndexEnclosedInConstant(node.text!, index)) {
          this.openFunctionBoundary(nodePosition, index);
        }

        // Close function boundary.
        if (c === ')' && !this.isCharAtIndexEnclosedInConstant(node.text!, index)) {
          this.closeFunctionBoundary(nodePosition, index);
        }
      });
    });

    return this.parenthesisBoundaries;
  }

  private openFunctionBoundary(nodePosition: number, index: number) {
    this.parenthesisBoundaries.push({
      openingParenthesisPosition: { nodePosition, index },
    });
  }

  private closeFunctionBoundary(nodePosition: number, characterIndex: number) {
    const openedParenthesisBoundaries = this.parenthesisBoundaries.filter(
      (pb) => pb.closingParenthesisPosition === undefined,
    );
    const parenthesisBoundaryToClose = openedParenthesisBoundaries.length
      ? openedParenthesisBoundaries[openedParenthesisBoundaries.length - 1]
      : undefined;
    if (!parenthesisBoundaryToClose) {
      return;
    }

    parenthesisBoundaryToClose.closingParenthesisPosition = { nodePosition, index: characterIndex };

    const functionNodePosition = parenthesisBoundaryToClose.openingParenthesisPosition.nodePosition - 1;
    const previousNode = this.findFunctionNodeAtPosition(functionNodePosition);
    if (previousNode) {
      parenthesisBoundaryToClose.functionNode = previousNode;
      parenthesisBoundaryToClose.functionNodePosition = functionNodePosition;
    }
  }

  private findFunctionNodeAtPosition(position: number) {
    const previousNode = this.doc.nodeAt(position);
    if (previousNode?.type.name !== FORMULA_TOKEN_NODE_NAME) {
      return undefined;
    }

    const previousToken = this.tokens.find((t) => t.formula === previousNode.attrs.formula);
    if (previousToken?.type !== TokenType.FUNCTION) {
      return undefined;
    }

    return previousNode;
  }

  private isCharAtIndexEnclosedInConstant(text: string, index: number) {
    return text.slice(0, index).includes('"') && text.slice(index + 1, text.length).includes('"');
  }
}
