import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type Plan, type Rule } from '@amalia/payout-definition/plans/types';

import { PLAN_QUERY_KEYS } from '../queries.keys';

import { FiltersApiClient } from './filters.api-client';
import { FILTERS_QUERY_KEYS } from './filters.keys';

export const useFilters = (planId?: string) =>
  useQuery({
    queryKey: [FILTERS_QUERY_KEYS.FILTERS, planId],
    queryFn: () => FiltersApiClient.list(planId),
  });

export const useCreateFilter = ({ planId, ruleId }: { planId: string; ruleId: string }) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [FILTERS_QUERY_KEYS.FILTERS, 'create'],
    mutationFn: FiltersApiClient.create,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [FILTERS_QUERY_KEYS.FILTERS] });
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.RULE_V2, 'configuration', planId, ruleId] });
      snackSuccess(formatMessage({ defaultMessage: 'Table created' }));
    },
    onError: (error) => {
      snackError(
        formatMessage({ defaultMessage: `Error while creating filter: {message}` }, { message: error.message }),
      );
    },
  });
};

export const usePatchFilter = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [FILTERS_QUERY_KEYS.FILTERS, 'patch'],
    mutationFn: FiltersApiClient.patch,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [FILTERS_QUERY_KEYS.FILTERS] });
      snackSuccess(formatMessage({ defaultMessage: 'Table updated' }));
    },
    onError: (error) => {
      snackError(
        formatMessage({ defaultMessage: `Error while updating table: {message}` }, { message: error.message }),
      );
    },
  });
};

export const useDeleteFilter = ({ planId, ruleId }: { planId: Plan['id']; ruleId: Rule['id'] }) => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [FILTERS_QUERY_KEYS.FILTERS, 'delete'],
    mutationFn: FiltersApiClient.delete,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Table deleted successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.RULE_V2, 'configuration', planId, ruleId] });
      await queryClient.invalidateQueries({ queryKey: [FILTERS_QUERY_KEYS.FILTERS] });
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occured while deleting the table: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};
