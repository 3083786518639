import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';

import { PLAN_QUERY_KEYS } from '../queries.keys';

import { RulesApiClient } from './rules.api-client';

export const useRulePlanConfiguration = (planId: string, ruleId: string) =>
  useQuery({
    queryKey: [PLAN_QUERY_KEYS.RULE_V2, 'configuration', planId, ruleId],
    queryFn: () => RulesApiClient.getRuleConfiguration(planId, ruleId),
  });

export const useUpdateRuleAssignment = () => {
  const queryClient = useQueryClient();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: [PLAN_QUERY_KEYS.RULE_V2, 'configuration', 'update-rule-assignment'],
    mutationFn: RulesApiClient.updateRuleAssignment,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Rule updated successfully.' }));
      await queryClient.invalidateQueries({ queryKey: [PLAN_QUERY_KEYS.RULE_V2, 'configuration'] });
    },
  });
};
