import { css } from '@emotion/react';
import { IconPlus } from '@tabler/icons-react';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';
import { FormField, type FormFieldProps, IconButton, Table } from '@amalia/design-system/components';
import { useUniqueId } from '@amalia/ext/react/hooks';

import { useParseTableBuilderFormula } from './hooks/use-parse-table-builder-formula';
import { useTableBuilderDataGridAdapter } from './hooks/use-table-builder-data-grid-adapter';
import { rowKeySymbol, type TableBuilderDataGridRowData } from './TableBuilder.types';

export type TableBuilderProps = Pick<FormFieldProps, 'error' | 'showError'> & {
  readonly id?: string;
  readonly value: AmaliaFormula;
  readonly columns: VariableFormatOptionsColumn[];
} & (
    | {
        isReadonly: true;
        onChange?: never;
        onChangeColumns?: never;
      }
    | {
        readonly isReadonly?: false;
        readonly onChange: (formula: AmaliaFormula) => void;
        readonly onChangeColumns: (columns: VariableFormatOptionsColumn[]) => void;
      }
  );

const getRowKey = (row: TableBuilderDataGridRowData) => row[rowKeySymbol];

export const TableBuilder = memo(function TableBuilder({
  value,
  columns: propColumns,
  isReadonly = false,
  id: propId,
  error,
  showError,
  onChange,
  onChangeColumns,
}: TableBuilderProps) {
  const id = useUniqueId({ id: propId, prefix: 'field' });

  const { formatMessage } = useIntl();

  // Parse formula and sync columns.
  const {
    parsedRows,
    columns: tableBuilderColumns,
    onChange: onChangeParsedRows,
  } = useParseTableBuilderFormula({
    formula: value,
    columns: propColumns,
    onChange,
  });

  // Adapt parsed rows and columns to the DataGrid component.
  const { datagridColumns, datagridRows, onCreateRow, onCreateColumn } = useTableBuilderDataGridAdapter({
    parsedRows,
    tableBuilderColumns,
    isReadonly,
    onChange: onChangeParsedRows,
    onChangeColumns,
  });

  return (
    <FormField
      error={error}
      id={id}
      showError={showError}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: auto auto;
          grid-template-rows: auto auto;
          gap: ${!isReadonly ? '8px' : 0};
        `}
      >
        <Table
          columns={datagridColumns}
          data={datagridRows}
          id={id}
          rowKey={getRowKey}
        />

        {!isReadonly && (
          <IconButton
            withBackground
            icon={<IconPlus />}
            label={formatMessage({ defaultMessage: 'Add a column' })}
            size={IconButton.Size.SMALL}
            style={{ height: '100%' }}
            onClick={onCreateColumn}
          />
        )}

        {!isReadonly && (
          <IconButton
            withBackground
            icon={<IconPlus />}
            label={formatMessage({ defaultMessage: 'Add a row' })}
            size={IconButton.Size.SMALL}
            style={{ width: '100%' }}
            onClick={onCreateRow}
          />
        )}
      </div>
    </FormField>
  );
});
