import { type FormikProps } from 'formik';
import { memo, useCallback, useRef } from 'react';
import { type EmptyObject } from 'type-fest';
import * as Yup from 'yup';

import {
  useCreateConnector,
  useAuthenticateWithOAuth,
  useUpdateConnectorAuth,
} from '@amalia/data-capture/connectors/state';
import {
  AuthType,
  DataConnectorCategories,
  DataConnectorTypes,
  type ConnectorResponse,
  type DataConnector,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { useMountEffect } from '@amalia/ext/react/hooks';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

type OAuthConnectorAuthFormValues = EmptyObject;
const initialValues: OAuthConnectorAuthFormValues = {};

type OAuthConnectorAuthFormikProps = FormikFormProps<OAuthConnectorAuthFormValues, ConnectorResponse>;

export type OAuthConnectorAuthFormProps = Omit<
  OAuthConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly connectorType:
    | DataConnectorTypes.BIGQUERY
    | DataConnectorTypes.GOOGLESHEET
    | DataConnectorTypes.HUBSPOT
    | DataConnectorTypes.POWERBI
    | DataConnectorTypes.SALESFORCE
    | DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT
    | DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT;
  readonly dataConnector?: DataConnector;
};

export const OAuthConnectorAuthForm = memo(function OAuthConnectorAuthForm({
  connectorType,
  dataConnector,
  children,
  ...props
}: OAuthConnectorAuthFormProps) {
  const { data: company } = useCurrentCompany();
  const ref = useRef<FormikProps<OAuthConnectorAuthFormValues>>(null);

  const { mutateAsync: authenticateWithOAuth } = useAuthenticateWithOAuth(
    Yup.object().shape({
      code: Yup.string().required('code is required'),
    }),
  );

  const { mutateAsync: createConnector } = useCreateConnector();
  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth();

  const runAuthentication = useCallback(async () => {
    const oauthQueryParams = await authenticateWithOAuth({
      connectorType,
      queryParams: [
        DataConnectorTypes.SALESFORCE,
        DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
        DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT,
      ].includes(connectorType)
        ? { useSandbox: company.preferences?.connectors?.salesforce?.useSandbox ? 'true' : 'false' }
        : undefined,
    });

    return !dataConnector
      ? createConnector({
          type: connectorType,
          category: DataConnectorCategories.DATA,
          authType: AuthType.OAuth2_authorizationCode,
          auth: oauthQueryParams,
        })
      : updateConnectorAuth({
          id: dataConnector.id,
          type: connectorType,
          auth: oauthQueryParams,
        });
  }, [company, connectorType, createConnector, dataConnector, authenticateWithOAuth, updateConnectorAuth]);

  useMountEffect(() => {
    ref.current?.submitForm().catch(() => {});
  });

  return (
    <FormikForm<OAuthConnectorAuthFormValues, ConnectorResponse>
      {...props}
      ref={ref}
      enableReinitialize={false}
      initialValues={initialValues}
      onSubmit={runAuthentication}
    >
      {children}
    </FormikForm>
  );
});
