//-- Formula builder validators -- //

import { type IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { CalculationParser, type CalculationScope } from '@amalia/amalia-lang/formula/evaluate/shared';
import {
  FormulaBuilderFunctionCategory,
  type FormulaBuilder,
  FormulaBuilderBlockType,
  type FormulaBuilderFunctionBlock,
  type FormulaBuilderLogicalOperatorBlock,
  FormulaBuilderLogicalOperatorType,
  type FormulaBuilderRootBlock,
} from '@amalia/amalia-lang/formula/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

import { yupValidateCondition } from '../../formula-editor-field/yup-validators/yupValidatorAmaliaFormula';

import { yupFormulaBuilderFunctionBoolean } from './yup-validator-formula-builder-functions/yupValidatorFormulaBuilderFunctionsBoolean';
import { yupFormulaBuilderFunctionDate } from './yup-validator-formula-builder-functions/yupValidatorFormulaBuilderFunctionsDate';
import { yupFormulaBuilderFunctionNumber } from './yup-validator-formula-builder-functions/yupValidatorFormulaBuilderFunctionsNumber';
import { yupFormulaBuilderFunctionString } from './yup-validator-formula-builder-functions/yupValidatorFormulaBuilderFunctionString';
import { yupFormulaBuilderFunctionUser } from './yup-validator-formula-builder-functions/yupValidatorFormulaBuilderFunctionsUser';

const yupFormulaBuilderFormula = (
  scope: CalculationScope,
  customObjects: CustomObjectDefinition[],
  objectIdFieldName: string,
  intl: IntlShape,
) =>
  Yup.object().shape({
    formula: yupValidateCondition(
      new CalculationParser({ stubAdvancedFunctions: true }),
      scope,
      customObjects,
      objectIdFieldName,
    ).required(intl.formatMessage({ defaultMessage: 'The formula is required' })),
    id: Yup.string().required(),
    label: Yup.string().required(intl.formatMessage({ defaultMessage: 'The condition name is required' })),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FORMULA]).required(),
  });

const yupFormulaBuilderFunction = (functionBlock: FormulaBuilderFunctionBlock, intl: IntlShape) => {
  switch (functionBlock.category) {
    case FormulaBuilderFunctionCategory.DATE:
      return yupFormulaBuilderFunctionDate(functionBlock, intl);
    case FormulaBuilderFunctionCategory.STRING:
      return yupFormulaBuilderFunctionString(functionBlock);
    case FormulaBuilderFunctionCategory.USER:
      return yupFormulaBuilderFunctionUser(functionBlock, intl);
    case FormulaBuilderFunctionCategory.NUMBER:
    case FormulaBuilderFunctionCategory.PERCENT:
    case FormulaBuilderFunctionCategory.CURRENCY:
      return yupFormulaBuilderFunctionNumber(functionBlock);
    case FormulaBuilderFunctionCategory.BOOLEAN:
      return yupFormulaBuilderFunctionBoolean(functionBlock);
    default:
      throw new Error(intl.formatMessage({ defaultMessage: 'Invalid formula builder function category' }));
  }
};

const yupFormulaBuilderLogicalOperator = (
  scope: CalculationScope,
  customObjects: CustomObjectDefinition[],
  objectIdFieldName: string,
  intl: IntlShape,
): Yup.ObjectSchema<NonNullable<unknown>, FormulaBuilderLogicalOperatorBlock, NonNullable<unknown>, ''> =>
  Yup.object<FormulaBuilderLogicalOperatorBlock>().shape({
    id: Yup.string().required(),
    logicalOperator: Yup.string().oneOf(Object.values(FormulaBuilderLogicalOperatorType)).required(),
    operands: Yup.array()
      .of(
        Yup.lazy<
          | ReturnType<typeof yupFormulaBuilderFormula>
          | ReturnType<typeof yupFormulaBuilderFunction>
          | ReturnType<typeof yupFormulaBuilderLogicalOperator>
        >((operand: FormulaBuilderLogicalOperatorBlock['operands'][number]) => {
          switch (operand.type) {
            case FormulaBuilderBlockType.LOGICAL_OPERATOR:
              return yupFormulaBuilderLogicalOperator(scope, customObjects, objectIdFieldName, intl).default(
                undefined,
              ) as unknown as Yup.ObjectSchema<
                NonNullable<unknown>,
                FormulaBuilderLogicalOperatorBlock,
                NonNullable<unknown>,
                ''
              >; // Need to set the default to undefined for yup to prevent infinite loop with recursion.
            case FormulaBuilderBlockType.FORMULA:
              return yupFormulaBuilderFormula(scope, customObjects, objectIdFieldName, intl);
            case FormulaBuilderBlockType.FUNCTION:
              return yupFormulaBuilderFunction(operand, intl);
            default:
              throw new Error(intl.formatMessage({ defaultMessage: 'Invalid formula builder block type' }));
          }
        }),
      )
      .required(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.LOGICAL_OPERATOR]).required(),
  });

const yupFormulaBuilderRoot = (
  scope: CalculationScope,
  customObjects: CustomObjectDefinition[],
  objectIdFieldName: string,
  intl: IntlShape,
) =>
  Yup.object<FormulaBuilderRootBlock>().shape({
    id: Yup.string().optional(),
    logicalOperator: Yup.string().oneOf(Object.values(FormulaBuilderLogicalOperatorType)).required(),
    operands: Yup.array()
      .of(yupFormulaBuilderLogicalOperator(scope, customObjects, objectIdFieldName, intl))
      .required(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.LOGICAL_OPERATOR]).required(),
  });

export const yupValidateFormulaBuilder = (
  scope: CalculationScope,
  customObjects: CustomObjectDefinition[],
  objectIdFieldName: string,
  intl: IntlShape,
) =>
  Yup.object<FormulaBuilder>().shape({
    root: yupFormulaBuilderRoot(scope, customObjects, objectIdFieldName, intl).required(),
  });
