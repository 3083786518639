import { memo } from 'react';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { Modal, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import { OAuthLoadingIndicator } from '../../oauth/loading-indicator/OAuthLoadingIndicator';
import { ZohoConnectorAuthForm, type ZohoConnectorAuthFormProps } from '../ZohoConnectorAuthForm';

export type ZohoConnectorAuthFormModalProps = Omit<ZohoConnectorAuthFormProps, 'children'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const ZohoConnectorAuthFormModal = memo(function ZohoConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: ZohoConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ZohoConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ isSubmitting, status }) => (
          <ConnectorAuthFormModalContent
            isValid
            connectorType={DataConnectorTypes.ZOHO}
            dataConnector={props.dataConnector}
            isRunningOAuthFlow={isSubmitting}
            isSubmitting={isSubmitting}
            status={status as unknown}
          >
            <OAuthLoadingIndicator />
          </ConnectorAuthFormModalContent>
        )}
      </ZohoConnectorAuthForm>
    </Modal>
  );
});
