import { http } from '@amalia/core/http/client';
import {
  type Rule,
  type RulePlanConfiguration,
  type UpdateRuleAssignment,
} from '@amalia/payout-definition/plans/types';

const e = encodeURIComponent;

export class RulesApiClient {
  public static async update(rule: Rule): Promise<Rule> {
    const { data } = await http.put<Rule>(`/rules/${e(rule.id)}`, rule);
    return data;
  }

  public static async delete(ruleId: string): Promise<void> {
    await http.delete(`/rules/${e(ruleId)}`);
  }

  public static async list(): Promise<Rule[]> {
    const { data } = await http.get<Rule[]>(`/rules`);
    return data;
  }

  public static async create(rule: Partial<Rule>): Promise<Rule> {
    const { data } = await http.post<Rule>('/rules', rule);
    return data;
  }

  public static async getRuleConfiguration(planId: string, ruleId: string): Promise<RulePlanConfiguration> {
    const { data } = await http.get<RulePlanConfiguration>(`/plans-v2/${planId}/rules/${ruleId}`);
    return data;
  }

  public static async updateRuleAssignment({
    planId,
    ruleId,
    patch,
  }: {
    planId: string;
    ruleId: string;
    patch: UpdateRuleAssignment;
  }): Promise<RulePlanConfiguration> {
    const { data } = await http.patch<RulePlanConfiguration>(
      `/plans-v2/${e(planId)}/rule-assignments/${e(ruleId)}`,
      patch,
    );
    return data;
  }
}
