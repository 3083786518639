import { last } from 'lodash';

import {
  type WorkflowCondensedStep,
  WorkflowConditionType,
  type WorkflowDefinition,
  type WorkflowMap,
  type WorkflowOptions,
  type WorkflowStatementState,
  type WorkflowStep,
  WorkflowType,
} from '@amalia/payout-collaboration/workflows/types';
import { UserRole } from '@amalia/tenants/users/types';

import { type Statement } from '../types/statement';
import { type UserStatements } from '../types/userStatements';

export const extractStepsFromCompletedStatement = (
  statement: Statement,
): {
  steps: WorkflowCondensedStep[];
  currentStepNumber: number;
} => {
  const steps: Record<number, WorkflowCondensedStep> = {};
  (statement.workflowSteps || [])
    .filter((step: WorkflowStatementState) => step.to > step.from)
    .forEach((step: WorkflowStatementState) => {
      steps[step.from] = {
        userId: step.userId,
        date: step.date,
        stepName: step.stepName,
        completed: true,
      };
    });
  return {
    steps: Object.values(steps),
    currentStepNumber: last(statement.workflowSteps)?.to || 0,
  };
};

/**
 * Given the current statement, returns the definition of the workflow in the map.
 * @param statement
 * @param workflowMap
 */
export const getWorkflowDefinition = (
  statement: Statement,
  workflowMap: WorkflowMap,
): WorkflowDefinition | undefined => {
  const statementWorkflowId = statement.plan!.workflowId;
  // If no workflow configured, use the default.
  // The default workflow is the only one that has `undefined` for id.
  const workflowDefinition = statementWorkflowId ? workflowMap?.[statementWorkflowId] : undefined;

  // If statement is already completed and workflow definition has changed or if we have no workflow definition anymore,
  // we can not rely on definition anymore, so we rebuild one form scratch from statement workflow steps.
  if (
    statement.workflowComplete &&
    statement.workflowCompletedDate &&
    (!workflowDefinition ||
      (workflowDefinition?.updatedAt && workflowDefinition?.updatedAt > statement.workflowCompletedDate))
  ) {
    const { steps } = extractStepsFromCompletedStatement(statement);

    return {
      id: undefined,
      updatedAt: undefined,
      name: '',
      machineName: '',
      type: WorkflowType.PLAN,
      steps: steps.map((s) => ({
        ...s,
        conditions: [
          {
            conditionType: WorkflowConditionType.ROLE,
            role: UserRole.ADMIN,
          },
        ],
      })),
      workflowOptions: {} as WorkflowOptions,
    };
  }

  return workflowDefinition || workflowMap?.undefined;
};

/**
 * Compute the workflow definition and the current statement to make a renderable array of steps.
 * @param statement
 * @param workflowMap
 */
export const extractStatementWorkflowSteps = (
  statement: Statement,
  workflowMap: WorkflowMap,
): {
  steps: WorkflowCondensedStep[];
  currentStepNumber: number;
} => {
  // If statement is reviewed, then we don't fetch steps from workflow (it may have been modified)
  if (statement?.workflowComplete) {
    return extractStepsFromCompletedStatement(statement);
  }

  const workflowDefinition = getWorkflowDefinition(statement, workflowMap);

  const steps: WorkflowCondensedStep[] = (workflowDefinition?.steps || []).map((s: WorkflowStep) => ({
    stepName: s.stepName,
    date: undefined as Date | undefined,
    userId: undefined as string | undefined,
    completed: false,
  }));

  // We need to populate data only up to the last validated step. Example: 0=>1, 1=>2, 2=>1, 1=>0, 0=>1
  // We don't want to populate step 2, we're not here anymore
  const stepUpTo = last(statement.workflowSteps)?.to || 0;

  // We need to overwrite the userId and date from the last validation for this step.
  // To do this, we loop through all validations that took place, overwritting each time with the latest data
  // If step > current workflow step, we don't want to populate it
  (statement?.workflowSteps || []).forEach((s: WorkflowStatementState) => {
    if (s.to > s.from && s.to <= stepUpTo) {
      steps[s.from] = {
        ...steps[s.from],
        userId: s.userId,
        date: s.date,
        completed: true,
      };
    }
  });

  return { steps, currentStepNumber: stepUpTo };
};

export const getUserStatementsWorkflowStatus = (userStatements: UserStatements, workflowMap: WorkflowMap): number => {
  if (userStatements.lines.length === 0) {
    return 0;
  }
  if (userStatements.lines.length > 1) {
    // If we have more than one statement, make an average of completion.
    return userStatements.lines.filter((s) => s.workflowComplete).length / userStatements.lines.length;
  }
  // If we have exactly one statement, we show its progress.
  if (userStatements.lines[0].workflowComplete) {
    return 1;
  }
  const lastStepTo = last(userStatements.lines[0].workflowSteps)?.to || 0;
  const workflow = getWorkflowDefinition(userStatements.lines[0], workflowMap);
  return lastStepTo / (workflow?.steps.length || 1);
};
