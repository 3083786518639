import { useQuery } from '@tanstack/react-query';

import { QuotasWithAssignedPlansApiClient } from '@amalia/assignments/quotas/api-client';

import { QUOTAS_KEYS } from './queries.keys';

export const useQuotaWithAssignedPlans = (...args: Parameters<(typeof QuotasWithAssignedPlansApiClient)['get']>) =>
  useQuery({
    queryKey: [QUOTAS_KEYS.QUOTA_WITH_ASSIGNED_PLANS, ...args],
    queryFn: () => QuotasWithAssignedPlansApiClient.get(...args),
  });

export const useQuotasWithAssignedPlans = (...args: Parameters<(typeof QuotasWithAssignedPlansApiClient)['search']>) =>
  useQuery({
    queryKey: [QUOTAS_KEYS.QUOTAS_WITH_ASSIGNED_PLANS, ...args],
    queryFn: () => QuotasWithAssignedPlansApiClient.search(...args),
  });
