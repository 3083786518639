import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { getDataConnectorName } from '@amalia/core/types';
import { useConnectorLogout } from '@amalia/data-capture/connectors/state';
import { type DataConnector } from '@amalia/data-capture/connectors/types';
import { Modal, type ModalProps } from '@amalia/design-system/components';

export type ConnectorLogoutConfirmModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  readonly dataConnector: Pick<DataConnector, 'id' | 'type'>;
  readonly onSuccess: () => void;
};

export const ConnectorLogoutConfirmModal = memo(function ConnectorLogoutConfirmModal({
  dataConnector,
  onSuccess,
  ...props
}: ConnectorLogoutConfirmModalProps) {
  const { mutate: logout } = useConnectorLogout();

  const handleLogout = useCallback(
    () => logout(dataConnector.id, { onSuccess }),
    [dataConnector.id, logout, onSuccess],
  );

  return (
    <Modal
      {...props}
      variant={Modal.Variant.DANGER}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage="Log out from {connectorType}"
              values={{ connectorType: getDataConnectorName(dataConnector) }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage defaultMessage="Are you sure you want to log out from this connector?" />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction onClick={handleLogout}>
          <FormattedMessage defaultMessage="Confirm" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
