import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { toString } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { FormatsEnum, PropertyRef } from '@amalia/data-capture/fields/types';
import { Divider, IconLoading, Modal, ModalSize, Stack } from '@amalia/design-system/components';
import { assert } from '@amalia/ext/typescript';

import { OverwriteForm } from './form/OverwriteForm';
import { type OverwriteCreationRequestDetails } from './overwrite.types';
import { createRowChange } from './OverwriteModal.utils';
import { OverwriteOptions } from './OverwriteOption';
import { OverwriteRecapInfos } from './recapitulation/OverwriteRecapitulationInfo';

export enum OVERWRITE_CONTEXT {
  'DATA' = 'data',
  'ROWTABLE' = 'rowtable',
  'KPI' = 'kpi',
  'COMMENTDRAWER' = 'commentDrawer',
  'FILTER_ROW_REMOVE' = 'filter_row_remove',
}

export type OverwriteModalContainerProps = {
  readonly overwriteContext: OVERWRITE_CONTEXT;
  readonly isOpen: boolean;
  readonly currentObjectDetails: OverwriteCreationRequestDetails;
  readonly handleSubmit: (state: unknown) => Promise<void> | void;
  readonly handleClose: () => void;
  readonly isSimulation?: boolean;
};

/*
 * Prefill the UserSelector with previous value
 * if the property is a user model reference.
 */
const initialValue = ({ ref, oldValue }: OverwriteCreationRequestDetails) =>
  ref === PropertyRef.USER ? (oldValue ?? '') : '';

export type OverwriteModalInitialValues = {
  percentage: number;
  newValue: boolean | number | string | null;
  isApplyToOverall: boolean;
};

export const OverwriteModalContainer = memo(function OverwriteModalContainer({
  overwriteContext,
  isOpen,
  handleClose,
  currentObjectDetails,
  handleSubmit,
  isSimulation = false,
}: OverwriteModalContainerProps) {
  const { formatMessage } = useIntl();

  const initialValues: OverwriteModalInitialValues = useMemo(
    () => ({
      percentage: 0,
      newValue: initialValue(currentObjectDetails),
      isApplyToOverall: false,
    }),
    [currentObjectDetails],
  );

  const formValidationSchema = useMemo(() => {
    let formikNewValueShape:
      | Yup.BooleanSchema<boolean | undefined>
      | Yup.NumberSchema<number | undefined>
      | Yup.StringSchema<string | undefined>;

    switch (currentObjectDetails.format) {
      case FormatsEnum.currency:
      case FormatsEnum.number:
      case FormatsEnum.percent:
        formikNewValueShape = Yup.number().required();
        break;
      case FormatsEnum.boolean:
        formikNewValueShape = Yup.boolean().required();
        break;
      default:
        formikNewValueShape = Yup.string().min(1);
    }

    return Yup.object().shape({
      isApplyToOverall: Yup.boolean(),
      newValue: formikNewValueShape,
      percentage: Yup.number(),
    });
  }, [currentObjectDetails]);

  const onSubmit = useCallback(
    async (values: OverwriteModalInitialValues) => {
      const editedValues = { ...values };
      if ([FormatsEnum.currency, FormatsEnum.number, FormatsEnum.percent].includes(currentObjectDetails.format)) {
        assert(typeof values.newValue === 'string', 'newValue should be a string');
        editedValues.newValue = parseFloat(values.newValue);
        if (currentObjectDetails.format === FormatsEnum.percent) {
          editedValues.newValue /= 100;
        }
      }
      const changed = createRowChange(currentObjectDetails, editedValues, overwriteContext);
      await handleSubmit({ changed });
      handleClose();
    },
    [currentObjectDetails, handleClose, handleSubmit, overwriteContext],
  );

  return (
    <Modal
      isOpen={isOpen}
      size={ModalSize.MEDIUM}
      onClose={handleClose}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={formValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, setFieldValue, values, isSubmitting }) => (
          <Form
            css={css`
              display: contents;
            `}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  {isSimulation ? (
                    <FormattedMessage defaultMessage="Make a simulation" />
                  ) : (
                    <FormattedMessage defaultMessage="Make an overwrite" />
                  )}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {/* Information recapitulation */}
                <OverwriteRecapInfos
                  currentObjectDetails={currentObjectDetails}
                  overwriteContext={overwriteContext}
                />

                {/* Formulaire  */}

                <OverwriteForm currentObjectDetails={currentObjectDetails} />

                {!isSimulation && overwriteContext !== OVERWRITE_CONTEXT.DATA && !currentObjectDetails.isProperty && (
                  <Stack
                    align="center"
                    gap={24}
                    css={css`
                      margin-top: 24px;
                    `}
                  >
                    <Divider.Horizontal />

                    {/* Options (Apply to whom) */}
                    <OverwriteOptions
                      isApplyToOverall={values.isApplyToOverall}
                      setIsApplyToOverall={setFieldValue}
                    />
                  </Stack>
                )}
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />
              <Modal.MainAction
                icon={isSubmitting ? <IconLoading /> : undefined}
                type="submit"
                aria-label={formatMessage(
                  { defaultMessage: 'Apply overwrite on {fieldName}' },
                  { fieldName: currentObjectDetails.field.trim() },
                )}
                disabled={
                  !isValid ||
                  !dirty ||
                  isSubmitting ||
                  toString(currentObjectDetails.oldValue) === toString(values.newValue) ||
                  values.percentage === 100
                }
              >
                <FormattedMessage defaultMessage="Apply" />
              </Modal.MainAction>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
});
