import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { useCreateConnector, useUpdateConnectorAuth } from '@amalia/data-capture/connectors/state';
import {
  type ConnectorResponse,
  type DataConnector,
  DataConnectorCategories,
  AuthType,
  type OAuthDataConnectorAuth,
  DataConnectorTypes,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';

import { extractPowerBiToken } from './extract-powerbi-token';

type PowerBiRetardedConnectorAuthFormValues = {
  authRaw: string;
};

type PowerBiRetardedConnectorAuthFormikProps = FormikFormProps<
  PowerBiRetardedConnectorAuthFormValues,
  ConnectorResponse
>;

export type PowerBiRetardedConnectorAuthFormProps = Omit<
  PowerBiRetardedConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
};

export const PowerBiRetardedConnectorAuthForm = memo(function PowerBiRetardedConnectorAuthForm({
  dataConnector,
  children,
  ...props
}: PowerBiRetardedConnectorAuthFormProps) {
  const dataConnectorAuth = dataConnector?.auth as OAuthDataConnectorAuth | null | undefined;
  const { formatMessage } = useIntl();

  const { mutateAsync: createConnector } = useCreateConnector();
  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth();

  const initialValues = useMemo(
    (): PowerBiRetardedConnectorAuthFormValues => ({ authRaw: dataConnectorAuth?.accessToken || '' }),
    [dataConnectorAuth],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        authRaw: Yup.string()
          .test(
            'is-valid',
            formatMessage({ defaultMessage: 'Token is invalid' }),
            (v) => !!extractPowerBiToken(v || ''),
          )
          .required(),
      }),
    [formatMessage],
  );

  const handleSubmit: PowerBiRetardedConnectorAuthFormikProps['onSubmit'] = useCallback(
    async (values) => {
      const authRequest = {
        accessToken: extractPowerBiToken(values.authRaw)!, // Token is validated by formik so it must exist here.
      };

      return !dataConnector
        ? createConnector({
            type: DataConnectorTypes.POWERBI,
            category: DataConnectorCategories.DATA,
            authType: AuthType.OAuth2_authorizationCode,
            auth: authRequest,
            isDirtyWorkaround: true,
          })
        : updateConnectorAuth({
            id: dataConnector.id,
            type: DataConnectorTypes.POWERBI,
            auth: authRequest,
            isDirtyWorkaround: true,
          });
    },
    [createConnector, dataConnector, updateConnectorAuth],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
