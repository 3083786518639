import { type JSXElementConstructor, memo, type ReactElement, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { type MergeAll } from '@amalia/ext/typescript';

import { Pagination, type PaginationProps } from '../../controls/pagination/Pagination';
import { AlertBanner } from '../../feedback/alert-banner/AlertBanner';
import { Table, type TableProps } from '../table/Table';
import { type RowData, type RowKey } from '../table/Table.types';

import { Action } from './action/Action';
import { ColumnOrder, type ColumnOrderProps } from './column-order/ColumnOrder';
import { ColumnOrderAndVisibility } from './column-order-and-visibility/ColumnOrderAndVisibility';
import { ColumnPinning, type ColumnPinningProps } from './column-pinning/ColumnPinning';
import { ColumnSorting, type ColumnSortingProps } from './column-sorting/ColumnSorting';
import { ColumnVisibility, type ColumnVisibilityProps } from './column-visibility/ColumnVisibility';
import { DataGridContext, type DataGridContextValue } from './DataGrid.context';
import * as styles from './DataGrid.styles';
import { useDisplayedColumns } from './hooks/useDisplayedColumns';
import { PageSize, type PageSizeProps } from './page-size/PageSize';
import { Search, type SearchProps } from './search/Search';
import { sortDates, sortNumbers, sortStrings } from './sorting-fns';
import { TotalItems } from './total-items/TotalItems';

export type DataGridProps<TData extends RowData = RowData, TKey extends RowKey = RowKey> = MergeAll<
  [
    Omit<TableProps<TData, TKey>, 'pinFirstColumn'>,
    {
      /** Pass a <DataGrid.Search /> element. Search must be controlled. */
      search?: ReactElement<SearchProps, JSXElementConstructor<SearchProps>>;
      /** Pass a <DataGrid.ColumnVisibility /> element. */
      columnVisibility?: ReactElement<ColumnVisibilityProps, JSXElementConstructor<ColumnVisibilityProps>>;
      /** Pass a <DataGrid.ColumnOrder /> element. */
      columnOrder?: ReactElement<ColumnOrderProps, JSXElementConstructor<ColumnOrderProps>>;
      /** Pass a <DataGrid.Sort /> element. */
      columnSorting?: ReactElement<ColumnSortingProps, JSXElementConstructor<ColumnSortingProps>>;
      /** Pass a <DataGrid.ColumnPinning /> element. It must be controlled. */
      columnPinning?: ReactElement<ColumnPinningProps, JSXElementConstructor<ColumnPinningProps>>;
      /** List of <DataGrid.Action />, can be inside a Fragment. */
      actions?: ReactNode;
      /** Pass a `<DataGrid.PageSize /> element. */
      pageSize?: ReactElement<PageSizeProps, JSXElementConstructor<PageSizeProps>>;
      /** Pagination. Pass a `<Pagination />` element. */
      pagination?: ReactElement<PaginationProps, JSXElementConstructor<PaginationProps>>;
      /** Show total number of items. */
      totalItems?: number;
      /** Error message to show as AlertBanner in the Table. */
      errorMessage?: ReactNode;
      /** Override default "No data available" message. */
      noDataMessage?: ReactNode;
      /** If specificied, overrides views/viewModes widgets (when implemented). */
      leftHeaderSlot?: ReactNode;
    },
  ]
>;

const DataGridBase = function DataGrid<TData extends RowData = RowData, TKey extends RowKey = RowKey>({
  pagination,
  pageSize,
  actions,
  search,
  columnVisibility,
  columnOrder,
  columnSorting,
  columnPinning,
  totalItems,
  columns,
  data,
  id,
  alert,
  isLoading,
  loadingRowsCount,
  errorMessage,
  noDataMessage,
  leftHeaderSlot,
  ...props
}: DataGridProps<TData, TKey>) {
  const contextValue = useShallowObjectMemo<DataGridContextValue<TData, TKey>>({
    columns,
  });

  const displayedColumns = useDisplayedColumns(columns, {
    columnOrder: columnOrder?.props.columnOrder,
    columnVisibility: columnVisibility?.props.columnVisibility,
  });

  return (
    // Need to cast here because we can't use generics in createContext.
    <DataGridContext.Provider value={contextValue as DataGridContextValue<RowData, RowKey>}>
      <div data-testid={id ? `${id}-datagrid-container` : undefined}>
        {!!(
          actions ||
          search ||
          columnPinning ||
          columnVisibility ||
          columnOrder ||
          columnSorting ||
          leftHeaderSlot
        ) && (
          <div css={styles.header}>
            <div>
              {/* Will be view modes and views. */}
              {leftHeaderSlot}
            </div>

            <div css={styles.headerRight}>
              {search}

              {!!(columnPinning || columnVisibility || columnOrder || columnSorting) && (
                <div css={styles.smallActions}>
                  {columnSorting}
                  {columnPinning}
                  {!!(columnVisibility || columnOrder) && (
                    <ColumnOrderAndVisibility
                      columnOrder={columnOrder?.props.columnOrder}
                      columnVisibility={columnVisibility?.props.columnVisibility}
                      onChangeColumnOrder={columnOrder?.props.onChangeColumnOrder}
                      onChangeColumnVisibility={columnVisibility?.props.onChangeColumnVisibility}
                    />
                  )}
                </div>
              )}

              {!!actions && <div css={styles.extraActions}>{actions}</div>}
            </div>
          </div>
        )}

        <Table<TData, TKey>
          {...props}
          columns={displayedColumns}
          data={data}
          id={id}
          isLoading={isLoading}
          loadingRowsCount={loadingRowsCount ?? pageSize?.props.value}
          pinFirstColumn={columnPinning?.props.isActive}
          alert={
            // Props alert can override default behavior.
            alert ||
            (errorMessage ? (
              <AlertBanner variant={AlertBanner.Variant.ERROR}>{errorMessage}</AlertBanner>
            ) : undefined) ||
            // If no data, and not loading, show an empty data banner.
            (!data?.length && !isLoading && !props.placeHolder ? (
              <AlertBanner>{noDataMessage ?? <FormattedMessage defaultMessage="No data available." />}</AlertBanner>
            ) : undefined)
          }
        />

        {!!(pageSize || pagination) && (
          <div
            css={styles.footer}
            data-testid={id ? `${id}-datagrid-footer` : undefined}
          >
            <div css={styles.pageSizeAndTotal}>
              {pageSize}

              <TotalItems
                page={pagination?.props.page}
                pageSize={pageSize?.props.value}
                totalItems={totalItems}
              />
            </div>

            {pagination}
          </div>
        )}
      </div>
    </DataGridContext.Provider>
  );
};

export const DataGrid = Object.assign(memo(DataGridBase) as typeof DataGridBase, {
  Action,
  ColumnPinning,
  ColumnSorting,
  ColumnVisibility,
  ColumnOrder,
  Pagination,
  PageSize,
  Search,
  sortingFn: {
    dates: sortDates,
    strings: sortStrings,
    numbers: sortNumbers,
  },
});
