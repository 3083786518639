import { type Variable, VariableType } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { type Filter } from '@amalia/payout-definition/plans/types';

export const rulesMockObject = {
  id: 'objectA',
  machineName: 'deal',
  name: 'Deal',
} as CustomObjectDefinition;

export const rulesMockVariables = [
  {
    machineName: 'v1',
    id: 'v1',
    name: 'Object currency var 1',
    format: FormatsEnum.currency,
    type: VariableType.object,
    object: rulesMockObject,
  },
  {
    machineName: 'v2',
    id: 'v2',
    name: 'Object currency var 2',
    format: FormatsEnum.currency,
    type: VariableType.object,
    object: rulesMockObject,
  },
  {
    machineName: 'v3',
    id: 'v3',
    name: 'Object number var 1',
    format: FormatsEnum.number,
    type: VariableType.object,
    object: rulesMockObject,
  },
  {
    machineName: 'v4',
    id: 'v4',
    name: 'Object number var 2',
    format: FormatsEnum.number,
    type: VariableType.object,
    object: rulesMockObject,
  },
  {
    machineName: 'v5',
    id: 'v5',
    name: 'Object date var 1',
    format: FormatsEnum.date,
    type: VariableType.object,
    object: rulesMockObject,
  },
  {
    id: 's1',
    machineName: 's1',
    name: 'Statement var 1',
    format: FormatsEnum.percent,
    type: VariableType.statement,
  },
  {
    id: 's2',
    machineName: 's2',
    name: 'Statement var 2',
    format: FormatsEnum.percent,
    type: VariableType.statement,
  },
  {
    id: 's3',
    machineName: 's3',
    name: 'Statement var 3',
    format: FormatsEnum.percent,
    type: VariableType.statement,
  },
  {
    id: 's4',
    machineName: 's4',
    name: 'Statement var 4',
    format: FormatsEnum.percent,
    type: VariableType.statement,
  },
] as Variable[];

export const rulesMockFilters = [
  {
    object: rulesMockObject,
    machineName: 'closedInPeriod',
    name: 'Closed In Period',
    id: 'f1',
  },
] as Filter[];
