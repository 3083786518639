import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect } from 'react';

import { useSnackbars } from '@amalia/design-system/components';
import { type Period, type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { PeriodsApiClient } from '@amalia/payout-definition/state';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { PERIOD_QUERY_KEYS } from './query-keys';

export type UseCurrentPeriodProps = {
  frequency?: PeriodFrequencyEnum;
  periodId?: Period['id'];
  enabled?: boolean;
};

export const useCurrentPeriod = ({ frequency, periodId, enabled }: UseCurrentPeriodProps = {}) => {
  const { data: company } = useCurrentCompany();
  const { snackError } = useSnackbars();
  const currentFrequency = frequency || company.statementFrequency;
  const {
    data: periods,
    isError,
    error,
    ...rest
  } = useQuery({
    queryKey: [PERIOD_QUERY_KEYS.PERIODS, periodId, currentFrequency],

    queryFn: () =>
      periodId
        ? PeriodsApiClient.getAllPeriods()
        : PeriodsApiClient.getPeriodByDate(moment.utc().format('YYYY-MM-DD'), currentFrequency).then((period) => [
            period,
          ]),

    enabled: enabled !== false,
  });

  useEffect(() => {
    if (isError) {
      snackError(error.message);
    }
  }, [isError, error, snackError]);

  const currentPeriod = periodId ? periods?.find(({ id }) => id === periodId) : periods?.[0];

  return {
    currentPeriod,
    ...rest,
  };
};
