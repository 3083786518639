import { css } from '@emotion/react';
import { lazy, memo, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { Skeleton, Stack, Typography } from '@amalia/design-system/components';

const OAuthLoadingIndicatorSvg = lazy(async () => ({
  default: (await import('./OAuthLoadingIndicator.svg')).ReactComponent,
}));

export const OAuthLoadingIndicator = memo(function OAuthLoadingIndicator() {
  return (
    <Stack
      align="center"
      gap={48}
      css={css`
        padding: 32px;
      `}
    >
      <Suspense
        fallback={
          <Skeleton
            css={css`
              height: 189px;
              width: 340px;
            `}
          />
        }
      >
        <OAuthLoadingIndicatorSvg />
      </Suspense>

      <Stack
        css={css`
          text-align: center;
        `}
      >
        <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage defaultMessage="Please complete the authentication in the new window." />
        </Typography>

        <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
          <FormattedMessage defaultMessage="A success message will appear here once you're connected." />
        </Typography>
      </Stack>
    </Stack>
  );
});
