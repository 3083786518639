import {
  FormulaBuilderDateOperatorNoArgs,
  FormulaBuilderDateOperatorOneArg,
  FormulaBuilderDateOperatorTwoArgs,
  FormulaBuilderNumberOperatorNoArgs,
  FormulaBuilderNumberOperatorOneArg,
  FormulaBuilderStringOperatorOneArg,
} from '@amalia/amalia-lang/formula/types';
import { isEnum } from '@amalia/ext/typescript';

import {
  type FormulaBuilderFunctionBlockNumberNoArgsForm,
  type FormulaBuilderFunctionBlockDateForm,
  type FormulaBuilderFunctionBlockDateNoArgsForm,
  type FormulaBuilderFunctionBlockDateOneArgForm,
  type FormulaBuilderFunctionBlockDateTwoArgsForm,
  type FormulaBuilderFunctionBlockStringForm,
  type FormulaBuilderFunctionBlockStringOneArgForm,
  type FormulaBuilderFunctionBlockNumberForm,
  type FormulaBuilderFunctionBlockNumberOneArgForm,
  type FormulaBuilderFunctionBlockPercentForm,
  type FormulaBuilderFunctionBlockPercentNoArgsForm,
  type FormulaBuilderFunctionBlockPercentOneArgForm,
  type FormulaBuilderFunctionBlockCurrencyForm,
  type FormulaBuilderFunctionBlockCurrencyNoArgsForm,
  type FormulaBuilderFunctionBlockCurrencyOneArgForm,
} from './types';

export const isFormulaBuilderFunctionBlockStringOneArgForm = (
  block: FormulaBuilderFunctionBlockStringForm,
): block is FormulaBuilderFunctionBlockStringOneArgForm => isEnum(block.operator, FormulaBuilderStringOperatorOneArg);

export const isFormulaBuilderFunctionBlockDateNoArgsForm = (
  block: FormulaBuilderFunctionBlockDateForm,
): block is FormulaBuilderFunctionBlockDateNoArgsForm => isEnum(block.operator, FormulaBuilderDateOperatorNoArgs);

export const isFormulaBuilderFunctionBlockDateOneArgForm = (
  block: FormulaBuilderFunctionBlockDateForm,
): block is FormulaBuilderFunctionBlockDateOneArgForm => isEnum(block.operator, FormulaBuilderDateOperatorOneArg);

export const isFormulaBuilderFunctionBlockDateTwoArgsForm = (
  block: FormulaBuilderFunctionBlockDateForm,
): block is FormulaBuilderFunctionBlockDateTwoArgsForm => isEnum(block.operator, FormulaBuilderDateOperatorTwoArgs);

export const isFormulaBuilderFunctionBlockNumberNoArgsForm = (
  block: FormulaBuilderFunctionBlockNumberForm,
): block is FormulaBuilderFunctionBlockNumberNoArgsForm => isEnum(block.operator, FormulaBuilderNumberOperatorNoArgs);

export const isFormulaBuilderFunctionBlockNumberOneArgForm = (
  block: FormulaBuilderFunctionBlockNumberForm,
): block is FormulaBuilderFunctionBlockNumberOneArgForm => isEnum(block.operator, FormulaBuilderNumberOperatorOneArg);

export const isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyNoArgsForm = (
  block:
    | FormulaBuilderFunctionBlockCurrencyForm
    | FormulaBuilderFunctionBlockNumberForm
    | FormulaBuilderFunctionBlockPercentForm,
): block is
  | FormulaBuilderFunctionBlockCurrencyNoArgsForm
  | FormulaBuilderFunctionBlockNumberNoArgsForm
  | FormulaBuilderFunctionBlockPercentNoArgsForm => isEnum(block.operator, FormulaBuilderNumberOperatorNoArgs);

export const isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyOneArgForm = (
  block:
    | FormulaBuilderFunctionBlockCurrencyForm
    | FormulaBuilderFunctionBlockNumberForm
    | FormulaBuilderFunctionBlockPercentForm,
): block is
  | FormulaBuilderFunctionBlockCurrencyOneArgForm
  | FormulaBuilderFunctionBlockNumberOneArgForm
  | FormulaBuilderFunctionBlockPercentOneArgForm => isEnum(block.operator, FormulaBuilderNumberOperatorOneArg);
