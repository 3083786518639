import { memo, useCallback } from 'react';

import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';
import { FORMATS_SYMBOLS_AND_LABELS, FormatsEnum } from '@amalia/data-capture/fields/types';
import { Table } from '@amalia/design-system/components';

import { type rowKeySymbol, type TableBuilderDataGridRowData, type TableBuilderParsedRow } from '../TableBuilder.types';

const ROW = {};

export type TableBuilderRowValueCellProps = {
  readonly rowKey: TableBuilderDataGridRowData[typeof rowKeySymbol];
  readonly value: TableBuilderParsedRow[number];
  readonly column: VariableFormatOptionsColumn;
  readonly isReadonly: boolean;
  readonly onChange: (
    key: TableBuilderDataGridRowData[typeof rowKeySymbol],
    column: VariableFormatOptionsColumn['machineName'],
    value: TableBuilderParsedRow[number],
  ) => void;
};

export const TableBuilderRowValueCell = memo(function TableBuilderRowValueCell({
  rowKey,
  value,
  column,
  isReadonly,
  onChange,
}: TableBuilderRowValueCellProps) {
  const handleChange = useCallback(
    (newValue: string) => onChange(rowKey, column.machineName, newValue),
    [onChange, rowKey, column],
  );

  return (
    <Table.Cell.TextField
      disabled={isReadonly}
      row={ROW}
      value={value}
      suffix={
        column.format === FormatsEnum.percent ? FORMATS_SYMBOLS_AND_LABELS[FormatsEnum.percent].symbol : undefined
      }
      onChange={handleChange}
    />
  );
});
