import { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { CONNECTORS, getDataConnectorName } from '@amalia/core/types';
import {
  type DataConnector,
  type ConnectorResponse,
  type DataConnectorTypes,
} from '@amalia/data-capture/connectors/types';
import { IconLoading, Modal, Stack } from '@amalia/design-system/components';
import { FormikForm, isFormikStatusSubmissionError, isFormikStatusSubmissionSuccess } from '@amalia/ext/formik';

import { OAuthLoadingIndicator } from '../../oauth/loading-indicator/OAuthLoadingIndicator';
import { ConnectorAuthFormSuccess } from '../success/ConnectorAuthFormSuccess';

import { SubmissionErrorBanner } from './submission-error-banner/SubmissionErrorBanner';

export type ConnectorAuthFormModalContentProps = {
  /** Original data connector (if updating). */
  readonly dataConnector?: Pick<DataConnector, 'alias' | 'type'>;
  /** Connector type of the form. */
  readonly connectorType: DataConnectorTypes;
  /** Formik isValid prop. */
  readonly isValid: boolean;
  /** Formik isSubmitting prop. */
  readonly isSubmitting: boolean;
  /** Formik status prop. */
  readonly status: unknown;
  /** Is OAuth flow being running. */
  readonly isRunningOAuthFlow?: boolean;
  /** Form fields. */
  readonly children: ReactNode;
};

export const ConnectorAuthFormModalContent = memo(function ConnectorAuthFormModalContent({
  dataConnector,
  connectorType,
  isValid,
  isSubmitting,
  status,
  isRunningOAuthFlow,
  children,
}: ConnectorAuthFormModalContentProps) {
  return (
    <FormikForm.FormDisplayContents>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            {dataConnector ? (
              <FormattedMessage
                defaultMessage="Update authentication to {connectorType}"
                values={{ connectorType: getDataConnectorName(dataConnector) }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Connect to {connectorType}"
                values={{ connectorType: CONNECTORS[connectorType].name }}
              />
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!dataConnector && isFormikStatusSubmissionSuccess<ConnectorResponse>(status) ? (
            <ConnectorAuthFormSuccess dataConnector={status.response} />
          ) : isRunningOAuthFlow ? (
            <OAuthLoadingIndicator />
          ) : (
            <Stack gap={32}>
              {isFormikStatusSubmissionError(status) && (
                <SubmissionErrorBanner>{status.error.errorMessage}</SubmissionErrorBanner>
              )}

              {!!children && <div>{children}</div>}
            </Stack>
          )}
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        {!isFormikStatusSubmissionSuccess<ConnectorResponse>(status) && (
          <Modal.MainAction
            disabled={!isValid}
            icon={isSubmitting ? <IconLoading /> : undefined}
            isLoading={isSubmitting}
            type="submit"
          >
            {dataConnector ? (
              isSubmitting ? (
                <FormattedMessage defaultMessage="Updating…" />
              ) : (
                <FormattedMessage defaultMessage="Update" />
              )
            ) : isSubmitting ? (
              <FormattedMessage defaultMessage="Connecting…" />
            ) : (
              <FormattedMessage defaultMessage="Connect" />
            )}
          </Modal.MainAction>
        )}
      </Modal.Actions>
    </FormikForm.FormDisplayContents>
  );
});
