import { css, type Theme } from '@emotion/react';

import { designerObjectsDetails } from '../../../utils/designer';

export const editorContentCss = (theme: Theme) => css`
  width: 100%;
  word-break: break-word;
  display: inline-flex;
  flex-wrap: nowrap;

  p,
  span {
    display: inline-block;
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
  }

  .tiptap.ProseMirror {
    border: 1px solid ${theme.ds.colors.gray['200']};
    border-radius: 5px;
    padding: 12px 16px;
    height: 100%;
    min-height: 75px;
    width: 100%;
    span.amalia-function {
      color: ${designerObjectsDetails.FUNCTION.textColor};
    }
  }

  .tiptap.ProseMirror.ProseMirror-focused {
    outline: 1px solid ${theme.ds.colors.primary[200]};
  }
`;
