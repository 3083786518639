import { get, omit, pickBy, set } from 'lodash';
import { type MathJsInstance, type MathNode, type Parser } from 'mathjs';
import moment from 'moment';
import { sha1 } from 'object-hash';

import { getMathJS, lockMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { type AmaliaFormula, type AmaliaFunctionKeys } from '@amalia/amalia-lang/formula/types';
import { AmaliaAccessorKeywords, type VariableType } from '@amalia/amalia-lang/tokens/types';
import { type DatasetRow } from '@amalia/core/types';
import { type BaseCustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { assert, toError } from '@amalia/ext/typescript';
import {
  type ComputedFunctionResult,
  type ComputedVariable,
  type ComputeEnginePrimitiveTypes,
  type ComputeEngineResult,
  type DatasetRowContent,
  type FilterDataset,
  type MetricsDataset,
} from '@amalia/payout-calculation/types';
import { type Period } from '@amalia/payout-definition/periods/types';
import { type Plan, type PlanAssignment, type Relationship } from '@amalia/payout-definition/plans/types';
import { type TeamAssignment } from '@amalia/tenants/assignments/teams/types';
import { type UserContract } from '@amalia/tenants/users/types';

import { CalculationError, type MathJsError } from './calculationError';
import { semesterRange } from './dates';
import { AmaliaFunction } from './functions';
import { RowConverterUtils } from './rowConverter.utils';
import { SanitizeFormula } from './sanitizeFormula';

export type CalculationScope = Record<string, ComputeEngineResult>;
export type ParserScope = Map<string, ComputeEngineResult>;

export type PeriodExtended = Period & {
  startOfMonth: number;
  startOfQuarter: number;
  startOfSemester: number;
  startOfYear: number;
  endOfMonth: number;
  endOfQuarter: number;
  endOfSemester: number;
  endOfYear: number;
};

type UserWithTeamMembers = UserContract & { teamMembers: UserContract[] };

export type TeamInParserScope = {
  id?: string;
  name?: string;
  archived?: boolean;
  members?: UserContract[];
  managers?: UserContract[];
  employees?: UserContract[];
  childrenTeams?: TeamInParserScope[];
};

interface ConstructorOptions {
  // Replace advanced functions (IF, convertCurrency...) with their stubbed equivalent.
  // Useful for scope evaluation and formula validation.
  stubAdvancedFunctions?: boolean;

  // Set the user.
  user?: UserContract;

  // Set his team members.
  userTeamMembers?: UserContract[];

  // Set the plan
  plan?: Plan;

  // Set the team
  teams?: TeamInParserScope[];

  // Set the period.
  period?: Period;
}

export class CalculationParser {
  private readonly parser: Parser;

  private period?: PeriodExtended;

  private user?: UserContract;

  private team?: TeamInParserScope;

  private teams?: TeamInParserScope[] = [];

  private plan?: Plan;

  public constructor({ stubAdvancedFunctions, user, plan, teams, period, userTeamMembers }: ConstructorOptions = {}) {
    // Create a new instance of mathJs on each CalculationParser.
    // This avoids data leaking when calculating in parallel.
    const mathJs = getMathJS();

    // Set functions
    this.setParserFunctions(mathJs, stubAdvancedFunctions || false);

    this.parser = mathJs.parser();

    lockMathJs(mathJs);

    if (user) {
      this.setUser(user, userTeamMembers);
    }
    if (plan) {
      this.setPlan(plan);
    }
    if (teams) {
      this.setTeams(teams);
    }
    if (period) {
      this.setPeriod(period);
    }
  }

  // ====================== GETTERS / SETTERS ======================

  public setPeriod(period: Period) {
    // Since our current period system is broken, we can't trust neither
    // the startDate nor the endDate to be in the current period because
    // of the timezone issue. So we're gonna calculate the middle of them,
    // which should give us more or less the middle of the period.
    const periodMiddle = (period.startDate + period.endDate) / 2;
    const momentObjectReference = moment.utc(periodMiddle, 'X');

    const semester = semesterRange(momentObjectReference);

    const extendedPeriod = {
      ...period,
      currentDate: +moment.utc().format('X'),
      startOfMonth: +momentObjectReference.clone().startOf('month').format('X'),
      startOfQuarter: +momentObjectReference.clone().startOf('quarter').format('X'),
      startOfSemester: +semester.startDate.format('X'),
      startOfYear: +momentObjectReference.clone().startOf('year').format('X'),
      endOfMonth: +momentObjectReference.clone().endOf('month').format('X'),
      endOfQuarter: +momentObjectReference.clone().endOf('quarter').format('X'),
      endOfSemester: +semester.endDate.format('X'),
      endOfYear: +momentObjectReference.clone().endOf('year').format('X'),
    };

    this.period = extendedPeriod;

    this.parser.set(AmaliaAccessorKeywords.statementPeriod, extendedPeriod);
  }

  public getPeriod(): PeriodExtended | undefined {
    return this.period;
  }

  public setUser(user: UserContract, userTeamMembers?: UserContract[]) {
    this.user = user;
    this.parser.set(AmaliaAccessorKeywords.user, { ...user, teamMembers: userTeamMembers } as UserWithTeamMembers);
  }

  public getUser(): UserContract | null {
    return this.user ?? null;
  }

  public getUserTeamMembers(): UserContract[] {
    return (this.parser.get(AmaliaAccessorKeywords.user) as UserWithTeamMembers).teamMembers;
  }

  public setTeams(teams: TeamInParserScope[]) {
    this.teams = teams;

    // Only setting the first team in the parser
    // If not team found, insert an empty one
    const team = teams[0] || { members: [], managers: [], employees: [] };
    this.team = team;
    this.parser.set(AmaliaAccessorKeywords.team, team);
  }

  public getTeam() {
    return this.team;
  }

  public getTeams() {
    return this.teams;
  }

  public setPlan(plan: Plan) {
    this.plan = plan;
    this.parser.set(AmaliaAccessorKeywords.plan, plan);
  }

  public getPlan() {
    return this.plan;
  }

  public setPlanAssignment(planAssignment: PlanAssignment) {
    // Change null dates to +-Infinity, so we can compare in MathJS.
    this.parser.set(AmaliaAccessorKeywords.planAssignement, {
      ...planAssignment,
      effectiveAsOf: planAssignment.effectiveAsOf || -Infinity,
      effectiveUntil: planAssignment.effectiveUntil || Infinity,
    });
  }

  public setTeamAssignment(teamAssignment: TeamAssignment | null) {
    if (teamAssignment) {
      // Change null dates to +-Infinity, so we can compare in MathJS.
      this.parser.set(AmaliaAccessorKeywords.teamAssignment, {
        ...teamAssignment,
        effectiveAsOf: teamAssignment.effectiveAsOf || -Infinity,
        effectiveUntil: teamAssignment.effectiveUntil || Infinity,
      });
    } else {
      this.parser.set(AmaliaAccessorKeywords.teamAssignment, null);
    }
  }

  public addFilter(
    dataset: FilterDataset | MetricsDataset,
    rows: DatasetRow[],
    relationshipsMap: Record<string, Relationship>,
    customObjectDefinitionMap: Record<string, BaseCustomObjectDefinition>,
  ) {
    const rowContents = rows.map((row) =>
      RowConverterUtils.convertRowDatesAndCurrencies(
        dataset.customObjectDefinition.machineName,
        row.content,
        relationshipsMap,
        customObjectDefinitionMap,
      ),
    );

    this.parser.set(AmaliaAccessorKeywords.filter, {
      ...this.parser.get(AmaliaAccessorKeywords.filter),
      [dataset.filterMachineName]: rowContents,
    });
  }

  public getAllScope(): Record<string, unknown> {
    return this.parser.getAll();
  }

  public getPropertyFromScope(propertyDeep: string): unknown {
    const [scopeKey, ...rest] = propertyDeep.split('.');
    const value = this.parser.get(scopeKey) as unknown;
    // If we had only one level, return it, also do a lodash get inside.
    return !rest.length ? value : get(value, rest);
  }

  public saveComputedVariableToScope(variableType: VariableType, variableMachineName: string, value: unknown) {
    const currentValue = this.parser.get(variableType) as Record<string, ComputedVariable>;
    this.parser.set(variableType, {
      ...currentValue,
      [variableMachineName]: value,
    });
  }

  public saveComputedObjectVariableToScope(
    datasetName: string,
    path: string,
    variableMachineName: string,
    value: unknown,
  ) {
    const currentValue = this.parser.get('filter') as Record<string, DatasetRow[]>;
    const newValue = set(currentValue, `${datasetName}.${path}.${variableMachineName}`, value);
    this.parser.set('filter', newValue);
  }

  // ====================== PARSER AND EVALUATIONS ======================

  public setParserFunctions(mathJs: MathJsInstance, stubAdvancedFunction: boolean) {
    const functionsEnum = AmaliaFunction.getAllFunctions();

    (Object.keys(functionsEnum) as AmaliaFunctionKeys[]).forEach((functionKey) => {
      const functionDefinition = functionsEnum[functionKey];

      // Load the mock implementation if necessary (aka in the frontend for validation) and available, otherwise load the real implementation.
      const functionToImport =
        stubAdvancedFunction && functionDefinition.execMock ? functionDefinition.execMock : functionDefinition.exec;

      mathJs.import({ [functionKey]: functionToImport }, { override: true });
    });
  }

  public computeFormula(formula: AmaliaFormula, scope?: CalculationScope): ComputeEngineResult {
    let savedScope: CalculationScope | undefined;

    // Before evaluating, create a backup of the parser scope keys
    // that are going to be overwritten.
    if (scope) {
      savedScope = this.saveParserScope(scope);
    }

    try {
      const sanitizedFormula = typeof formula === 'string' ? SanitizeFormula.amaliaFormulaToMathJs(formula) : formula;

      assert(sanitizedFormula !== null, 'Formula is null');

      return this.parser.evaluate(sanitizedFormula) as ComputeEngineResult;
    } catch (e) {
      throw new CalculationError(e as MathJsError);
    } finally {
      if (scope) {
        this.restoreParserScope(scope, savedScope);
      }
    }
  }

  /**
   * Return '' if formula is valid, or the error if it's not.
   */
  public formulaHasError(formula: AmaliaFormula, scope?: CalculationScope): string {
    try {
      const result = this.computeFormula(formula, scope);

      if (result === null || result === undefined) {
        return 'Calculation returns undefined';
      }

      return '';
    } catch (e) {
      return toError(e).message;
    }
  }

  private saveParserScope(scope: CalculationScope): CalculationScope {
    const savedScope: CalculationScope = {};

    Object.keys(scope).forEach((key) => {
      set(savedScope, key, this.parser.get(key) || {});
      this.parser.set(key, get(scope, key));
    });

    return savedScope;
  }

  /**
   * Restore parser scope.
   * @param scope
   * @param savedScope
   * @private
   */
  private restoreParserScope(scope: CalculationScope, savedScope?: CalculationScope) {
    Object.keys(scope).forEach((key) => {
      this.parser.set(key, undefined);

      if (savedScope && Object.keys(get(savedScope, key) as object).length > 0) {
        this.parser.set(key, get(savedScope, key));
      }
    });
  }

  // ====================== ADVANCED FUNCTIONS OVERRIDE ======================

  /**
   * Generate a computedFunctionResult that we will be able to reuse later.
   *
   * @param computedFunctionResult
   * @param value
   * @param ignoreKeys
   */
  public generateComputedFunctionResult(
    computedFunctionResult: ComputedFunctionResult,
    value: ComputeEngineResult,
    ignoreKeys?: string[],
  ) {
    const functionKey = `${computedFunctionResult.function}Result`;
    const argsComputed = omit(computedFunctionResult.args, ignoreKeys || []);
    // Filter out undefined values (which means no argument was passed here).
    const argsKey = CalculationParser.computeArgumentsSha(argsComputed);
    return { [functionKey]: { [argsKey]: value } };
  }

  private static computeArgumentsSha(argsComputed: Record<string, ComputeEnginePrimitiveTypes>) {
    const argsFiltered = pickBy(argsComputed, (v) => v !== undefined);
    return sha1(argsFiltered);
  }

  /**
   * Fetch in the scope the previously stored computedFunctionResult.
   * @param args
   * @param scope
   * @param functionName
   */
  public static readonly getFunctionResult = <T extends ComputeEngineResult = ComputeEngineResult>(
    args: MathNode[],
    scope: ParserScope,
    functionName: AmaliaFunctionKeys,
  ): T => {
    const callback = AmaliaFunction.getAllFunctions()[functionName].generateComputedFunctionResult;

    assert(callback, 'Trying to getFunctionResult on a function that does not support it');

    const argsComputed = callback(args) as Record<string, ComputeEnginePrimitiveTypes>;

    const argsKey = CalculationParser.computeArgumentsSha(argsComputed);

    const currentFunctionValues = scope.get(`${functionName}Result`);

    assert(
      currentFunctionValues &&
        typeof currentFunctionValues === 'object' &&
        argsKey in currentFunctionValues &&
        !Array.isArray(currentFunctionValues),
      `Could not find ${functionName} result`,
    );

    const value = (currentFunctionValues as DatasetRowContent)[argsKey] as T | undefined;

    if (value === undefined) {
      throw new Error(`Could not find ${functionName} result`);
    }

    return value;
  };
}
