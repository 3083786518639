import { http } from '@amalia/core/http/client';
import {
  type ConnectorResponse,
  type DataConnectorObject,
  type DataConnectorObjectField,
  type PatchDataConnectorRequest,
  type CreateConnectorRequest,
  type DataConnector,
  type UpdateConnectorAuthRequest,
} from '@amalia/data-capture/connectors/types';

const apiEndpoint = '/connectors';

export class DataConnectorsApiClient {
  /**
   * Create data connector.
   * @param dataConnector
   */
  public static async create(dataConnector: CreateConnectorRequest): Promise<ConnectorResponse> {
    const response = await http.post<ConnectorResponse>(`${apiEndpoint}`, { connector: dataConnector });
    return response.data;
  }

  /**
   * Update data connector.
   * @param id
   * @param dataConnector
   */
  public static async patch(id: string, dataConnector: PatchDataConnectorRequest): Promise<ConnectorResponse> {
    const response = await http.patch<ConnectorResponse>(`${apiEndpoint}/${id}`, dataConnector);
    return response.data;
  }

  public static async updateConnectorAuth({
    id,
    ...updateConnectorAuthRequest
  }: UpdateConnectorAuthRequest & { id: DataConnector['id'] }) {
    return (
      await http.put<ConnectorResponse>(`${apiEndpoint}/${encodeURIComponent(id)}/auth`, {
        authRequest: updateConnectorAuthRequest,
      })
    ).data;
  }

  /**
   * Logouts a connector
   * @param id
   * @returns dataConnector
   */
  public static async logout(id: DataConnector['id']): Promise<void> {
    return http.patch(`${apiEndpoint}/${encodeURIComponent(id)}/logout`);
  }

  /**
   * List data connectors.
   */
  public static async list(): Promise<ConnectorResponse[]> {
    const response = await http.get<ConnectorResponse[]>(`${apiEndpoint}`);
    return response.data;
  }

  /**
   * List connector supported objects.
   */
  public static async listObjects(id: string): Promise<DataConnectorObject[]> {
    const response = await http.get<DataConnectorObject[]>(`${apiEndpoint}/${id}/objects`);
    return response.data;
  }

  /**
   * List object fields.
   * @param id
   * @param objectName
   */
  public static async listObjectFields(id: string, objectName: string): Promise<DataConnectorObjectField[]> {
    const response = await http.get<DataConnectorObjectField[]>(`${apiEndpoint}/${id}/objects/${objectName}/fields`);
    return response.data;
  }
}
