import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';

import { columnExists } from '../../../hooks/use-generate-column';

type EditColumnFormValues = {
  name: string;
  format: FormatsEnum;
};

type EditColumnFormikProps = FormikFormProps<
  EditColumnFormValues,
  Pick<VariableFormatOptionsColumn, 'format' | 'name'>
>;

export type EditColumnFormProps = Omit<
  EditColumnFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly column: VariableFormatOptionsColumn;
  readonly allColumns: VariableFormatOptionsColumn[];
};

export const EditColumnForm = memo(function EditColumnForm({
  column,
  allColumns,
  children,
  ...props
}: EditColumnFormProps) {
  const { formatMessage } = useIntl();

  const initialValues = useMemo(
    () => ({
      name: column.name,
      format: column.format,
    }),
    [column],
  );

  const allColumnsExceptCurrentColumn = useMemo(
    () => allColumns.filter(({ machineName }) => machineName !== column.machineName),
    [allColumns, column.machineName],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        format: Yup.string().oneOf(Object.values(FormatsEnum)).required(),
        name: Yup.string()
          .test({
            name: 'unique',
            message: formatMessage({ defaultMessage: 'Name must be unique' }),
            test: (name) => !!name && !columnExists(allColumnsExceptCurrentColumn, name),
          })
          .required(),
      }),
    [allColumnsExceptCurrentColumn, formatMessage],
  );

  // Send values to onSubmitSuccess.
  const handleSubmit: EditColumnFormikProps['onSubmit'] = (values) => values;

  return (
    <FormikForm
      {...props}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
