import { type Node as ProseMirrorNode } from '@tiptap/pm/model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { UNKNOWN_TOKEN_TYPE } from '@amalia/amalia-lang/tokens/types';

import { type FormulaEditorToken, type FormulaEditorUnknownToken } from '../../../types/formulaEditorToken';

import { useFormulaEditorContext } from './FormulaEditor.context';
import { FormulaTokenNodeView } from './FormulaTokenNodeView';

export type FormulaTokenNodeProps = {
  readonly node: ProseMirrorNode & {
    attrs: {
      formula: string;
    };
  };
  readonly getPos: () => number;
};

export const FormulaTokenNode = function FormulaTokenNode({
  node: {
    attrs: { formula },
  },
  getPos,
}: FormulaTokenNodeProps) {
  const { formatMessage } = useIntl();

  const { formulaEditorPlanTokensMap } = useFormulaEditorContext();

  // If the token is not found in the plan tokens, we will render it as an unknown token.
  // This can happen when a formula is copy pasted from another plan and thus the token is not found in the current plan.
  const token: FormulaEditorToken | FormulaEditorUnknownToken = useMemo(() => {
    const tokenInPlanTokens = formulaEditorPlanTokensMap[formula];

    if (tokenInPlanTokens) {
      return tokenInPlanTokens;
    }

    return {
      formula,
      name: formatMessage({ defaultMessage: 'Unknown token' }),
      type: UNKNOWN_TOKEN_TYPE,
      tooltipContent: {
        title: formula,
        body: formatMessage(
          {
            defaultMessage: 'The token {formula} is not recognized in the context of the current plan.',
          },
          { formula },
        ),
      },
      editorContentToApply: [{ attrs: { formula, type: 'FormulaTokenNode' } }],
    } as FormulaEditorUnknownToken;
  }, [formulaEditorPlanTokensMap, formula, formatMessage]);

  const position = useMemo(() => getPos(), [getPos]);

  return (
    <FormulaTokenNodeView
      position={position}
      token={token}
    />
  );
};
