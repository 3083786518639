import { Fragment, type MouseEvent, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { TableBuilder } from '@amalia/amalia-lang/formula/components';
import { type ArrayNode } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type VariableFormatOptionsColumn, type VariableFormatOptionsTable } from '@amalia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Button, Modal } from '@amalia/design-system/components';
import { eventStopPropagation } from '@amalia/ext/web';
import { log } from '@amalia/kernel/logger/client';

const parsedRowToFormula = (row: unknown[]) => `[${row.join(',')}]`;
const parsedRowsToFormula = (rows: unknown[][]) => `[${rows.map(parsedRowToFormula).join(',')}]`;

interface TracingTableProps {
  readonly value: unknown[][] | null;
  readonly formula: string;
  readonly formatOptions?: VariableFormatOptionsTable;
}

export const TracingTable = memo(function TracingTable({ value, formula, formatOptions }: TracingTableProps) {
  const [openTable, setOpenTable] = useState(false);

  const onOpenTable = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      eventStopPropagation(e);
      setOpenTable(true);
    },
    [setOpenTable],
  );
  // Try to parse the row formula. Will fail if not in format [[],[]...].
  // If it fails, return null, hide the button, and call it a day.
  const parsedFormula = useMemo(() => {
    try {
      const linesRaw = commonMathJs.parse(formula) as ArrayNode;
      return linesRaw.items.map((line) => (line as ArrayNode).items.map((cell) => cell.toString()));
    } catch (e) {
      log.error(`Cannot trace formula ${formula}`);
      log.error(e);
      return null;
    }
  }, [formula]);

  const columns = useMemo(() => {
    if (formatOptions?.columns) {
      return formatOptions.columns;
    }

    if (!value?.[0]) {
      return null;
    }

    try {
      // Generate a fake column for each cell of the first row.
      return Array(value[0].length)
        .fill(0)
        .map(
          (_, index) =>
            ({
              machineName: `${index}`,
              name: '',
              format: FormatsEnum.text,
            }) as VariableFormatOptionsColumn,
        );
    } catch (e) {
      // We're not completely sure of what we can receive here so
      // we better have a fallback.
      log.error(e);
      return null;
    }
  }, [formatOptions, value]);

  if (!columns) {
    return (
      <Fragment>
        <Button
          size={Button.Size.SMALL}
          variant={Button.Variant.LIGHT}
          onClick={onOpenTable}
        >
          <FormattedMessage defaultMessage="Show table formula" />
        </Button>

        <Modal
          isOpen={openTable}
          size={Modal.Size.LARGE}
          onClose={() => setOpenTable(false)}
        >
          <Modal.Content>
            <Modal.Header>
              <Modal.Title>
                <FormattedMessage defaultMessage="Table formula" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{formula}</Modal.Body>
          </Modal.Content>
        </Modal>
      </Fragment>
    );
  }
  return (
    <Fragment>
      {!!value && (
        <TableBuilder
          isReadonly
          columns={columns}
          value={parsedRowsToFormula(value)}
        />
      )}

      {!!parsedFormula && (
        <Fragment>
          <Button
            size={Button.Size.SMALL}
            variant={Button.Variant.LIGHT}
            onClick={onOpenTable}
          >
            <FormattedMessage defaultMessage="Show formulas" />
          </Button>

          <Modal
            isOpen={openTable}
            size={Modal.Size.LARGE}
            onClose={() => setOpenTable(false)}
          >
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Table formula" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <TableBuilder
                  isReadonly
                  columns={columns}
                  value={parsedRowsToFormula(parsedFormula)}
                />
              </Modal.Body>
            </Modal.Content>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
});
