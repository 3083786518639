import { type Dispatch, memo, type SetStateAction } from 'react';
import { useParams } from 'react-router-dom';

import { type CurrentTracingDataType, type Adjustment } from '@amalia/core/types';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { PlanRulesLayout } from '@amalia/payout-definition/plans/components';
import { type PlanCategory } from '@amalia/payout-definition/plans/types';

import { StatementAdjustmentsList } from './Adjustments/StatementAdjustmentsList';
import { StatementCategory } from './StatementCategory';
import { CURRENCY_RATE } from './StatementDetail';

interface StatementDetailPayoutProps {
  readonly setSelectAdjustment: Dispatch<SetStateAction<Adjustment | null>>;
  readonly setCurrentTracingData: Dispatch<SetStateAction<CurrentTracingDataType | null>>;
}

export const StatementDetailPayout = memo(function StatementDetailPayout({
  setSelectAdjustment,
  setCurrentTracingData,
}: StatementDetailPayoutProps) {
  const { ruleid: ruleId } = useParams<Record<string, string>>();

  const { statement } = useStatementDetailContext();

  const searchValue = '';

  return (
    <PlanRulesLayout>
      <StatementCategory
        activeRuleId={ruleId}
        globalSearchValue={searchValue}
        setCurrentTracingData={setCurrentTracingData}
      />

      {(statement.results.definitions.plan.categoriesV2 || []).map((category: PlanCategory) => (
        <StatementCategory
          key={category.name}
          activeRuleId={ruleId}
          category={category}
          globalSearchValue={searchValue}
          setCurrentTracingData={setCurrentTracingData}
        />
      ))}

      {!!statement.adjustments?.length && (
        <StatementAdjustmentsList
          currencyRate={CURRENCY_RATE}
          currencySymbol={statement.currency}
          setSelectAdjustment={setSelectAdjustment}
          statement={statement}
        />
      )}
    </PlanRulesLayout>
  );
});
