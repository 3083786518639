import * as Yup from 'yup';

import {
  FormulaBuilderBlockType,
  FormulaBuilderFunctionCategory,
  ValueOrAttributeType,
  type NumberValueOrAttribute,
  FormulaBuilderNumberOperatorOneArg,
  FormulaBuilderNumberOperatorNoArgs,
  type FormulaBuilderFunctionBlockNumber,
  type FormulaBuilderFunctionBlockPercent,
  type FormulaBuilderFunctionBlockCurrency,
} from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { isEnum } from '@amalia/ext/typescript';

import { yupFormulaBuilderFunctionArgsAttributes } from './yupValidatorFormulaBuilderFunctionString';

export const yupFormulaBuilderFunctionNumberArgs = (valueOrAttribute: NumberValueOrAttribute) => {
  switch (valueOrAttribute.type) {
    case ValueOrAttributeType.VALUE:
      return Yup.object()
        .shape({
          type: Yup.string().oneOf([ValueOrAttributeType.VALUE]).required(),
          value: Yup.number(),
        })
        .required();
    case ValueOrAttributeType.ATTRIBUTE:
      return Yup.object().shape({
        fieldType: Yup.string().oneOf(Object.values(TokenType)).required(),
        type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]).required(),
        ...('fieldType' in valueOrAttribute && {
          ...yupFormulaBuilderFunctionArgsAttributes(valueOrAttribute),
        }),
      });
    default:
      throw new Error(`Invalid valueOrAttribute type`);
  }
};

/** Number function formulaBuilder validator */
export const yupFormulaBuilderFunctionNumberNoArgs = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: NumberValueOrAttribute) => yupFormulaBuilderFunctionNumberArgs(arg)))
      .length(1),
    category: Yup.string()
      .oneOf([
        FormulaBuilderFunctionCategory.NUMBER,
        FormulaBuilderFunctionCategory.PERCENT,
        FormulaBuilderFunctionCategory.CURRENCY,
      ])
      .required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderNumberOperatorNoArgs)).nullable(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });
export const yupFormulaBuilderFunctionNumberOneArg = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: NumberValueOrAttribute) => yupFormulaBuilderFunctionNumberArgs(arg)))
      .length(2),
    category: Yup.string()
      .oneOf([
        FormulaBuilderFunctionCategory.NUMBER,
        FormulaBuilderFunctionCategory.PERCENT,
        FormulaBuilderFunctionCategory.CURRENCY,
      ])
      .required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderNumberOperatorOneArg)).nullable(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

export const yupFormulaBuilderFunctionNumber = (
  stringBlock:
    | FormulaBuilderFunctionBlockCurrency
    | FormulaBuilderFunctionBlockNumber
    | FormulaBuilderFunctionBlockPercent,
) => {
  switch (true) {
    // null is needed here because as long as we don't have an operator
    // we don't know which validator to use
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- it's a validator, it validates.
    case stringBlock.operator === null:
      return Yup.object().shape({
        isDraft: Yup.boolean().required().oneOf([false]),
      });
    case isEnum(stringBlock.operator, FormulaBuilderNumberOperatorNoArgs):
      return yupFormulaBuilderFunctionNumberNoArgs();
    case isEnum(stringBlock.operator, FormulaBuilderNumberOperatorOneArg):
      return yupFormulaBuilderFunctionNumberOneArg();
    default:
      throw new Error(`Invalid operator ${JSON.stringify(stringBlock)}`);
  }
};
