import { memo, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  useCreateConnector,
  useAuthenticateWithOAuth,
  useUpdateConnectorAuth,
} from '@amalia/data-capture/connectors/state';
import {
  type DataConnector,
  type ConnectorResponse,
  DataConnectorCategories,
  AuthType,
  DataConnectorTypes,
  type OAuthDataConnectorAuth,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';

type NetsuiteConnectorAuthFormValues = {
  clientId: string;
  clientSecret: string;
  instanceUrl: string;
};

type NetsuiteConnectorAuthFormikProps = FormikFormProps<NetsuiteConnectorAuthFormValues, ConnectorResponse>;

export type NetsuiteConnectorAuthFormProps = Omit<
  NetsuiteConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
};

export const NetsuiteConnectorAuthForm = memo(function NetsuiteConnectorAuthForm({
  dataConnector,
  children,
  ...props
}: NetsuiteConnectorAuthFormProps) {
  const dataConnectorAuth = dataConnector?.auth as OAuthDataConnectorAuth | null | undefined;

  const { mutateAsync: authenticateWithOAuth } = useAuthenticateWithOAuth(
    Yup.object().shape({
      code: Yup.string().required('code is required'),
    }),
  );

  const { mutateAsync: createConnector } = useCreateConnector();
  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth();

  const initialValues = useMemo(
    (): NetsuiteConnectorAuthFormValues => ({
      clientId: dataConnectorAuth?.clientId || '',
      clientSecret: dataConnectorAuth?.clientSecret || '',
      instanceUrl: dataConnectorAuth?.instanceUrl || '',
    }),
    [dataConnectorAuth],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        clientId: Yup.string().required(),
        clientSecret: Yup.string().required(),
        instanceUrl: Yup.string().required(),
      }),
    [],
  );

  const handleSubmit: NetsuiteConnectorAuthFormikProps['onSubmit'] = useCallback(
    async (values) => {
      const oauthQueryParams = await authenticateWithOAuth({
        connectorType: DataConnectorTypes.NETSUITE,
        queryParams: {
          clientId: values.clientId,
          clientSecret: values.clientSecret,
          instanceUrl: values.instanceUrl,
        },
      });

      const authRequest = {
        clientId: values.clientId,
        clientSecret: values.clientSecret,
        instanceUrl: values.instanceUrl,
        code: oauthQueryParams.code,
      };

      return !dataConnector
        ? createConnector({
            category: DataConnectorCategories.DATA,
            authType: AuthType.OAuth2_authorizationCode,
            type: DataConnectorTypes.NETSUITE,
            auth: authRequest,
          })
        : updateConnectorAuth({
            id: dataConnector.id,
            type: DataConnectorTypes.NETSUITE,
            auth: authRequest,
          });
    },
    [createConnector, dataConnector, authenticateWithOAuth, updateConnectorAuth],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
