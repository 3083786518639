import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { type AssignmentDates } from '@amalia/assignments/common/shared';
import { Table, useDatePickerTimestampsAdapter, type CellDatePickerProps } from '@amalia/design-system/components';
import { type UnixTimestampInSeconds } from '@amalia/ext/dates';
import { type MergeAll } from '@amalia/ext/typescript';

export type AssignmentDateCellProps<TAssignment extends AssignmentDates> = MergeAll<
  [
    Omit<CellDatePickerProps<false, TAssignment>, 'dateFormat' | 'placeholder'>,
    {
      readonly value: UnixTimestampInSeconds | null;
      readonly boundary: 'end' | 'start';
      readonly maxDate?: UnixTimestampInSeconds | null;
      readonly minDate?: UnixTimestampInSeconds | null;
      readonly onChange: (value: UnixTimestampInSeconds | null, assignment: TAssignment) => void;
    },
  ]
>;

export const AssignmentDateCell = function AssignmentDateCell<TAssignment extends AssignmentDates>({
  value,
  boundary,
  minDate,
  maxDate,
  onChange,
  row,
  ...props
}: AssignmentDateCellProps<TAssignment>) {
  const { formatMessage } = useIntl();

  const datePickerProps = useDatePickerTimestampsAdapter({
    value,
    minDate,
    maxDate,
    boundary,
    onChange: useCallback((value: UnixTimestampInSeconds | null) => onChange(value, row), [onChange, row]),
  });

  return (
    <Table.Cell.DatePicker<false>
      {...props}
      {...datePickerProps}
      clearButtonLabel={formatMessage({ defaultMessage: 'Reset date to indefinite' })}
      dateFormat="yyyy-MM-dd"
      row={row}
      placeholder={
        <span
          css={(theme) => css`
            color: ${theme.ds.colors.gray[700]};
          `}
        >
          {formatMessage({ defaultMessage: 'Indefinite' })}
        </span>
      }
    />
  );
};
