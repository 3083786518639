import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { useEditor } from '@tiptap/react';
import { type Node } from 'prosemirror-model';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useBoolState } from '@amalia/ext/react/hooks';

import { makeFormulaTokenExtension } from '../../components/formula-editor-content/token-extension/TokenExtension';
import { FunctionNodeExtension } from '../../formula-tiptap-extensions/function-node-extension';
import { type FormulaEditorToken } from '../../types/formulaEditorToken';
import { useSuggestions } from '../use-formula-editor-suggestions/useSuggestions';

import { compileTextNodesToFormulaTokenNodes } from './compile-text-nodes-to-formula-nodes';
import { findActiveFunctionNode } from './find-active-function-node';

export const useFormulaEditor = ({
  formulaEditorTokens,
  content,
  testId,
  setActiveNode,
}: {
  formulaEditorTokens: FormulaEditorToken[];
  content?: string;
  testId?: string;
  setActiveNode: (node?: { node: Node; position: number }) => void;
}) => {
  const id = 'formula';
  const name = 'formula';

  const { formatMessage } = useIntl();
  const FormulaTokenExtension = useMemo(() => makeFormulaTokenExtension(formulaEditorTokens), [formulaEditorTokens]);

  const { suggestion, isOpen: isSuggestionDropdownOpened } = useSuggestions(formulaEditorTokens);

  const { setCompilationRunningTrue, setCompilationRunningFalse, isCompilationRunning } = useBoolState(
    false,
    'compilationRunning',
  );
  const extensions = useMemo(
    () => [
      Document,
      History,
      Paragraph,
      Placeholder.configure({
        placeholder: formatMessage({
          defaultMessage: 'Enter @ to view the library and type a keyword to search in it. ',
        }),
      }),
      Text,
      FunctionNodeExtension,
      Mention.configure({
        suggestion,
      }),
      FormulaTokenExtension,
    ],
    [FormulaTokenExtension, formatMessage, suggestion],
  );

  const editor = useEditor(
    {
      extensions,
      editorProps: {
        attributes: {
          name,
          id,
          ...(testId ? { 'data-testid': testId } : {}),
        },
      },
      content,
      // This is called when the caret position changes in the editor.
      onSelectionUpdate: ({ editor }) => {
        // If editor is being updated (we are searching for a token or editor compilation is running), we don't need to find the active node.
        if (!isSuggestionDropdownOpened.current && !isCompilationRunning) {
          const activeFunctionNode = findActiveFunctionNode(editor, formulaEditorTokens);
          setActiveNode(activeFunctionNode);
        }
      },
      // This is called when the editor content changes.
      onUpdate: ({ editor, transaction }) => {
        if (!isSuggestionDropdownOpened.current && !isCompilationRunning && transaction.docChanged) {
          setCompilationRunningTrue();
          compileTextNodesToFormulaTokenNodes(editor, formulaEditorTokens);
          setCompilationRunningFalse();
        }
      },
      onBlur: () => {},
    },
    // We need to reload the editor when the tokens change.
    [content, formulaEditorTokens.length],
  );

  return { editor };
};
