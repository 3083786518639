/// <reference types="redux-thunk/extend-redux" />

import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import currenciesReducer from './currencies/reducer';
import customObjectDefinitionsReducer from './customObjectDefinitions/reducer';
import filtersReducer from './filters/reducer';
import { errorHandler } from './middlewares';
import overwritesReducer from './overwrites/reducer';
import paymentsReducer from './payments/reducer';
import planForecastsReducer from './planForecasts/reducer';
import plansReducer from './plans/reducer';
import snackbarsReducer from './snackbars/reducer';
import statementsReducer from './statements/reducer';
import { type RootState } from './types';
import usersReducer from './users/reducer';
import userStatementsReducer from './userStatements/reducer';

// Add __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ to window to enable Redux DevTools.
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducer = combineReducers<RootState>({
  currencies: currenciesReducer,
  customObjectDefinitions: customObjectDefinitionsReducer,
  filters: filtersReducer,
  overwrites: overwritesReducer,
  payments: paymentsReducer,
  planForecasts: planForecastsReducer,
  plans: plansReducer,
  snackbars: snackbarsReducer,
  statements: statementsReducer,
  users: usersReducer,
  userStatements: userStatementsReducer,
});

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk, errorHandler];

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export type ThunkDispatch = typeof store.dispatch;

export const useThunkDispatch = () => useDispatch<ThunkDispatch>();
