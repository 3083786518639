import { IconTrash } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Table } from '@amalia/design-system/components';

import { rowKeySymbol, type TableBuilderDataGridRowData } from '../TableBuilder.types';

export type TableBuilderRowActionsCellProps = {
  readonly row: TableBuilderDataGridRowData;
  readonly disabled?: boolean;
  readonly onDeleteRow: (key: TableBuilderDataGridRowData[typeof rowKeySymbol]) => void;
};

export const TableBuilderRowActionsCell = memo(function TableBuilderRowActionsCell({
  row,
  disabled,
  onDeleteRow,
}: TableBuilderRowActionsCellProps) {
  const { formatMessage } = useIntl();

  const handleDeleteRow = useCallback(() => onDeleteRow(row[rowKeySymbol]), [onDeleteRow, row]);

  return (
    <Table.Cell.Actions>
      <Table.Cell.IconAction
        disabled={disabled}
        icon={<IconTrash />}
        label={formatMessage({ defaultMessage: 'Delete row' })}
        variant={Table.Cell.IconAction.Variant.DANGER}
        onClick={handleDeleteRow}
      />
    </Table.Cell.Actions>
  );
});
