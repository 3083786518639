import { useTheme } from '@emotion/react';
import { Fragment, memo, useCallback, useMemo } from 'react';

import { StatementErrorLevel } from '@amalia/core/types';
import { mathBetween } from '@amalia/ext/typescript';

import { Gauge } from './gauge/Gauge';

export type StatementGaugeGroupProps = {
  readonly progress?: {
    primary: number | null | undefined;
    secondary: number | null | undefined;
  };
  readonly onGaugeHover?: (identifier: string, isHover: boolean) => void;
  readonly errorLevel?: StatementErrorLevel;
  readonly width?: number;
  readonly gap?: number;
  readonly strokeWidth?: number;

  readonly isForecast?: boolean;
};

export const StatementGaugeGroup = memo(function StatementGaugeGroup({
  isForecast = false,
  errorLevel,
  onGaugeHover,
  progress,
  width = 176,
  gap = 4,
  strokeWidth = 9.6,
}: StatementGaugeGroupProps) {
  const theme = useTheme();

  const themeColorForStatementErrorType: Record<StatementErrorLevel, string> = {
    [StatementErrorLevel.WARNING]: theme.ds.colors.warning[500],
    [StatementErrorLevel.ERROR]: theme.ds.colors.danger[500],
  };

  const gaugeColor = useMemo(
    () =>
      isForecast
        ? {
            primary: theme.ds.hues.purple[500],
            primaryEmptyStroke: theme.ds.colors.graySecondary[100],
            secondary: theme.ds.hues.purple[200],
            secondaryEmptyStroke: theme.ds.colors.graySecondary[100],
          }
        : {
            primary: theme.ds.colors.primary[400],
            primaryEmptyStroke: theme.ds.colors.graySecondary[100],
            secondary: theme.ds.colors.primary[200],
            secondaryEmptyStroke: theme.ds.colors.graySecondary[100],
          },
    [isForecast, theme],
  );

  const gaugeHover = useCallback(
    (identifier: string, isHover: boolean) => onGaugeHover?.(identifier, isHover),
    [onGaugeHover],
  );

  const innerGaugeRadius = (width - (strokeWidth + gap) * 2) / 2;

  return (
    <svg
      height={width}
      width={width}
    >
      {errorLevel ? (
        <Gauge
          boxSize={width}
          color={themeColorForStatementErrorType[errorLevel]}
          progress={100}
          radius={innerGaugeRadius}
          stroke={strokeWidth}
        />
      ) : (
        <Fragment>
          <Gauge
            boxSize={width}
            color={gaugeColor.primary}
            colorEmptyStroke={gaugeColor.primaryEmptyStroke}
            progress={mathBetween(progress?.primary || 0, 0, 100)}
            radius={width / 2}
            stroke={strokeWidth}
            onGaugeHover={(isHover: boolean) => gaugeHover('primary', isHover)}
          />

          <Gauge
            boxSize={width}
            color={gaugeColor.secondary}
            colorEmptyStroke={gaugeColor.secondaryEmptyStroke}
            progress={mathBetween(progress?.secondary || 0, 0, 100)}
            radius={innerGaugeRadius}
            stroke={strokeWidth}
            onGaugeHover={(isHover: boolean) => gaugeHover('secondary', isHover)}
          />
        </Fragment>
      )}
    </svg>
  );
});
