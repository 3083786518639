import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { css } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import clsx from 'clsx';
import { memo, useContext } from 'react';

import { Tooltip, Typography } from '@amalia/design-system/components';
import { useDatagridStyles } from '@amalia/lib-ui';

import { RowsTableContext } from './RowsTableContext';
import { useRowsTableStyles } from './useRowsTableStyles';

export const RowsTableHeaderCell = memo(function RowsTableHeaderCell({
  column,
  children,
  ...restProps
}: TableHeaderRow.CellProps) {
  const classes = useDatagridStyles();
  const classesRowsTable = useRowsTableStyles();

  const { columnDescriptions } = useContext(RowsTableContext);

  const helpLabel = columnDescriptions[column.name];

  return (
    <TableHeaderRow.Cell
      column={column}
      {...restProps}
      className={clsx(
        (restProps as { className?: string }).className,
        classes.header,
        column.name === 'actions' ? classesRowsTable.headerActionsCell : undefined,
      )}
    >
      <Typography
        variant={Typography.Variant.BODY_BASE_MEDIUM}
        css={css`
          display: flex;
          align-items: center;
          gap: 6px;
        `}
      >
        {children}

        {!!helpLabel && (
          <Tooltip content={helpLabel}>
            <IconHelp size={18} />
          </Tooltip>
        )}
      </Typography>
    </TableHeaderRow.Cell>
  );
});
