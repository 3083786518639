import * as Yup from 'yup';

import {
  FormulaBuilderBlockType,
  FormulaBuilderFunctionCategory,
  ValueOrAttributeType,
  type FormulaBuilderFunctionBlockBoolean,
  FormulaBuilderBooleanOperatorNoArgs,
  type AttributeValue,
} from '@amalia/amalia-lang/formula/types';
import { isEnum } from '@amalia/ext/typescript';

import { yupFormulaBuilderFunctionArgsAttributes } from './yupValidatorFormulaBuilderFunctionString';

export const yupFormulaBuilderFunctionBooleanArgs = (attributeValue: unknown) => {
  if (
    typeof attributeValue === 'object' &&
    !!attributeValue &&
    'type' in attributeValue &&
    attributeValue.type === ValueOrAttributeType.ATTRIBUTE
  ) {
    return Yup.object().shape({
      type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]).required(),
      ...('fieldType' in attributeValue && {
        ...yupFormulaBuilderFunctionArgsAttributes(attributeValue as AttributeValue),
      }),
    });
  }

  throw new Error(`Invalid valueOrAttribute type`);
};

/** Boolean function formulaBuilder validator */
export const yupFormulaBuilderFunctionBooleanNoArgs = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: unknown) => yupFormulaBuilderFunctionBooleanArgs(arg)))
      .length(1)
      .required(),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.BOOLEAN]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderBooleanOperatorNoArgs)).required(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

export const yupFormulaBuilderFunctionBoolean = (stringBlock: FormulaBuilderFunctionBlockBoolean) => {
  switch (true) {
    // null is needed here because as long as we don't have an operator
    // we don't know which validator to use
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- validator
    case stringBlock.operator === null:
      return Yup.object().shape({
        isDraft: Yup.boolean().required().oneOf([false]),
      });
    case isEnum(stringBlock.operator, FormulaBuilderBooleanOperatorNoArgs):
      return yupFormulaBuilderFunctionBooleanNoArgs();
    default:
      throw new Error(`Invalid operator ${stringBlock.operator}`);
  }
};
