import { Fragment, memo } from 'react';

import { OVERWRITE_CONTEXT, OverwriteModalContainer } from '@amalia/data-correction/overwrites/components';
import { useCompanyCustomization } from '@amalia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amalia/lib-ui';
import { RuleLayout } from '@amalia/payout-definition/plans/rules/components';
import { type PlanRule } from '@amalia/payout-definition/plans/types';

import { KpiDisplayDropdown } from './kpi-display-dropdown/KpiDisplayDropdown';
import * as styles from './KPIList.styles';
import { Section } from './section/Section';
import { useKPIDisplay } from './useKPIDisplay';
import { useKPIOverwrite } from './useKPIOverwrite';
import { useStatementRuleComputedVariables } from './useStatementRuleComputedVariables';

type KPIListProps = {
  readonly ruleDefinition: PlanRule;
};

export const KPIList = memo(function KPIList({ ruleDefinition }: KPIListProps) {
  const statementContext = useStatementDetailContext();
  const { legacyKpiCardView } = useCompanyCustomization();

  const statementRuleComputedVariables = useStatementRuleComputedVariables(statementContext, ruleDefinition);

  const {
    clearOverwrite,
    closeOverwriteModal,
    isOverwriteModalOpen,
    openOverwriteModal,
    overwriteObjectDetails,
    saveOverwrite,
    setOverwriteObjectDetails,
  } = useKPIOverwrite(statementContext);

  const { availableKPIOptions, displayedKPIIds, setDisplayedKPIIds, KPISections } = useKPIDisplay(
    statementContext,
    ruleDefinition,
    statementRuleComputedVariables,
  );

  if (!availableKPIOptions?.length) {
    return null;
  }

  return (
    <Fragment>
      {!!availableKPIOptions.length && (
        <RuleLayout.Kpis>
          <div css={styles.kpisAndFiltersContainer}>
            <div css={styles.sectionsContainer(legacyKpiCardView)}>
              {KPISections.map((section) => (
                <Section
                  key={section.id}
                  clearOverwrite={clearOverwrite}
                  openOverwriteModal={openOverwriteModal}
                  ruleDefinition={ruleDefinition}
                  section={section}
                  setOverwriteObjectDetails={setOverwriteObjectDetails}
                  statementRuleComputedVariables={statementRuleComputedVariables}
                />
              ))}
            </div>

            <div css={styles.filtersContainers}>
              <KpiDisplayDropdown
                options={availableKPIOptions}
                value={displayedKPIIds}
                onChange={setDisplayedKPIIds}
              />
            </div>
          </div>
        </RuleLayout.Kpis>
      )}

      {!!overwriteObjectDetails && (
        <OverwriteModalContainer
          currentObjectDetails={overwriteObjectDetails}
          handleClose={closeOverwriteModal}
          handleSubmit={saveOverwrite}
          isOpen={isOverwriteModalOpen}
          isSimulation={statementContext.isForecastedView}
          overwriteContext={OVERWRITE_CONTEXT.KPI}
        />
      )}
    </Fragment>
  );
});
