import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type AttributeValueForm } from '@amalia/amalia-lang/formula/form/types';
import { type AttributeValue } from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { CountBadge, Select, type SelectProps } from '@amalia/design-system/components';

import {
  type AttributesOptions,
  type AttributeSelectOption,
} from '../../hooks/use-attributes-options/useAttributesOptions';
import { useMapAttributeValueToSelectOption } from '../../hooks/use-map-attribute-value-to-select-option/useMapAttributeValueToSelectOption';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';

export type AttributeSelectorProps = {
  readonly selectedAttribute: AttributeValueForm;
  readonly options: AttributesOptions;
  readonly onChange: (attribute: AttributeValue) => void;
};

export const AttributeSelector = memo(function AttributeSelector({
  selectedAttribute,
  options,
  onChange,
}: AttributeSelectorProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const { formatMessage } = useIntl();
  const mapAttributeValueToSelectOption = useMapAttributeValueToSelectOption();

  const handleChangeAttributeValue = useCallback(
    (option: AttributeSelectOption<AttributeValue>) => onChange(option.attribute),
    [onChange],
  );

  const value = useMemo(
    () => mapAttributeValueToSelectOption(selectedAttribute),
    [mapAttributeValueToSelectOption, selectedAttribute],
  );

  const selectOptions: SelectProps<AttributeSelectOption<AttributeValue>, false, true, false>['options'] = useMemo(
    () => [
      {
        label: formatMessage({ defaultMessage: 'Variables' }),
        countBadge: <CountBadge variant={CountBadge.Variant.CYAN}>{options[TokenType.VARIABLE].length}</CountBadge>,
        options: options[TokenType.VARIABLE],
        initialIsOpen: false,
      },
      {
        label: formatMessage(
          { defaultMessage: 'Properties from {customObjectName}' },
          { customObjectName: customObjectDefinition?.name ?? '' },
        ),
        countBadge: <CountBadge variant={CountBadge.Variant.ORANGE}>{options[TokenType.PROPERTY].length}</CountBadge>,
        initialIsOpen: false,
        options: options[TokenType.PROPERTY],
      },
      {
        label: formatMessage({ defaultMessage: 'Virtuals' }),
        countBadge: (
          <CountBadge variant={CountBadge.Variant.ORANGE}>{options[TokenType.VIRTUAL_PROPERTY].length}</CountBadge>
        ),
        initialIsOpen: false,
        options: options[TokenType.VIRTUAL_PROPERTY],
      },
      {
        label: formatMessage({ defaultMessage: 'Fields' }),
        countBadge: <CountBadge variant={CountBadge.Variant.ORANGE}>{options[TokenType.FIELD].length}</CountBadge>,
        initialIsOpen: false,
        options: options[TokenType.FIELD],
      },
      {
        label: formatMessage({ defaultMessage: 'Quotas' }),
        initialIsOpen: false,
        countBadge: <CountBadge variant={CountBadge.Variant.PURPLE}>{options[TokenType.QUOTA].length}</CountBadge>,
        options: options[TokenType.QUOTA],
      },
      ...options[TokenType.LINK].map(({ relationship, ...relAttributes }) => ({
        label: formatMessage(
          { defaultMessage: 'Properties from {relationshipName}' },
          { relationshipName: relationship.label },
        ),
        countBadge: (
          <CountBadge variant={CountBadge.Variant.RED}>
            {relAttributes[TokenType.PROPERTY].length + relAttributes[TokenType.VIRTUAL_PROPERTY].length}
          </CountBadge>
        ),
        initialIsOpen: false,
        options: [...relAttributes[TokenType.PROPERTY], ...relAttributes[TokenType.VIRTUAL_PROPERTY]],
      })),
      {
        label: formatMessage({ defaultMessage: 'Keywords' }),
        initialIsOpen: false,
        countBadge: <CountBadge variant={CountBadge.Variant.MAGENTA}>{options[TokenType.KEYWORD].length}</CountBadge>,
        options: options[TokenType.KEYWORD],
      },
    ],
    [formatMessage, options, customObjectDefinition],
  );

  return (
    <Select<AttributeSelectOption<AttributeValue>, false, true, false>
      useOptionAsValue
      isClearable={false}
      options={selectOptions}
      placeholder={formatMessage({ defaultMessage: 'Select field' })}
      size={Select.Size.SMALL}
      value={value}
      onChange={handleChangeAttributeValue}
    />
  );
});
