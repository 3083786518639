import { IconArrowLeft, IconPercentage } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type FormulaBuilderFunctionBlockNumberForm,
  type FormulaBuilderFunctionBlockPercentForm,
  isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyOneArgForm,
  type FormulaBuilderFunctionBlockCurrencyForm,
} from '@amalia/amalia-lang/formula/form/types';
import { FORMULA_KEYWORDS } from '@amalia/amalia-lang/formula/keywords/shared';
import {
  FormulaBuilderFunctionCategory,
  FormulaBuilderNumberOperatorNoArgs,
  FormulaBuilderNumberOperatorOneArg,
  FormulaKeyword,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Dropdown, IconButton, Typography } from '@amalia/design-system/components';
import { ComponentSwitch } from '@amalia/ext/react/components';
import { isEnum } from '@amalia/ext/typescript';

import {
  useAttributesOptions,
  type UseAttributesOptionsOptions,
} from '../../../hooks/use-attributes-options/useAttributesOptions';
import { useGetFormulaBuilderAttributeLabel } from '../../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useValueOrAttributeOptions } from '../../../hooks/use-value-or-attribute-options/useValueOrAttributeOptions';
import { useFormulaBuilderContext } from '../../formula-builder/FormulaBuilder.context';
import { type FormulaConditionTagProps } from '../../formula-condition-tag/FormulaConditionTag';
import { PopoverValueOrAttributeSelector } from '../../popover-value-or-attribute-selector/PopoverValueOrAttributeSelector';
import { useValueOrAttributeHandlers } from '../../popover-value-or-attribute-selector/use-value-or-attribute-handlers/useValueOrAttributeHandlers';
import { NumberOperatorNoArgsToIcon } from '../FormulaConditionTagFunctionNumberIcon.mapper';

import { fieldOperatorMessages, operatorMessage } from './FunctionNumberPopover.messages';
import * as styles from './FunctionNumberPopover.styles';

enum FunctionNumberPopoverStep {
  OPERATOR = 'OPERATOR',
  OTHER = 'OTHER',
}

const CONDITION_CATEGORY_TO_FORMAT_ENUM = {
  [FormulaBuilderFunctionCategory.NUMBER]: FormatsEnum.number,
  [FormulaBuilderFunctionCategory.PERCENT]: FormatsEnum.percent,
  [FormulaBuilderFunctionCategory.CURRENCY]: FormatsEnum.currency,
} as const;

export type FunctionNumberPopoverProps<
  T extends
    | FormulaBuilderFunctionBlockCurrencyForm
    | FormulaBuilderFunctionBlockNumberForm
    | FormulaBuilderFunctionBlockPercentForm,
> = {
  readonly condition: T;
  readonly onChange: FormulaConditionTagProps<T>['onChange'];
  readonly onClose?: () => void;
};

const getInputIcon = (category: FormulaBuilderFunctionCategory) => {
  if (category === FormulaBuilderFunctionCategory.PERCENT) {
    return <IconPercentage size="14" />;
  }
  return undefined;
};

const FunctionNumberPopoverBase = function FunctionNumberPopover<
  T extends
    | FormulaBuilderFunctionBlockCurrencyForm
    | FormulaBuilderFunctionBlockNumberForm
    | FormulaBuilderFunctionBlockPercentForm,
>({ condition, onChange = noop, onClose = noop }: FunctionNumberPopoverProps<T>) {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const valueOrAttributeOptions = useValueOrAttributeOptions(propertyName, customObjectDefinition?.name);

  const [step, setStep] = useState<FunctionNumberPopoverStep>(
    condition.isDraft || !condition.operator || isEnum(condition.operator, FormulaBuilderNumberOperatorNoArgs)
      ? FunctionNumberPopoverStep.OPERATOR
      : FunctionNumberPopoverStep.OTHER,
  );

  const handleGoToOperatorStep = useCallback(() => setStep(FunctionNumberPopoverStep.OPERATOR), []);

  const handleChangeOperator = useCallback(
    (operator: FormulaBuilderNumberOperatorNoArgs | FormulaBuilderNumberOperatorOneArg) => {
      const args = isEnum(operator, FormulaBuilderNumberOperatorOneArg)
        ? [condition.args[0], { type: ValueOrAttributeType.ATTRIBUTE }]
        : [condition.args[0]];

      onChange({
        ...condition,
        operator,
        args,
        isDraft: !isEnum(operator, FormulaBuilderNumberOperatorNoArgs),
      } as T);
      if (isEnum(operator, FormulaBuilderNumberOperatorOneArg)) {
        setStep(FunctionNumberPopoverStep.OTHER);
      } else {
        onClose();
      }
    },
    [condition, onChange, onClose],
  );

  const { handleChangeValueOrAttribute, handleChangeManualValue, handleChangeAttributeValue } =
    useValueOrAttributeHandlers({ condition, onChange });

  const NUMBER_ATTRIBUTES_OPTIONS_FILTERS: UseAttributesOptionsOptions = useMemo(
    () => ({
      filterProperty: (property) => property.format === CONDITION_CATEGORY_TO_FORMAT_ENUM[condition.category],
      filterVariable: (variable) => variable.format === CONDITION_CATEGORY_TO_FORMAT_ENUM[condition.category],
      keywords: Object.values(FormulaKeyword).filter(
        (key) => FORMULA_KEYWORDS[key].format === CONDITION_CATEGORY_TO_FORMAT_ENUM[condition.category],
      ),
      showFilteredAsDisabled: false,
    }),
    [condition.category],
  );

  const attributesOptions = useAttributesOptions(NUMBER_ATTRIBUTES_OPTIONS_FILTERS);

  return (
    <div>
      <ComponentSwitch value={step}>
        <ComponentSwitch.Item value={FunctionNumberPopoverStep.OPERATOR}>
          <Dropdown.Title>{propertyName}</Dropdown.Title>

          {Object.values(FormulaBuilderNumberOperatorNoArgs).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              label={<FormattedMessage {...operatorMessage[operator]} />}
              icon={
                <NumberOperatorNoArgsToIcon
                  operator={operator}
                  size={18}
                />
              }
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
          {Object.values(FormulaBuilderNumberOperatorOneArg).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              label={<FormattedMessage {...operatorMessage[operator]} />}
              icon={
                <NumberOperatorNoArgsToIcon
                  operator={operator}
                  size={18}
                />
              }
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
        </ComponentSwitch.Item>
        {isFormulaBuilderFunctionBlockNumberOrPercentOrCurrencyOneArgForm(condition) && (
          <ComponentSwitch.Item value={FunctionNumberPopoverStep.OTHER}>
            <div css={styles.otherStepPopover}>
              <div css={styles.headerContainer}>
                <IconButton
                  icon={<IconArrowLeft />}
                  label={<FormattedMessage defaultMessage="Go back to operator" />}
                  onClick={handleGoToOperatorStep}
                />
                {!!condition.operator && (
                  <Typography variant={Typography.Variant.BODY_SMALL_BOLD}>
                    <FormattedMessage
                      {...fieldOperatorMessages[condition.operator]}
                      values={{ propertyName }}
                    />
                  </Typography>
                )}
              </div>

              <div css={styles.form}>
                <PopoverValueOrAttributeSelector
                  arg={condition.args[1]}
                  attributesOptions={attributesOptions}
                  conditionCategory={condition.category}
                  inputType="number"
                  leftIcon={getInputIcon(condition.category)}
                  valueOrAttributeOptions={valueOrAttributeOptions}
                  onChangeAttributeValue={handleChangeAttributeValue}
                  onChangeManualValue={handleChangeManualValue}
                  onChangeValueOrAttribute={handleChangeValueOrAttribute}
                />
              </div>
            </div>
          </ComponentSwitch.Item>
        )}
      </ComponentSwitch>
    </div>
  );
};

export const FunctionNumberPopover = memo(FunctionNumberPopoverBase) as typeof FunctionNumberPopoverBase;
