import styled from '@emotion/styled/macro';
import { Fragment, memo, type ReactElement } from 'react';

import { UNKNOWN_TOKEN_TYPE } from '@amalia/amalia-lang/tokens/types';
import { Divider, Popover, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';

import { type FormulaEditorUnknownToken, type FormulaEditorToken } from '../../../../types/formulaEditorToken';

import { TokenNodePopoverActions } from './TokenNodePopoverActions';

const PopoverLayout = styled(Popover.Layout)`
  min-height: 48px;
  min-width: 360px;
  max-width: 500px;
`;

const PopoverBody = styled(Popover.Body)`
  padding: 0;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 16px;
  width: 100%;
`;

type TokenNodePopoverProps = {
  readonly token: FormulaEditorToken | FormulaEditorUnknownToken;
  readonly children: ReactElement;
  readonly isOpen?: boolean;
};

export const TokenNodePopover = memo(function TokenNodePopover({ token, isOpen, children }: TokenNodePopoverProps) {
  return (
    <Popover
      autoFocus={false}
      distance={0}
      initialFocus={-1}
      isOpen={isOpen}
      placement="top-start"
      content={
        <PopoverLayout>
          <PopoverBody>
            <BodyContainer>
              <div>
                <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>
                  <em>{token.tooltipContent.title}</em>
                </Typography>
              </div>
              <div>
                <Typography variant={TypographyVariant.BODY_BASE_REGULAR}>{token.tooltipContent.body}</Typography>
              </div>
            </BodyContainer>

            {token.type !== UNKNOWN_TOKEN_TYPE && (
              <Fragment>
                <Divider.Horizontal />

                <TokenNodePopoverActions token={token} />
              </Fragment>
            )}
          </PopoverBody>
        </PopoverLayout>
      }
    >
      {children}
    </Popover>
  );
});
