import { css } from '@emotion/react';
import { IconX } from '@tabler/icons-react';
import { keyBy } from 'lodash';
import { type Dispatch, memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { routes } from '@amalia/core/routes';
import { type Statement, type StatementDataset, type CurrentTracingDataType } from '@amalia/core/types';
import { Group, IconButton, Stack, Typography, useSnackbars } from '@amalia/design-system/components';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { useQueryString } from '@amalia/ext/react-router-dom';
import { TracingContext, type TracingContextInterface } from '@amalia/lib-ui';
import { StatementDatasetsApiClient } from '@amalia/payout-calculation/statements/state';

import { TracingRow } from './TracingRow';
import { TracingRule } from './TracingRule';

interface TracingPageProps {
  readonly currentTracingData?: CurrentTracingDataType;
  readonly setCurrentTracingData: Dispatch<any>;
  readonly statement: Statement;
  readonly currencySymbol: CurrencySymbolsEnum;
  readonly currencyRate: number;
}

/**
 * TracingPage
 * Main component of Tracing. Acts as a container that render selectively a child component according to the tracing data
 */
export const TracingPage = memo(function TracingPage({
  currentTracingData,
  setCurrentTracingData,
  statement,
  currencySymbol,
  currencyRate,
}: TracingPageProps) {
  const urlParams = useQueryString();
  const { ruleid: ruleId } = useParams<Record<string, string>>();
  const navigate = useNavigate();
  const { snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  const closeTracing = useCallback(
    (event: any) => {
      // If the user clicks the close button, set the tracingData to null in the parent component
      event.preventDefault();
      if (setCurrentTracingData) {
        setCurrentTracingData(null);

        // if we have a backtostatementid param, it means that we come from another statement.
        // If that's the case, get back to it, and count on redux to have save the navigation state
        if (urlParams?.backtostatementid) {
          navigate(generatePath(routes.STATEMENT, { id: urlParams.backtostatementid }));
        } else {
          navigate(
            ruleId
              ? generatePath(routes.STATEMENT_RULE, { id: statement.id, ruleid: ruleId })
              : generatePath(routes.STATEMENT, { id: statement.id }),
          );
        }
      } else {
        navigate(generatePath(routes.STATEMENTS));
      }
    },
    [navigate, setCurrentTracingData, ruleId, statement, urlParams],
  );

  // Populate a record of datasets (by datasetId) we're encountering in the tracing.
  const [statementDatasets, setStatementDatasets] = useState<Record<string, StatementDataset>>({});

  useAsyncEffect(async () => {
    try {
      const statementDatasetsToLoad = await StatementDatasetsApiClient.fetchDatasets(statement.id);
      setStatementDatasets(keyBy(statementDatasetsToLoad, 'datasetId'));
    } catch (e) {
      snackError(e.message);
    }
  }, [statement.id]);

  const contextContent = useShallowObjectMemo<TracingContextInterface>({
    statementDatasets,
    statementResults: statement.results,
  });

  if (!currentTracingData?.rule) return null;

  const tracingRuleDefinition = statement.results.definitions.plan.rules?.find(
    (rule) => rule.ruleMachineName === currentTracingData?.rule?.ruleMachineName,
  );

  const heading = (
    currentTracingData.datasetRow?.content ? currentTracingData.datasetRow?.content?.name : tracingRuleDefinition?.name
  ) as string;

  return (
    <TracingContext.Provider value={contextContent}>
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <Group
          align="center"
          justify="space-between"
          css={css`
            margin: 24px 0;
          `}
        >
          {/* If tracing per deal, try to print name (as title) + id (as subtitle).
            If no name, print id as title. If tracing, print rule name as title */}
          <Typography variant={Typography.Variant.HEADING_4_BOLD}>{heading}</Typography>

          <IconButton
            icon={<IconX />}
            label={formatMessage({ defaultMessage: 'Close tracing' })}
            onClick={closeTracing}
          />
        </Group>

        <Stack gap={24}>
          {currentTracingData.fields && currentTracingData.datasetRow && currentTracingData.dataset ? (
            <TracingRow
              currencyRate={currencyRate}
              currencySymbol={currencySymbol}
              dataset={currentTracingData.dataset}
              datasetRow={currentTracingData.datasetRow}
              fields={currentTracingData.fields}
              ruleResult={currentTracingData.rule}
              statement={statement}
            />
          ) : (
            <TracingRule
              computedRule={currentTracingData.rule}
              currencyRate={currencyRate}
              currencySymbol={currencySymbol}
              statement={statement}
            />
          )}
        </Stack>
      </div>
    </TracingContext.Provider>
  );
});
