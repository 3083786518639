import { css } from '@emotion/react';
import { lazy, memo, Suspense, useMemo } from 'react';
import { Route } from 'react-router-dom';
import * as Yup from 'yup';

import { Layout, PageContainer } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { CircularLoader, Group, Stack, type SelectOption } from '@amalia/design-system/components';
import { AmaliaHead } from '@amalia/design-system/ext';
import { useShallowObjectMemo, useStateInLocalStorage } from '@amalia/ext/react/hooks';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';
import { useCompaniesList, useIsSALoading } from '@amalia/superadmin/state';

import CompaniesRoutes from './companies/companies.routes';
import { SuperadminHeader } from './superadmin-header/SuperadminHeader';
import { SARouterContext } from './utils/SARouterContext';

const CalculationsPage = lazy(() => import('./calculations/CalculationsPage'));
const CompaniesEditPage = lazy(() => import('./companies/CompaniesEditPage'));
const MasterSettingsPage = lazy(() => import('./masterSettings/MasterSettingsPage'));
const RefreshmentsPage = lazy(() => import('./refreshments/RefreshmentsPage'));
const ConnectorsPage = lazy(() => import('./connectors/ConnectorsPage'));
const StatementsPage = lazy(() => import('./statements/StatementsPage'));
const UsersPage = lazy(() => import('./users/UsersPage'));
const PlansPage = lazy(() => import('./plans/PlansPage'));

type CompanyOption = SelectOption<string>;

export const SuperAdminRoutes = memo(function SuperAdminRoutes() {
  const isSALoading = useIsSALoading();

  const { data: companyList } = useCompaniesList(true);

  const [selectedCompanies, setSelectedCompanies] = useStateInLocalStorage<string[]>(
    'saCompaniesListId',
    [],
    Yup.array().of(Yup.string().required()),
  );

  const companiesOptions: CompanyOption[] = useMemo(
    () =>
      (companyList ?? [])
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map((company) => ({
          value: company.id,
          label: company.name,
        })),
    [companyList],
  );

  const saRouterContextContent = useShallowObjectMemo({
    selectedCompanies,
    companies: companiesOptions,
  });

  return (
    <Layout>
      <PageContainer showLoadingBar={!!isSALoading}>
        <Stack gap={16}>
          <SuperadminHeader
            companiesOptions={companiesOptions}
            selectedCompanies={selectedCompanies}
            onChangeSelectedCompanies={setSelectedCompanies}
          />

          <SARouterContext.Provider value={saRouterContextContent}>
            <div>
              <Suspense
                fallback={
                  <Group
                    justify="center"
                    css={css`
                      padding: 40px;
                    `}
                  >
                    <CircularLoader size={CircularLoader.Size.LARGE} />
                  </Group>
                }
              >
                <RootRoutes>
                  <Route
                    path={routes.SUPERADMIN_HOME}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Home" />
                        <CompaniesEditPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_SETTINGS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Master settings" />
                        <MasterSettingsPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={`${routes.SUPERADMIN_COMPANIES}/*`}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Companies" />
                        <CompaniesRoutes />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_CALCULATIONS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Calculations" />
                        <CalculationsPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_REFRESHMENTS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Data refreshments" />
                        <RefreshmentsPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_CONNECTORS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Connectors" />
                        <ConnectorsPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_STATEMENTS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Statements" />
                        <StatementsPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_USERS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Users" />
                        <UsersPage />
                      </AbilityRouteProtector>
                    }
                  />

                  <Route
                    path={routes.SUPERADMIN_PLANS}
                    element={
                      <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                        <AmaliaHead title="Superadmin | Plans" />
                        <PlansPage />
                      </AbilityRouteProtector>
                    }
                  />
                </RootRoutes>
              </Suspense>
            </div>
          </SARouterContext.Provider>
        </Stack>
      </PageContainer>
    </Layout>
  );
});
