import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { AlertBanner, FormikInput, Modal, Stack, TextLink, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import {
  PowerBiRetardedConnectorAuthForm,
  type PowerBiRetardedConnectorAuthFormProps,
} from '../PowerBiRetardedConnectorAuthForm';

export type PowerBiRetardedConnectorAuthFormModalProps = Omit<
  PowerBiRetardedConnectorAuthFormProps,
  'children' | 'onSubmitSuccess'
> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const PowerBiRetardedConnectorAuthFormModal = memo(function PowerBiRetardedConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: PowerBiRetardedConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <PowerBiRetardedConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ status, isValid, isSubmitting }) => (
          <ConnectorAuthFormModalContent
            connectorType={DataConnectorTypes.POWERBI}
            dataConnector={props.dataConnector}
            isSubmitting={isSubmitting}
            isValid={isValid}
            status={status as unknown}
          >
            <Stack gap={32}>
              <AlertBanner variant={AlertBanner.Variant.INFO}>
                <FormattedMessage
                  defaultMessage={`To get an access token, you must:{br}{br}1. Go to <link>Microsoft playground</link>{br}2. In the right part, click on "Continue with Account"{br}3. Authenticate with your Microsoft account if needed{br}4. In the top right corner of the "Request Preview", click on the "Copy" button{br}5. Paste it in the input below and submit{br}{br}Your token is valid for one hour, you should start syncing data right after submitting.`}
                  values={{
                    link: (chunks) => (
                      <TextLink to="https://learn.microsoft.com/en-us/rest/api/power-bi/groups/get-groups?tryIt=true&source=docs#code-try-0">
                        {chunks}
                      </TextLink>
                    ),
                  }}
                />
              </AlertBanner>

              <FormikInput
                autoFocus
                required
                label={<FormattedMessage defaultMessage="Access token" />}
                name="authRaw"
              />
            </Stack>
          </ConnectorAuthFormModalContent>
        )}
      </PowerBiRetardedConnectorAuthForm>
    </Modal>
  );
});
