import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatTotal } from '@amalia/core/types';
import {
  FieldValuePrettyFormat,
  getPrettyFormatProps,
  isFieldValuePrettyFormatCompatible,
} from '@amalia/data-capture/fields/components';
import { FormatsEnum, PropertyRef } from '@amalia/data-capture/fields/types';
import { Typography } from '@amalia/design-system/components';

import { type OverwriteCreationRequestDetails, OverwriteFormField } from '../overwrite.types';

import { BooleanOverwriteSelector } from './selectors/BooleanOverwriteSelector';
import { CurrencyOverwriteSelector } from './selectors/CurrencyOverwriteSelector';
import { DateOverwriteSelector } from './selectors/DateOverwriteSelector';
import { DateTimeOverwriteSelector } from './selectors/DateTimeOverwriteSelector';
import { NumberOverwriteSelector } from './selectors/NumberOverwriteSelector';
import { PercentOverwriteSelector } from './selectors/PercentOverwriteSelector';
import { TextOverwriteSelector } from './selectors/TextOverwriteSelector';
import { UserOverwriteSelector } from './selectors/UserOverwriteSelector';

interface OverwriteFormProps {
  readonly currentObjectDetails: OverwriteCreationRequestDetails;
}

export const OverwriteForm = memo(function OverwriteForm({ currentObjectDetails }: OverwriteFormProps) {
  const { format, currency, oldValue, ref: propertyRef } = currentObjectDetails;

  const shouldDisplayAdditionalPercentageSelector = [FormatsEnum.currency, FormatsEnum.number].includes(format);

  const formattedPreviousValue = currency
    ? formatTotal(oldValue, FormatsEnum.currency, currency, 1)
    : formatTotal(oldValue, format, currency, 1);

  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
          <FormattedMessage defaultMessage="Old value" />
        </Typography>

        {isFieldValuePrettyFormatCompatible(format, propertyRef) ? (
          <FieldValuePrettyFormat {...getPrettyFormatProps(format, oldValue, propertyRef)} />
        ) : (
          <Typography
            variant={Typography.Variant.BODY_BASE_REGULAR}
            css={css`
              // FIXME: hack to align the text with the input
              margin-top: 8px;
            `}
          >
            {formattedPreviousValue || <FormattedMessage defaultMessage="None" />}
          </Typography>
        )}
      </div>

      {shouldDisplayAdditionalPercentageSelector ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;

            max-width: 150px;
          `}
        >
          <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
            <FormattedMessage defaultMessage="New value (%)" />
          </Typography>

          <PercentOverwriteSelector
            currentObjectDetails={currentObjectDetails}
            formFieldContext={OverwriteFormField.PERCENTAGE}
          />
        </div>
      ) : null}

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
          <FormattedMessage defaultMessage="New value" />
        </Typography>
        <div>
          {(() => {
            // PropertyRef has higher priority than format to display the correct component
            if (propertyRef === PropertyRef.USER) {
              return <UserOverwriteSelector property="externalId" />;
            }

            switch (format) {
              case FormatsEnum.boolean:
                return <BooleanOverwriteSelector currentObjectDetails={currentObjectDetails} />;
              case FormatsEnum.currency:
                return <CurrencyOverwriteSelector currentObjectDetails={currentObjectDetails} />;
              case FormatsEnum.date:
                return <DateOverwriteSelector />;
              case FormatsEnum['date-time']:
                return <DateTimeOverwriteSelector />;
              case FormatsEnum.number:
                return <NumberOverwriteSelector currentObjectDetails={currentObjectDetails} />;
              case FormatsEnum.percent:
                return (
                  <PercentOverwriteSelector
                    currentObjectDetails={currentObjectDetails}
                    formFieldContext={OverwriteFormField.NEW_VALUE}
                  />
                );
              case FormatsEnum.text:
                return <TextOverwriteSelector />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
});
