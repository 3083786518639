import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';

import { USER_NULL_VALUE, UserSelector } from '@amalia/data-capture/fields/components';
import { Skeleton } from '@amalia/design-system/components';
import { useDeprecatedCompanyUsers } from '@amalia/tenants/users/state';
import { type KeysOfUserWithStringValues } from '@amalia/tenants/users/types';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

export const EMPTY_ARRAY = [];

export type UserOverwriteSelectorProps<TProperty extends KeysOfUserWithStringValues> = {
  readonly property: TProperty;
};

export const UserOverwriteSelectorBase = function UserOverwriteSelector<TProperty extends KeysOfUserWithStringValues>({
  property,
}: UserOverwriteSelectorProps<TProperty>) {
  const fieldName = OverwriteFormField.NEW_VALUE;
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();
  const value = values[fieldName];
  /* See footnote (1) */
  const validValue = value === USER_NULL_VALUE ? null : value;

  const { data: users, isLoading } = useDeprecatedCompanyUsers();

  const handleChange = useCallback(
    async (newValue?: string | null) => {
      /* See footnote (1) */
      await setFieldValue(fieldName, newValue ?? USER_NULL_VALUE);
    },
    [fieldName, setFieldValue],
  );

  return (
    <Skeleton
      data-testid="user-overwrite-selector"
      visible={isLoading}
    >
      <UserSelector
        property={property}
        users={users ?? EMPTY_ARRAY}
        value={validValue}
        onChange={handleChange}
      />
    </Skeleton>
  );
};

export const UserOverwriteSelector = memo(UserOverwriteSelectorBase) as typeof UserOverwriteSelectorBase;

/* Footnotes:
 * (1): We want to allow the admin to do a "null" overwrite on user reference fields.
 * However, the UserSelector still expects to get null as a value, not the string `'null'`.
 *
 * That's why we are using the constant USER_NULL_VALUE which
 * represents this possible string 'null' value.
 *
 * So we need to convert the string 'null' to null before passing it to the UserSelector.
 * And convert null to the string 'null' before passing it to the setFieldValue function.
 *
 * We can't use null because some users don't have an external id, and we may
 * inadvertently look them up instead of having no user assigned.
 */
