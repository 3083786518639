import { css } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { memo, Suspense, type ReactNode, useState } from 'react';

import { CircularLoader, Group } from '@amalia/design-system/components';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';

import { LEFT_BAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from './Layout.constants';
import { LayoutContext, type LayoutContextValue } from './Layout.context';
import { LeftBar, type LeftBarProps } from './left-bar/LeftBar';

export type LayoutProps = {
  readonly currentPage?: LeftBarProps['currentPage'];
  readonly children: ReactNode;
  readonly hideNavigationMenu?: boolean;
};

export const Layout = memo(function Layout({ currentPage, children, hideNavigationMenu }: LayoutProps) {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [isPortalActive, setIsPortalActive] = useState(false);
  const pxToRemove = isPortalActive ? LEFT_BAR_WIDTH + RIGHT_SIDEBAR_WIDTH : LEFT_BAR_WIDTH;

  const layoutContextValue: LayoutContextValue = useShallowObjectMemo({ portalElement, setIsPortalActive });

  if (hideNavigationMenu) {
    return (
      <Suspense
        fallback={
          <Group
            align="center"
            justify="center"
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            `}
          >
            <CircularLoader size={CircularLoader.Size.LARGE} />
          </Group>
        }
      >
        {children}
      </Suspense>
    );
  }
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
      `}
    >
      <LayoutContext.Provider value={layoutContextValue}>
        <CssBaseline />

        <LeftBar currentPage={currentPage} />

        <main
          css={css`
            flex-grow: 1;
            margin-left: ${LEFT_BAR_WIDTH}px;
            ${isPortalActive && `padding-right: ${RIGHT_SIDEBAR_WIDTH}px;`}
            width: calc(100% - ${pxToRemove}px);
            position: relative;
          `}
        >
          <Suspense
            fallback={
              <Group
                align="center"
                justify="center"
                css={css`
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                `}
              >
                <CircularLoader size={CircularLoader.Size.LARGE} />
              </Group>
            }
          >
            {children}
          </Suspense>
        </main>

        {/* This is the statement comment section, declared in CommentDrawerContainer */}
        <div ref={setPortalElement} />
      </LayoutContext.Provider>
    </div>
  );
});
