import { type ForwardedRef, forwardRef, memo, useCallback, type ReactElement, type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type FormulaBuilderLogicalOperatorBlockForm } from '@amalia/amalia-lang/formula/form/types';
import { type FormulaBuilderBaseBlock } from '@amalia/amalia-lang/formula/types';
import { Tag } from '@amalia/design-system/components';

import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';

import { formulaConditionTagTestIds } from './FormulaConditionTag.testIds';

export type FormulaConditionTagProps<TOperand extends FormulaBuilderLogicalOperatorBlockForm['operands'][number]> = {
  /** Condition. */
  readonly condition: TOperand;
  /** Label shown in tag. */
  readonly label?: ReactNode;
  /** Error. */
  readonly errors?: string[] | string;
  /** Change handler. */
  readonly onChange?: (condition: TOperand) => void;
  /** Click handler. */
  readonly onClick?: (conditionId: FormulaBuilderBaseBlock['id']) => void;
  /** Delete handler. */
  readonly onDelete?: (conditionId: FormulaBuilderBaseBlock['id']) => void;
};

const FormulaConditionTagForwardRef = forwardRef(function FormulaConditionTag<
  TOperand extends FormulaBuilderLogicalOperatorBlockForm['operands'][number],
>(
  {
    condition,
    label = undefined,
    errors = undefined,
    onClick = undefined,
    onChange: _onChange = undefined,
    onDelete = undefined,
  }: FormulaConditionTagProps<TOperand>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { setActiveConditionId, activeConditionId } = useFormulaBuilderContext();

  const { formatMessage } = useIntl();

  const isActive = condition.id === activeConditionId;

  const handleClick: Required<FormulaConditionTagProps<TOperand>>['onClick'] = useCallback(
    (id) => {
      setActiveConditionId(id);
      onClick?.(id);
    },
    [onClick, setActiveConditionId],
  );

  const errorsArray = (Array.isArray(errors) ? errors : [errors]).filter(Boolean);

  return (
    <Tag
      ref={ref}
      data-testid={formulaConditionTagTestIds.conditionTag(condition.id)}
      deleteTooltip={formatMessage({ defaultMessage: 'Remove condition' })}
      isActive={isActive}
      isDraft={condition.isDraft}
      value={condition.id}
      error={
        !condition.isDraft && errorsArray.length ? (
          <ul>
            {errorsArray.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        ) : undefined
      }
      onClick={handleClick}
      onDelete={onDelete}
    >
      {label || <FormattedMessage defaultMessage="Custom condition" />}
    </Tag>
  );
});

export const FormulaConditionTag = memo(FormulaConditionTagForwardRef) as <
  TOperand extends FormulaBuilderLogicalOperatorBlockForm['operands'][number],
>(
  props: FormulaConditionTagProps<TOperand> & {
    ref?: ForwardedRef<HTMLDivElement>;
  },
) => ReactElement | null;
