import { memo } from 'react';

import { Modal, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import { OAuthLoadingIndicator } from '../loading-indicator/OAuthLoadingIndicator';
import { OAuthConnectorAuthForm, type OAuthConnectorAuthFormProps } from '../OAuthConnectorAuthForm';

export type OAuthConnectorAuthFormModalProps = Omit<OAuthConnectorAuthFormProps, 'children'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const OAuthConnectorAuthFormModal = memo(function OAuthConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: OAuthConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <OAuthConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ isSubmitting, status }) => (
          <ConnectorAuthFormModalContent
            isValid
            connectorType={props.connectorType}
            dataConnector={props.dataConnector}
            isRunningOAuthFlow={isSubmitting}
            isSubmitting={isSubmitting}
            status={status as unknown}
          >
            <OAuthLoadingIndicator />
          </ConnectorAuthFormModalContent>
        )}
      </OAuthConnectorAuthForm>
    </Modal>
  );
});
