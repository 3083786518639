import { css } from '@emotion/react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type UserStatements } from '@amalia/core/types';
import { Stack, Tooltip } from '@amalia/design-system/components';
import { WorkflowProgressBar } from '@amalia/payout-calculation/statements/components';
import { type WorkflowMap, type WorkflowStatementState } from '@amalia/payout-collaboration/workflows/types';

import { StatementWorkflow } from './workflow/StatementWorkflow/StatementWorkflow';

interface UserStatementsProgressBarProps {
  readonly userStatements: UserStatements;
  readonly workflowMap: WorkflowMap;
  readonly workflowDetails: { steps: Partial<WorkflowStatementState>[]; currentStepNumber: number };
}

export const UserStatementsProgressBar = memo(function UserStatementsProgressBar({
  userStatements,
  workflowMap,
  workflowDetails,
}: UserStatementsProgressBarProps) {
  if (userStatements.lines.length > 1) {
    const nbCompleted = userStatements.lines.filter((s) => s.workflowComplete).length;

    return (
      <Stack
        align="center"
        css={css`
          width: 100%;
          min-width: 200px;
          max-width: 300px;
          margin: 0 auto;
        `}
      >
        <Tooltip
          content={
            <FormattedMessage
              defaultMessage="Completed workflows: {current, number}/{total, number}"
              values={{ current: nbCompleted, total: userStatements.lines.length }}
            />
          }
        >
          <WorkflowProgressBar
            currentStep={nbCompleted}
            totalSteps={userStatements.lines.length}
          />
        </Tooltip>
      </Stack>
    );
  }
  return (
    <StatementWorkflow
      statement={userStatements.lines[0]}
      workflowDetails={workflowDetails}
      workflowMap={workflowMap}
      additionalOptions={{
        forceShowProgressBar: true,
      }}
    />
  );
});
