import * as Yup from 'yup';

import {
  type AttributeValue,
  FormulaBuilderBlockType,
  type FormulaBuilderFunctionBlockString,
  FormulaBuilderFunctionCategory,
  FormulaBuilderStringOperatorNoArgs,
  FormulaBuilderStringOperatorOneArg,
  FormulaKeyword,
  type ValueOrAttribute,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';
import { TokenType, VariableType } from '@amalia/amalia-lang/tokens/types';
import { isEnum } from '@amalia/ext/typescript';

export const yupFormulaBuilderFunctionArgsAttributes = ({
  fieldType,
}: Pick<AttributeValue, 'fieldType'>): Record<string, Yup.AnySchema> => {
  switch (fieldType) {
    case TokenType.LINK:
      return {
        fieldType: Yup.string().oneOf([TokenType.LINK]),
        objectMachineName: Yup.string().required(),
        propertyMachineName: Yup.string().required(),
        relationshipMachineName: Yup.string().required(),
        type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]),
      };
    case TokenType.FIELD:
    case TokenType.PROPERTY:
    case TokenType.VIRTUAL_PROPERTY:
      return {
        fieldType: Yup.string().oneOf([TokenType.FIELD, TokenType.PROPERTY, TokenType.VIRTUAL_PROPERTY]),
        objectMachineName: Yup.string().required(),
        propertyMachineName: Yup.string().required(),
        type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]),
      };
    case TokenType.VARIABLE:
      return {
        fieldType: Yup.string().oneOf([TokenType.FILTER, TokenType.VARIABLE]),
        machineName: Yup.string().required(),
        type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]),
      };
    case TokenType.QUOTA:
      return {
        fieldType: Yup.string().oneOf([TokenType.QUOTA]),
        machineName: Yup.string().required(),
        quotaType: Yup.string().oneOf(Object.values(VariableType)).required(),
        type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]),
      };
    case TokenType.KEYWORD:
      return {
        fieldType: Yup.string().oneOf([TokenType.KEYWORD]),
        keyword: Yup.string().oneOf(Object.values(FormulaKeyword)).required(),
        type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]),
      };
    default:
      throw new Error(`Invalid type ${fieldType}`);
  }
};

export const yupFormulaBuilderFunctionStringArgs = (valueOrAttribute: ValueOrAttribute) => {
  switch (valueOrAttribute.type) {
    case ValueOrAttributeType.VALUE:
      return Yup.object()
        .shape({
          type: Yup.string().oneOf([ValueOrAttributeType.VALUE]).required(),
          value: Yup.string().required(),
        })
        .required();
    case ValueOrAttributeType.ATTRIBUTE:
      return Yup.object()
        .shape({
          fieldType: Yup.string().oneOf(Object.values(TokenType)).required(),
          type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]).required(),
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- validator
          ...(valueOrAttribute.fieldType && {
            ...yupFormulaBuilderFunctionArgsAttributes(valueOrAttribute),
          }),
        })
        .required();
    default:
      throw new Error(`Invalid valueOrAttribute type`);
  }
};

/** String function formulaBuilder validator */
export const yupFormulaBuilderFunctionStringOneArg = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttribute) => yupFormulaBuilderFunctionStringArgs(arg)))
      .length(2),
    caseSensitive: Yup.boolean().required(),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.STRING]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderStringOperatorOneArg)).nullable(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

export const yupFormulaBuilderFunctionStringNoArgs = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttribute) => yupFormulaBuilderFunctionStringArgs(arg)))
      .length(1),
    caseSensitive: Yup.boolean().required(),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.STRING]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderStringOperatorNoArgs)).nullable(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

export const yupFormulaBuilderFunctionString = (stringBlock: FormulaBuilderFunctionBlockString) => {
  switch (true) {
    // null is needed here because as long as we don't have an operator
    // we don't know which validator to use
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- it's a validator, it validates.
    case stringBlock.operator === null:
      return Yup.object().shape({
        isDraft: Yup.boolean().required().oneOf([false]),
      });
    case isEnum(stringBlock.operator, FormulaBuilderStringOperatorNoArgs):
      return yupFormulaBuilderFunctionStringNoArgs();
    case isEnum(stringBlock.operator, FormulaBuilderStringOperatorOneArg):
      return yupFormulaBuilderFunctionStringOneArg();
    default:
      throw new Error(`Invalid operator ${JSON.stringify(stringBlock)}`);
  }
};
