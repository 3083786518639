import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { type Selection } from 'd3';

import { DEFAULT_THEME } from '@amalia/design-system/themes/default';
import { type AmaliaThemeType, colors } from '@amalia/ext/mui/theme';

export type D3Svg = Selection<SVGSVGElement, unknown, any, unknown>;

export const useChartStyles = makeStyles((theme: AmaliaThemeType) => ({
  graphDoughtnutArc: {
    fill: theme.palette.link.main,
  },

  isRotated90: {},

  fillColor: {},
  strokeColor: {},

  isGradient: {},

  primary: {
    '&$fillColor': {
      fill: DEFAULT_THEME.ds.colors.primary['500'],

      '&$isGradient': {
        fill: 'url(#primaryGradient)',

        '&$isRotated90': {
          fill: 'url(#primaryGradient-90)',
        },
      },
    },
    '&$strokeColor': {
      stroke: DEFAULT_THEME.ds.colors.primary['500'],

      '&$isGradient': {
        stroke: 'url(#primaryGradient)',

        '&$isRotated90': {
          stroke: 'url(#primaryGradient-90)',
        },
      },
    },
  },
  secondary: {
    '&$fillColor': {
      fill: theme.palette.secondary.main,

      '&$isGradient': {
        fill: 'url(#secondaryGradient)',

        '&$isRotated90': {
          fill: 'url(#secondaryGradient-90)',
        },
      },
    },
    '&$strokeColor': {
      stroke: theme.palette.secondary.main,

      '&$isGradient': {
        stroke: 'url(#secondaryGradient)',

        '&$isRotated90': {
          stroke: 'url(#secondaryGradient-90)',
        },
      },
    },
  },
  tertiary: {
    '&$fillColor': {
      fill: theme.palette.tertiary.main,

      '&$isGradient': {
        fill: 'url(#tertiaryGradient)',

        '&$isRotated90': {
          fill: 'url(#tertiaryGradient-90)',
        },
      },
    },
    '&$strokeColor': {
      stroke: theme.palette.tertiary.main,

      '&$isGradient': {
        stroke: 'url(#tertiaryGradient)',

        '&$isRotated90': {
          stroke: 'url(#tertiaryGradient-90)',
        },
      },
    },
  },
  quarternary: {
    '&$fillColor': {
      fill: colors['quarternary-500'],

      '&$isGradient': {
        fill: 'url(#quarternaryGradient)',

        '&$isRotated90': {
          fill: 'url(#quaternaryGradient-90)',
        },
      },
    },
    '&%strokeColor': {
      stroke: colors['quarternary-700'],

      '&$isGradient': {
        stroke: 'url(#quarternaryGradient)',

        '&$isRotated90': {
          stroke: 'url(#quarternaryGradient-90)',
        },
      },
    },
  },

  axisLabelGrey: {},
  axisLabelNotVisible: {},

  axisNormal: {
    '&$axisLabelGrey': {
      '& g.tick': {
        '& line': {
          stroke: DEFAULT_THEME.ds.colors.gray[500],
        },

        '& text': {
          fill: DEFAULT_THEME.ds.colors.gray[500],
          strokeOpacity: 0,
        },
      },
    },

    '&$axisLabelNotVisible': {
      '& g.tick': {
        '& line': {
          strokeOpacity: 0,
        },

        '& text': {
          fill: 'transparent',
        },
      },
    },
  },

  axisGrey: {
    '& path': {
      stroke: DEFAULT_THEME.ds.colors.gray[500],
    },

    '&$axisLabelGrey': {
      '& g.tick': {
        '& line': {
          stroke: DEFAULT_THEME.ds.colors.gray[500],
        },

        '& text': {
          fill: DEFAULT_THEME.ds.colors.gray[500],
          strokeOpacity: 0,
        },
      },
    },

    '&$axisLabelNotVisible': {
      '& g.tick': {
        '& line': {
          strokeOpacity: 0,
        },

        '& text': {
          fill: 'transparent',
        },
      },
    },
  },
  axisNotVisible: {
    '& path': {
      strokeOpacity: 0,
    },

    '&$axisLabelGrey': {
      '& g.tick': {
        '& line': {
          stroke: DEFAULT_THEME.ds.colors.gray[500],
        },

        '& text': {
          fill: DEFAULT_THEME.ds.colors.gray[500],
          strokeOpacity: 0,
        },
      },
    },

    '&$axisLabelNotVisible': {
      '& g.tick': {
        '& line': {
          strokeOpacity: 0,
        },

        '& text': {
          fill: 'transparent',
        },
      },
    },
  },

  axisDataLabel: {},

  chartGrid: {
    '& line': {
      stroke: colors['grey-200'],
      shapeRendering: 'crispEdges',
    },
    '& path': {
      strokeWidth: 0,
    },
  },
}));

export interface CommonChartStyleProps {
  // Color or graph
  color?: 'primary' | 'quarternary' | 'secondary' | 'tertiary';
  // For colors: should it be a gradient of the color?
  isGradient?: boolean;
}

export type AxisColor = 'grey' | 'normal' | 'notVisible';

export interface CardinalChartStyleProps extends CommonChartStyleProps {
  // Data axis color
  mainAxisColor?: AxisColor;
  // Data axis ticks color
  mainAxisLabelColor?: AxisColor;
  // Other axis color
  dimensionAxisColor?: AxisColor;
  // Other axis ticks color
  dimensionAxisLabelColor?: AxisColor;
  // Rotate X axis labels. Warning: this does not increase viewBox, labels may overflow!
  rotateXAxisLabels?: boolean;
}

export const buildChartClasses = (
  classes: Record<string, string>,
  styles?: CommonChartStyleProps,
  isFill?: boolean,
  additionalClasses: string[] = [],
) =>
  clsx(
    classes[styles?.color || 'tertiary'],
    styles?.isGradient && classes.isGradient,
    isFill ? classes.fillColor : classes.strokeColor,
    additionalClasses,
  );

const generateGradient = (
  svgDefs: Selection<SVGDefsElement, unknown, any, unknown>,
  name: string,
  startColor: string,
  endColor: string,
  rotation: number = 0,
) => {
  const gradient = svgDefs
    .append('linearGradient')
    .attr('id', name)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('gradientTransform', `rotate(${rotation})`);

  gradient.append('stop').attr('offset', '0%').style('stop-color', startColor).style('stop-opacity', 1);

  gradient.append('stop').attr('offset', '100%').style('stop-color', endColor).style('stop-opacity', 1);
};

export const defineCommonSVGUtils = (svg: D3Svg) => {
  svg.selectAll('*').remove();

  // Append definitions tag to create our own
  const svgDefs = svg.append('defs');

  generateGradient(
    svgDefs,
    'primaryGradient',
    DEFAULT_THEME.ds.colors.primary['400'],
    DEFAULT_THEME.ds.colors.primary['500'],
  );
  generateGradient(svgDefs, 'secondaryGradient', colors['secondary-400'], colors['secondary-500']);
  generateGradient(svgDefs, 'tertiaryGradient', colors['tertiary-400'], colors['tertiary-500']);
  generateGradient(svgDefs, 'quarternaryGradient', colors['quarternary-500'], colors['quarternary-700']);

  generateGradient(
    svgDefs,
    'primaryGradient-90',
    DEFAULT_THEME.ds.colors.primary['400'],
    DEFAULT_THEME.ds.colors.primary['500'],
    90,
  );
  generateGradient(svgDefs, 'secondaryGradient-90', colors['secondary-400'], colors['secondary-500'], 90);
  generateGradient(svgDefs, 'tertiaryGradient-90', colors['tertiary-400'], colors['tertiary-500'], 90);
  generateGradient(svgDefs, 'quaternaryGradient-90', colors['quarternary-500'], colors['quarternary-700'], 90);

  // Set overflow visible
  svg.style('overflow', 'visible');
  svg.style('width', '100%');
};

export const getAxisStyleFromAxisColor = (
  classes: Record<string, string>,
  axisColor: 'grey' | 'normal' | 'notVisible' = 'normal',
) =>
  ({
    normal: classes.axisNormal,
    grey: classes.axisGrey,
    notVisible: classes.axisNotVisible,
  })[axisColor];

export const getAxisLabelStyleFromAxisColor = (
  classes: Record<string, string>,
  axisColor: 'grey' | 'normal' | 'notVisible' = 'normal',
) =>
  ({
    normal: '',
    grey: classes.axisLabelGrey,
    notVisible: classes.axisLabelNotVisible,
  })[axisColor];

export const getAxisClasses = (
  classes: Record<string, string>,
  axisType: 'dimensional' | 'main',
  styles: CardinalChartStyleProps,
) => {
  const colorClasses =
    axisType === 'main'
      ? clsx(
          getAxisStyleFromAxisColor(classes, styles.mainAxisColor),
          getAxisLabelStyleFromAxisColor(classes, styles.mainAxisLabelColor),
        )
      : clsx(
          getAxisStyleFromAxisColor(classes, styles.dimensionAxisColor),
          getAxisLabelStyleFromAxisColor(classes, styles.dimensionAxisLabelColor),
        );
  return clsx(classes.axis, colorClasses);
};
