import { memo, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { useCreateConnector, useUpdateConnectorAuth } from '@amalia/data-capture/connectors/state';
import {
  type DataConnector,
  type ConnectorResponse,
  type OAuthDataConnectorAuth,
  DataConnectorCategories,
  AuthType,
  DataConnectorTypes,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';

type PersonioConnectorAuthFormValues = {
  clientId: string;
  clientSecret: string;
};

type PersonioConnectorAuthFormikProps = FormikFormProps<PersonioConnectorAuthFormValues, ConnectorResponse>;

export type PersonioConnectorAuthFormProps = Omit<
  PersonioConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
};

export const PersonioConnectorAuthForm = memo(function PersonioConnectorAuthForm({
  dataConnector,
  children,
  ...props
}: PersonioConnectorAuthFormProps) {
  const dataConnectorAuth = dataConnector?.auth as OAuthDataConnectorAuth | null | undefined;

  const { mutateAsync: createConnector } = useCreateConnector();
  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth();

  const initialValues = useMemo(
    (): PersonioConnectorAuthFormValues => ({
      clientId: dataConnectorAuth?.clientId || '',
      clientSecret: dataConnectorAuth?.clientSecret || '',
    }),
    [dataConnectorAuth],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        clientId: Yup.string().required(),
        clientSecret: Yup.string().required(),
      }),
    [],
  );

  const handleSubmit: PersonioConnectorAuthFormikProps['onSubmit'] = useCallback(
    async (values) => {
      const authRequest = {
        clientId: values.clientId,
        clientSecret: values.clientSecret,
      };

      return !dataConnector
        ? createConnector({
            type: DataConnectorTypes.PERSONIO,
            category: DataConnectorCategories.DATA,
            authType: AuthType.OAuth2_authorizationCode,
            auth: authRequest,
          })
        : updateConnectorAuth({
            id: dataConnector.id,
            type: DataConnectorTypes.PERSONIO,
            auth: authRequest,
          });
    },
    [createConnector, dataConnector, updateConnectorAuth],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
