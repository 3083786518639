import { IconChevronRight } from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { CONNECTORS } from '@amalia/core/types';
import { VENDOR_LOGOS } from '@amalia/data-capture/connectors/assets';
import { DataConnectorTypes, type ConnectorResponse } from '@amalia/data-capture/connectors/types';
import { Badge, Button, ButtonLink, Card, Typography } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { dataConnectorCategoriesMessages } from '../../intl/messages';
import { ConnectorAuthFormModal } from '../forms/auth/connector-auth-form-modal/ConnectorAuthFormModal';

import * as styles from './ConnectorCard.styles';
import { connectorCardTestIds } from './ConnectorCard.testIds';

export type ConnectorCardProps = {
  /** Connector definition (name, logo, etc.). */
  readonly connectorType: DataConnectorTypes;
  /** Connector connection. */
  readonly connector?: ConnectorResponse;
};

export const ConnectorCard = memo(function ConnectorCard({ connectorType, connector }: ConnectorCardProps) {
  const Logo = VENDOR_LOGOS[connectorType].CompactLogo;
  const connectorDefinition = CONNECTORS[connectorType];

  const { isAuthModalOpen, setAuthModalOpenTrue, setAuthModalOpenFalse } = useBoolState(false, 'authModalOpen');

  return (
    <Fragment>
      <Card>
        <div css={styles.connectorCardBody}>
          <Logo css={styles.logo} />

          <div css={styles.textContainer}>
            <Typography
              as="div"
              css={styles.connectorName}
              variant={Typography.Variant.BODY_LARGE_BOLD}
            >
              {connectorDefinition.name}

              {/* The salesforce second account is a dirty hack today so I'm not spending too much time on it. */}
              {connectorDefinition.connectorType === DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT && (
                <Badge
                  size={Badge.Size.SMALL}
                  variant={Badge.Hue.BLUE}
                >
                  <FormattedMessage defaultMessage="Second account" />
                </Badge>
              )}
              {connectorDefinition.connectorType === DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT && (
                <Badge
                  size={Badge.Size.SMALL}
                  variant={Badge.Hue.BLUE}
                >
                  <FormattedMessage defaultMessage="Third account" />
                </Badge>
              )}
            </Typography>

            <Typography
              as="div"
              css={styles.connectorCategory}
              variant={Typography.Variant.BODY_SMALL_REGULAR}
            >
              <FormattedMessage {...dataConnectorCategoriesMessages[connectorDefinition.category]} />
            </Typography>
          </div>
        </div>

        <Card.Footer>
          {connector?.isLoggedIn ? (
            <Badge
              size={Badge.Size.SMALL}
              variant={Badge.Status.SUCCESS}
            >
              <FormattedMessage defaultMessage="Connected" />
            </Badge>
          ) : (
            <Badge
              size={Badge.Size.SMALL}
              variant={Badge.Status.MISSING}
            >
              <FormattedMessage defaultMessage="Not connected" />
            </Badge>
          )}

          <Card.Actions>
            {connector?.isLoggedIn ? (
              <ButtonLink
                data-testid={connectorCardTestIds.addDataObjectButton(connectorDefinition.connectorType)}
                icon={<IconChevronRight />}
                iconPosition={Button.IconPosition.RIGHT}
                to={generatePath(routes.DATA_CONNECTOR, { connectorType })}
                variant={Button.Variant.PRIMARY_TEXT}
              >
                <FormattedMessage defaultMessage="Configure data objects" />
              </ButtonLink>
            ) : (
              <Button
                data-testid={connectorCardTestIds.configureButton(connectorDefinition.connectorType)}
                icon={<IconChevronRight />}
                iconPosition={Button.IconPosition.RIGHT}
                variant={Button.Variant.PRIMARY_TEXT}
                onClick={setAuthModalOpenTrue}
              >
                <FormattedMessage defaultMessage="Connect" />
              </Button>
            )}
          </Card.Actions>
        </Card.Footer>
      </Card>

      <ConnectorAuthFormModal
        connectorType={connectorType}
        dataConnector={connector && !connector.isLoggedIn ? connector : undefined} // Go into edition mode if the connector is only logged out.
        isOpen={isAuthModalOpen}
        onClose={setAuthModalOpenFalse}
      />
    </Fragment>
  );
});
