import { css } from '@emotion/react';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { tokenTypeLabels } from '@amalia/amalia-lang/tokens/components';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { Button, CountBadge, Dropdown } from '@amalia/design-system/components';
import { useLoadMore } from '@amalia/lib-ui';

import { type FormulaEditorToken } from '../../../types/formulaEditorToken';
import { SuggestionDropdownItem } from '../suggestion-dropdown-item/SuggestionDropdownItem';

type SuggestionGroupProps = {
  readonly command: (item: FormulaEditorToken) => void;
  readonly isOpen: boolean;
  readonly options: FormulaEditorToken[];
  readonly selectedFormulaToken?: FormulaEditorToken;
  readonly toggleGroup: (tokenType: TokenType) => void;
  readonly tokenType: TokenType;
};

export const SuggestionGroup = memo(function SuggestionGroup({
  tokenType,
  command,
  isOpen,
  options,
  selectedFormulaToken,
  toggleGroup,
}: SuggestionGroupProps) {
  const { formatMessage } = useIntl();
  const { elementsCapped, onClickLoadMore, count, total } = useLoadMore<FormulaEditorToken>(options);

  const handleChangeIsOpen = useCallback(() => {
    toggleGroup(tokenType);
  }, [toggleGroup, tokenType]);
  return (
    <Dropdown.Group
      key={tokenType}
      countBadge={<CountBadge>{total}</CountBadge>}
      isOpen={isOpen}
      label={formatMessage(tokenTypeLabels[tokenType], { count: undefined })}
      onChangeIsOpen={handleChangeIsOpen}
    >
      {(elementsCapped as FormulaEditorToken[]).map((item) => (
        <SuggestionDropdownItem
          key={`${item.formula}-${item.type}`}
          item={item}
          selectedItem={selectedFormulaToken}
          onClick={command}
        />
      ))}

      {!!onClickLoadMore && (
        <Button
          variant={Button.Variant.PRIMARY_TEXT}
          css={css`
            margin-top: 1px;
            width: 100%;
            justify-content: center;
          `}
          onClick={onClickLoadMore}
        >
          <FormattedMessage
            defaultMessage="Show more ({current, number}/{total, number})"
            values={{
              current: count,
              total,
            }}
          />
        </Button>
      )}
    </Dropdown.Group>
  );
});
