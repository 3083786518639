import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { isUnprocessableEntityError } from '@amalia/core/http/client';
import { useCreateConnector, useUpdateConnectorAuth } from '@amalia/data-capture/connectors/state';
import {
  type DataConnectorTypes,
  type ConnectorResponse,
  type DatabaseConnectorAuth,
  type DataConnector,
  DataConnectorCategories,
  AuthType,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, SubmissionError, type FormikFormProps } from '@amalia/ext/formik';

type DatabaseConnectorAuthFormValues = {
  database: string;
  host: string;
  password: string;
  port: string;
  schema: string;
  user: string;
  ssh: {
    host: string;
    port: string;
    username: string;
    privateKey?: string;
  } | null;
};

type DatabaseConnectorAuthFormikProps = FormikFormProps<DatabaseConnectorAuthFormValues, ConnectorResponse>;

export type DatabaseConnectorAuthFormProps = Omit<
  DatabaseConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
  readonly connectorType: DataConnectorTypes.MYSQL | DataConnectorTypes.POSTGRESQL | DataConnectorTypes.REDSHIFT;
};

export const DatabaseConnectorAuthForm = memo(function DatabaseConnectorAuthForm({
  dataConnector,
  connectorType,
  children,
  ...props
}: DatabaseConnectorAuthFormProps) {
  const dataConnectorAuth = dataConnector?.auth as DatabaseConnectorAuth | null | undefined;

  const { mutateAsync: createConnector } = useCreateConnector({
    shouldIgnoreError: isUnprocessableEntityError,
  });

  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth({
    shouldIgnoreError: isUnprocessableEntityError,
  });

  const initialValues = useMemo(
    (): DatabaseConnectorAuthFormValues => ({
      database: dataConnectorAuth?.database || '',
      host: dataConnectorAuth?.host || '',
      password: dataConnectorAuth?.password || '',
      port: `${dataConnectorAuth?.port || ''}`,
      schema: dataConnectorAuth?.schema || '',
      user: dataConnectorAuth?.user || '',
      ssh: dataConnectorAuth?.ssh
        ? {
            host: dataConnectorAuth.ssh.host || '',
            port: `${dataConnectorAuth.ssh.port || ''}`,
            username: dataConnectorAuth.ssh.username || '',
            privateKey: dataConnectorAuth.ssh.privateKey || '',
          }
        : null,
    }),
    [dataConnectorAuth],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        database: Yup.string().required(),
        host: Yup.string().required(),
        password: Yup.string().required(),
        port: Yup.number().required(),
        schema: Yup.string().required(),
        ssh: Yup.object()
          .shape({
            host: Yup.string().required(),
            port: Yup.number().required(),
            sshPrivateKey: Yup.string(),
            user: Yup.string().required(),
          })
          .nullable(),
        user: Yup.string().required(),
      }),
    [],
  );

  const handleSubmit: DatabaseConnectorAuthFormikProps['onSubmit'] = useCallback(
    async (values) => {
      try {
        const authRequest = {
          ...values,
          port: parseInt(values.port, 10),
          ssh: values.ssh
            ? {
                ...values.ssh,
                port: parseInt(values.ssh.port, 10),
              }
            : undefined,
        };

        return await (!dataConnector
          ? createConnector({
              type: connectorType,
              category: DataConnectorCategories.DATA,
              authType: AuthType.Database_Connection_Auth,
              auth: authRequest,
            })
          : updateConnectorAuth({
              id: dataConnector.id,
              type: connectorType,
              auth: authRequest,
            }));
      } catch (err) {
        if (isUnprocessableEntityError(err)) {
          throw new SubmissionError(
            <FormattedMessage defaultMessage="Authentication failed. Please check your credentials." />,
          );
        }

        throw err;
      }
    },
    [createConnector, connectorType, dataConnector, updateConnectorAuth],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
