import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Input } from '@amalia/design-system/components';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';
import { getTheValueOfPercentage } from '../../overwrite.utils';

import { messages } from './selectors.messages';
import { type CommonOverwriteSelectorProps } from './selectors.types';

export const OVERWRITE_INPUT_TEST_ID = `${OverwriteFormField.NEW_VALUE}-input`;

export type NumberOverwriteSelectorProps = CommonOverwriteSelectorProps;

export const NumberOverwriteSelector = memo(function NumberOverwriteSelector({
  currentObjectDetails,
}: NumberOverwriteSelectorProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    async (newValue: string) => {
      await setFieldValue(OverwriteFormField.NEW_VALUE, newValue);
      setFieldValue(
        OverwriteFormField.PERCENTAGE,
        getTheValueOfPercentage(currentObjectDetails.oldValue as CurrencyValue | number, Number(newValue)),
      ).catch(() => ({}));
    },
    [currentObjectDetails, setFieldValue],
  );

  return (
    <Input
      autoFocus
      data-testid={OVERWRITE_INPUT_TEST_ID}
      id={OverwriteFormField.NEW_VALUE}
      name={OverwriteFormField.NEW_VALUE}
      placeholder={formatMessage(messages[FormatsEnum.number])}
      size={Input.Size.SMALL}
      type="number"
      value={values.newValue}
      onChange={handleChange}
    />
  );
});
