import { type FormikProps } from 'formik';
import { memo, useCallback, useRef } from 'react';
import { type EmptyObject } from 'type-fest';
import * as Yup from 'yup';

import {
  useCreateConnector,
  useAuthenticateWithOAuth,
  useUpdateConnectorAuth,
} from '@amalia/data-capture/connectors/state';
import {
  AuthType,
  DataConnectorCategories,
  DataConnectorTypes,
  type ConnectorResponse,
  type DataConnector,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';
import { useMountEffect } from '@amalia/ext/react/hooks';

type ZohoConnectorAuthFormValues = EmptyObject;
const initialValues: ZohoConnectorAuthFormValues = {};

type ZohoConnectorAuthFormikProps = FormikFormProps<ZohoConnectorAuthFormValues, ConnectorResponse>;

export type ZohoConnectorAuthFormProps = Omit<
  ZohoConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
};

export const ZohoConnectorAuthForm = memo(function ZohoConnectorAuthForm({
  dataConnector,
  children,
  ...props
}: ZohoConnectorAuthFormProps) {
  const ref = useRef<FormikProps<ZohoConnectorAuthFormValues>>(null);

  const { mutateAsync: authenticateWithOAuth } = useAuthenticateWithOAuth(
    Yup.object().shape({
      code: Yup.string().required('code is required'),
      sanitized_location: Yup.string().required('sanitized_location is required'),
    }),
  );

  const { mutateAsync: createConnector } = useCreateConnector();
  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth();

  const runAuthentication = useCallback(async () => {
    const rawQueryParams = await authenticateWithOAuth({
      connectorType: DataConnectorTypes.ZOHO,
    });

    /**
     * Zoho returns `location` in the query string, but it's intercepted by Google Cloud Storage when serving the frontend on staging and production and crashes.
     * The `/api/connectors/zoho/sanitize` endpoint renames it to `sanitized_location` before redirecting to the frontend.
     * We rename it to `location` here.
     */
    const oauthQueryParams = {
      code: rawQueryParams.code,
      location: rawQueryParams.sanitized_location,
    };

    return !dataConnector
      ? createConnector({
          type: DataConnectorTypes.ZOHO,
          category: DataConnectorCategories.DATA,
          authType: AuthType.OAuth2_authorizationCode,
          auth: oauthQueryParams,
        })
      : updateConnectorAuth({
          id: dataConnector.id,
          type: DataConnectorTypes.ZOHO,
          auth: oauthQueryParams,
        });
  }, [createConnector, dataConnector, authenticateWithOAuth, updateConnectorAuth]);

  useMountEffect(() => {
    ref.current?.submitForm().catch(() => {});
  });

  return (
    <FormikForm<ZohoConnectorAuthFormValues, ConnectorResponse>
      {...props}
      ref={ref}
      enableReinitialize={false}
      initialValues={initialValues}
      onSubmit={runAuthentication}
    >
      {children}
    </FormikForm>
  );
});
