import { memo } from 'react';
import { useIntl } from 'react-intl';

import { type FilterSelectOption, Filters } from '@amalia/design-system/components';
import { CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

import { useUserOptions } from '../../select';
import { toCurrencyOption } from '../cells/currency/useCurrencyOptions';
import { useExternalIdSourceOptions } from '../cells/integrations/popover/external-id/select/useExternalIdSourceOptions';
import { useHrisIdSourceOptions } from '../cells/integrations/popover/hris-id/select/useHrisIdSourceOptions';
import { useRoleOptions } from '../cells/roles/useRoleOptions';
import { useStatusOptions } from '../cells/status/useStatusOptions';
import { type UsersTableData } from '../types';

import { type UserFilters } from './types';
import { useUsersTableFilterOptions } from './useUsersTableFilterOptions';

const possibleCurrencyOptions = Object.values(CurrencySymbolsEnum).map((currencySymbol) =>
  toCurrencyOption(currencySymbol),
);

export type UsersTableFiltersProps = {
  /** Is the datagrid loading? */
  readonly isLoading: boolean;
  /** The current filters values. */
  readonly filters: UserFilters;
  /** Called when the filters change with the selected filters value. Example: { status: [] } */
  readonly onChange: (filters: Partial<UserFilters>) => void;
  /** The unfiltered users. Used to compute the available facets with the other filters applied. */
  readonly users: UsersTableData[];
};

export const UsersTableFilters = memo(function UsersTableFilters({
  isLoading,
  filters,
  onChange,
  users,
}: UsersTableFiltersProps) {
  const { formatMessage } = useIntl();

  const possibleStatusOptions = useStatusOptions();

  const [statusOptions, onChangeStatus] = useUsersTableFilterOptions({
    users,
    filters,
    property: 'status',
    options: possibleStatusOptions,
    onChange,
  });

  const possibleRoleOptions = useRoleOptions();

  const [roleOptions, onChangeRole] = useUsersTableFilterOptions({
    users,
    filters,
    property: 'role',
    options: possibleRoleOptions,
    onChange,
  });

  const [currencyOptions, onChangeCurrency] = useUsersTableFilterOptions({
    users,
    filters,
    property: 'currency',
    options: possibleCurrencyOptions,
    onChange,
  });

  const possibleExternalIdOptions = useExternalIdSourceOptions({
    hideIconWhenSelected: true,
  });

  const [externalIdOptions, onChangeExternalId] = useUsersTableFilterOptions({
    users,
    filters,
    property: 'externalIdSource',
    options: possibleExternalIdOptions,
    onChange,
  });

  const possibleHrisIdOptions = useHrisIdSourceOptions({
    hideIconWhenSelected: true,
  });

  const [hrisIdOptions, onChangeHrisId] = useUsersTableFilterOptions({
    users,
    filters,
    property: 'hrisIdSource',
    options: possibleHrisIdOptions,
    onChange,
  });

  const possibleUserOptions = useUserOptions();

  const [userIdOptions, onChangeUserId] = useUsersTableFilterOptions({
    users,
    filters,
    property: 'id',
    options: possibleUserOptions,
    onChange,
  });

  const commonProps = {
    disabled: isLoading,
    isMultiple: true,
  } as const;

  return (
    <Filters>
      <Filters.FilterSelect<FilterSelectOption<UserFilters['status'][number]>, true>
        {...commonProps}
        isStatic
        allSelectedLabel={formatMessage({ defaultMessage: 'All statuses' })}
        id="status"
        options={statusOptions}
        title={formatMessage({ defaultMessage: 'Filter statuses' })}
        value={filters.status}
        label={(count) =>
          formatMessage({ defaultMessage: '{count, plural, one {Status} other {Statuses}}' }, { count })
        }
        onChange={onChangeStatus}
      />
      <Filters.FilterSelect<FilterSelectOption<UserFilters['role'][number]>, true>
        {...commonProps}
        isStatic
        allSelectedLabel={formatMessage({ defaultMessage: 'All roles' })}
        id="role"
        label={(count) => formatMessage({ defaultMessage: '{count, plural, one {Role} other {Roles}}' }, { count })}
        options={roleOptions}
        title={formatMessage({ defaultMessage: 'Filter roles' })}
        value={filters.role}
        onChange={onChangeRole}
      />
      <Filters.FilterSelect<FilterSelectOption<UserFilters['currency'][number]>, true>
        {...commonProps}
        allSelectedLabel={formatMessage({ defaultMessage: 'All currencies' })}
        id="currency"
        options={currencyOptions}
        title={formatMessage({ defaultMessage: 'Filter currency' })}
        value={filters.currency}
        label={(count) =>
          formatMessage({ defaultMessage: '{count, plural, one {Currency} other {Currencies}}' }, { count })
        }
        onChange={onChangeCurrency}
      />
      <Filters.FilterSelect<FilterSelectOption<UserFilters['externalIdSource'][number]>, true>
        {...commonProps}
        allSelectedLabel={formatMessage({ defaultMessage: 'All external integrations' })}
        id="externalIdSource"
        options={externalIdOptions}
        title={formatMessage({ defaultMessage: 'Filter external integrations' })}
        value={filters.externalIdSource}
        label={(count) =>
          formatMessage(
            { defaultMessage: '{count, plural, one {External integration} other {External integrations}}' },
            { count },
          )
        }
        onChange={onChangeExternalId}
      />
      <Filters.FilterSelect<FilterSelectOption<UserFilters['hrisIdSource'][number]>, true>
        {...commonProps}
        allSelectedLabel={formatMessage({ defaultMessage: 'All HRIS integrations' })}
        id="hrisIdSource"
        options={hrisIdOptions}
        title={formatMessage({ defaultMessage: 'Filter HRIS integrations' })}
        value={filters.hrisIdSource}
        label={(count) =>
          formatMessage(
            { defaultMessage: '{count, plural, one {HRIS integration} other {HRIS integrations}}' },
            { count },
          )
        }
        onChange={onChangeHrisId}
      />
      <Filters.FilterSelect<FilterSelectOption<UserFilters['id'][number]>, true>
        {...commonProps}
        isStatic
        allSelectedLabel={formatMessage({ defaultMessage: 'All users' })}
        id="id"
        label={(count) => formatMessage({ defaultMessage: '{count, plural, one {User} other {Users}}' }, { count })}
        options={userIdOptions}
        title={formatMessage({ defaultMessage: 'Filter users' })}
        value={filters.id}
        onChange={onChangeUserId}
      />
    </Filters>
  );
});
