import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { noop } from 'lodash';
import { type ComponentPropsWithoutRef, memo, type ReactNode } from 'react';

import { type MergeAll } from '@amalia/ext/typescript';

import { Stack } from '../../layout/stack/Stack';
import { Typography } from '../typography/Typography';
import { UnstyledButton } from '../unstyled-button/UnstyledButton';

export type CardButtonProps = MergeAll<
  [
    ComponentPropsWithoutRef<'button'>,
    {
      /** Title of the card button. */
      title: ReactNode;
      /** Description. */
      description: ReactNode;
      /** Svg to render at the bottom of the card. */
      svgUrl: ReactNode;
      /** Text at the top of the card. */
      header?: ReactNode;
      /** Disabled? */
      disabled?: boolean;
      /** Offset the svg to the bottom to give a "cut" effect. */
      svgBottomOffset?: number;
    },
  ]
>;

const StyledCardButton = styled(UnstyledButton, {
  shouldForwardProp: (propName) => propName !== 'svgBottomOffset' && propName !== 'svgUrl',
})<Pick<CardButtonProps, 'svgBottomOffset' | 'svgUrl'>>`
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.ds.colors.gray[0]};
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.squared};
  box-shadow: ${({ theme }) => theme.ds.shadows.soft};
  border: 1px solid ${({ theme }) => theme.ds.colors.primary[50]};

  background-repeat: no-repeat;
  background-position: bottom -${({ svgBottomOffset }) => svgBottomOffset || 0}px right 5px;
  background-image: ${(props) => `url('${props.svgUrl}')`};
  width: 316px;
  height: 112px;
  box-sizing: border-box;
  transition: ${({ theme }) => theme.ds.transitions.default()};

  &:hover {
    background-color: ${({ theme }) => theme.ds.colors.graySecondary[50]};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.ds.colors.primary[200]};
    background-color: ${({ theme }) => theme.ds.colors.gray[0]};
    box-shadow: ${({ theme }) => theme.ds.shadows.focusLarge(theme.ds.colors.primary[50])};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.ds.colors.gray[50]};
    & > * > * {
      color: ${({ theme }) => theme.ds.colors.gray[200]};
    }
  }
`;

export const CardButton = memo(function CardButton({
  title,
  description,
  svgUrl,
  header,
  disabled,
  onClick,
  ...props
}: CardButtonProps) {
  const childProps = {
    ...props,
    svgUrl,
    disabled,
    // Shouldn't be clickable if deactivated.
    onClick: disabled ? noop : onClick,
  };

  return (
    <StyledCardButton {...childProps}>
      <Stack
        gap="4px"
        css={css`
          height: 100%;
        `}
      >
        {!!header && (
          <Typography
            variant={Typography.Variant.BODY_XXSMALL_REGULAR}
            css={(theme) => css`
              margin-bottom: 2px;
              color: ${theme.ds.colors.gray[500]};
              text-transform: uppercase;
            `}
          >
            {header}
          </Typography>
        )}
        <Typography variant={Typography.Variant.BODY_LARGE_BOLD}>{title}</Typography>
        <Typography
          variant={Typography.Variant.BODY_SMALL_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[700]};
            max-width: 188px;
          `}
        >
          {description}
        </Typography>
      </Stack>
    </StyledCardButton>
  );
});
