import { EditorContent, type EditorContentProps } from '@tiptap/react';
import clsx from 'clsx';
import { memo } from 'react';

import { FormField, useFormFieldProps, type UseFormFieldPropsOptions } from '@amalia/design-system/components';
import { type MergeAll } from '@amalia/ext/typescript';

import * as styles from './FormulaEditorContent.styles';

export type FormulaEditorContentProps = MergeAll<
  [Omit<EditorContentProps, 'ref'>, UseFormFieldPropsOptions & { dv3?: boolean }]
>;

export const FormulaEditorContent = memo(function FormulaEditorContent(props: FormulaEditorContentProps) {
  const {
    formFieldProps,
    // FIXME: remove the DV3 when decommissioning DV2
    otherProps: { spellCheck = false, dv3, ...otherProps },
  } = useFormFieldProps(props);

  return (
    <FormField
      {...formFieldProps}
      size={FormField.Size.MEDIUM}
    >
      <EditorContent
        {...otherProps}
        css={styles.formulaEditor}
        spellCheck={spellCheck}
        className={clsx(otherProps.className, {
          [styles.HAS_ERROR_CLASSNAME]: formFieldProps.error,
          [styles.DV3_CLASSNAME]: dv3,
        })}
      />
    </FormField>
  );
});
