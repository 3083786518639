import { memo } from 'react';

import { DataConnectorTypes, type DataConnector } from '@amalia/data-capture/connectors/types';
import { useCurrentCompany } from '@amalia/tenants/companies/state';

import { DatabaseConnectorAuthFormModal } from '../connector-auth-form/database/modal/DatabaseConnectorAuthFormModal';
import { MicrosoftDynamicsConnectorAuthFormModal } from '../connector-auth-form/microsoft-dynamics/modal/MicrosoftDynamicsConnectorAuthFormModal';
import { NetsuiteConnectorAuthFormModal } from '../connector-auth-form/netsuite/modal/NetsuiteConnectorAuthFormModal';
import { OAuthConnectorAuthFormModal } from '../connector-auth-form/oauth/modal/OAuthConnectorAuthFormModal';
import { PersonioConnectorAuthFormModal } from '../connector-auth-form/personio/modal/PersonioConnectorAuthFormModal';
import { PowerBiRetardedConnectorAuthFormModal } from '../connector-auth-form/powerbi-retarded/modal/PowerBiRetardedConnectorAuthFormModal';
import { SnowflakeConnectorAuthFormModal } from '../connector-auth-form/snowflake/modal/SnowflakeConnectorAuthFormModal';
import { UnsupportedConnectorAuthFormModal } from '../connector-auth-form/unsupported/modal/UnsupportedConnectorAuthFormModal';
import { ZohoConnectorAuthFormModal } from '../connector-auth-form/zoho/modal/ZohoConnectorAuthFormModal';

export type ConnectorAuthFormModalProps = {
  readonly dataConnector?: DataConnector;
  readonly connectorType: DataConnectorTypes;
  readonly isOpen: boolean;
  readonly onClose?: () => void;
};

export const ConnectorAuthFormModal = memo(function ConnectorAuthFormModal({
  dataConnector,
  connectorType,
  isOpen,
  onClose,
}: ConnectorAuthFormModalProps) {
  const { data: company } = useCurrentCompany();

  if (company.name === 'Nespresso' && connectorType === DataConnectorTypes.POWERBI) {
    return (
      <PowerBiRetardedConnectorAuthFormModal
        dataConnector={dataConnector}
        isOpen={isOpen}
        onClose={onClose}
      />
    );
  }

  switch (connectorType) {
    case DataConnectorTypes.BIGQUERY:
    case DataConnectorTypes.GOOGLESHEET:
    case DataConnectorTypes.HUBSPOT:
    case DataConnectorTypes.POWERBI:
    case DataConnectorTypes.SALESFORCE:
      return (
        <OAuthConnectorAuthFormModal
          connectorType={connectorType}
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    case DataConnectorTypes.MYSQL:
    case DataConnectorTypes.POSTGRESQL:
    case DataConnectorTypes.REDSHIFT:
      return (
        <DatabaseConnectorAuthFormModal
          connectorType={connectorType}
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    case DataConnectorTypes.MSDYNAMICS:
      return (
        <MicrosoftDynamicsConnectorAuthFormModal
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    case DataConnectorTypes.NETSUITE:
      return (
        <NetsuiteConnectorAuthFormModal
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    case DataConnectorTypes.PERSONIO:
      return (
        <PersonioConnectorAuthFormModal
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    case DataConnectorTypes.SNOWFLAKE:
      return (
        <SnowflakeConnectorAuthFormModal
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    case DataConnectorTypes.ZOHO:
      return (
        <ZohoConnectorAuthFormModal
          dataConnector={dataConnector}
          isOpen={isOpen}
          onClose={onClose}
        />
      );

    default:
      // Other connectors are not implemented, we'll have a different modal with just an info message.
      return (
        <UnsupportedConnectorAuthFormModal
          connectorType={connectorType}
          isOpen={isOpen}
          onClose={onClose}
        />
      );
  }
});
