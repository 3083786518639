import clsx from 'clsx';
import { type ReactNode, memo, useMemo, forwardRef, type ForwardedRef } from 'react';

import { VENDOR_LOGOS } from '@amalia/data-capture/connectors/assets';
import {
  CustomObjectDefToDataConnectorType,
  CustomObjectDefinitionType,
} from '@amalia/data-capture/record-models/types';
import { Group, TextOverflow, Typography, UnstyledButton } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { useForwardedRef } from '@amalia/ext/react/hooks';
import { ignoreOutsideEvents } from '@amalia/ext/web';
import { IconAmaliaLogo } from '@amalia/frontend/ui-icons';
import { ForecastIcon } from '@amalia/payout-calculation/forecasts/components';

import * as styles from './DatasetButton.styles';
import { DatasetButtonVariant } from './DatasetButton.types';

export type DatasetButtonProps = {
  readonly label: string;
  readonly hasMultipleDatasets: boolean;
  readonly isActive?: boolean;
  readonly onClick: () => void;
  readonly variant?: DatasetButtonVariant;
  readonly customObjectDefinitionType?: CustomObjectDefinitionType;
  readonly isForecasted?: boolean;
  readonly children?: ReactNode;
  readonly dragHandle?: ReactNode;
  readonly className?: string;
  readonly dataTestId?: string;
};

export const DatasetButtonBase = forwardRef(function DatasetButton(
  {
    label,
    hasMultipleDatasets,
    isActive,
    onClick,
    variant = DatasetButtonVariant.STATEMENT,
    customObjectDefinitionType = CustomObjectDefinitionType.AMALIA,
    isForecasted = false,
    dragHandle,
    children,
    className,
    dataTestId,
  }: DatasetButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const Logo = useMemo(() => {
    const logoType = CustomObjectDefToDataConnectorType[customObjectDefinitionType];
    return logoType && logoType in VENDOR_LOGOS ? VENDOR_LOGOS[logoType].CompactLogo : null;
  }, [customObjectDefinitionType]);

  const buttonRef = useForwardedRef(ref);

  return (
    <UnstyledButton
      ref={buttonRef}
      data-dataset-button
      className={clsx(variant, className, { active: isActive, hasMultipleDatasets })}
      css={styles.datasetButton}
      data-testid={dataTestId}
      onClick={ignoreOutsideEvents(buttonRef, onClick)} // ignore clicks inside dropdown
    >
      <Group
        align="center"
        gap={8}
      >
        {dragHandle}

        {!!isForecasted && <ForecastIcon size={16} />}

        {Logo ? <Logo height={18} /> : <IconAmaliaLogo height={18} />}

        <Typography
          as={TextOverflow}
          variant={TypographyVariant.BODY_BASE_REGULAR}
        >
          {label}
        </Typography>

        {children}
      </Group>
    </UnstyledButton>
  );
});

export const DatasetButton = memo(DatasetButtonBase);
