import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector, useAbilityContext } from '@amalia/kernel/auth/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';

const DashboardsV2List = lazy(() => import('./DashboardsV2List'));

export const DashboardsV2ListRoutes = memo(function DashboardsV2ListRoutes() {
  const { formatMessage } = useIntl();

  const { isFeatureEnabled: isDashboardsV2FFEnabled } = useFeatureFlag(CompanyFeatureFlags.CUSTOM_DASHBOARDS);

  const ability = useAbilityContext();
  const canAccessDashboardsV2 = isDashboardsV2FFEnabled && ability.can(ActionsEnum.view, SubjectsEnum.DashboardsV2);
  return (
    <Layout currentPage="dashboardsV2">
      <RootRoutes>
        <Route
          path={routes.DASHBOARDS_V2}
          element={
            <AbilityRouteProtector can={() => canAccessDashboardsV2}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Dashboards V2' })} />
              <DashboardsV2List />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
