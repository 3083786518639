import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { FormikInput, FormLayout, Modal, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import { NetsuiteConnectorAuthForm, type NetsuiteConnectorAuthFormProps } from '../NetsuiteConnectorAuthForm';

export type NetsuiteConnectorAuthFormModalProps = Omit<NetsuiteConnectorAuthFormProps, 'children'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const NetsuiteConnectorAuthFormModal = memo(function NetsuiteConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: NetsuiteConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <NetsuiteConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ isValid, status, isSubmitting }) => (
          <ConnectorAuthFormModalContent
            connectorType={DataConnectorTypes.NETSUITE}
            dataConnector={props.dataConnector}
            isRunningOAuthFlow={isSubmitting}
            isSubmitting={isSubmitting}
            isValid={isValid}
            status={status as unknown}
          >
            <FormLayout>
              <FormLayout.Group>
                <FormikInput
                  autoFocus
                  required
                  label={<FormattedMessage defaultMessage="Client ID" />}
                  name="clientId"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Client secret" />}
                  name="clientSecret"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Account ID" />}
                  name="instanceUrl"
                />
              </FormLayout.Group>
            </FormLayout>
          </ConnectorAuthFormModalContent>
        )}
      </NetsuiteConnectorAuthForm>
    </Modal>
  );
});
