import { type Editor } from '@tiptap/react';
import { type FormikErrors } from 'formik';
import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';
import { type RequiredDeep } from 'type-fest';

import { type FormulaBuilder } from '@amalia/amalia-lang/formula/types';
import { type TokenType } from '@amalia/amalia-lang/tokens/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';
import { assert } from '@amalia/ext/typescript';

import { type DesignerLibrary } from '../../types/designerLibrary';
import { type FormulaEditorToken } from '../../types/formulaEditorToken';

export type FormulaBuilderContextValue = {
  portalElement: HTMLElement | null;
  activeConditionId: string | null;
  setActiveConditionId: Dispatch<SetStateAction<string | null>>;
  editor: Editor | null;
  /** Errors for fields inside the builder. Conditions use their path to find their own errors. */
  errors: FormikErrors<RequiredDeep<FormulaBuilder>> | undefined; // Need RequiredDeep to declare errors, otherwise on optional objects it only accepts string.
  /** Selected custom object. */
  customObjectDefinition?: CustomObjectDefinition;
  /** Current plan id. */
  planId: string;
  /** Tokens available for the current plan (plan local tokens + global tokens). */
  designerLibrary: Pick<
    DesignerLibrary,
    | TokenType.FIELD
    | TokenType.LINK
    | TokenType.QUOTA
    | TokenType.VARIABLE
    | 'OBJECT_DEFINITIONS'
    | 'VIRTUAL_OBJECT_DEFINITIONS'
  >;
  tokens: FormulaEditorToken[];
};

export const FormulaBuilderContext = createContext<FormulaBuilderContextValue | null>(null);

export const useFormulaBuilderContext = () => {
  const contextContent = useContext(FormulaBuilderContext);

  assert(contextContent, 'useFormulaBuilderContext must be used within a <FormulaEditorContext.Provider>');

  return contextContent;
};
