// eslint-disable-next-line no-restricted-imports -- ONLY PLACE WHERE AUTHORIZED
import { type Theme, Tooltip as MuiTooltip, type TooltipProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import { memo, type MouseEvent, useMemo } from 'react';

// Adapted from https://github.com/mui/material-ui/issues/34813#issue-1413509488
const MUI_TOOLTIP_SELECT_CLASSNAMES = ['MuiTooltip-tooltip', 'MuiSelect-select', 'MuiInputBase-input'];
const shouldNotPreventDefault = (classList: DOMTokenList) =>
  Array.from(classList).some((className: string) => MUI_TOOLTIP_SELECT_CLASSNAMES.includes(className));

const popperProps = {
  onPointerDown: (e: MouseEvent) => {
    if (e.target instanceof Element && !shouldNotPreventDefault(e.target.classList)) {
      e.preventDefault();
    }
  },
};

const AmaliaTooltip = memo(function AmaliaTooltip(props: TooltipProps) {
  const mergedPopperProps = useMemo(
    () => ({
      ...props.PopperProps,
      ...popperProps,
    }),
    [props.PopperProps],
  );

  return (
    <MuiTooltip
      {...props}
      PopperProps={mergedPopperProps}
    />
  );
});

export const Tooltip = withStyles((theme: Theme) => ({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, .15)',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    maxWidth: 'none',
  },

  popper: {
    zIndex: 10_000,
  },
}))(AmaliaTooltip);
