import { isEqual } from 'lodash';
import moment from 'moment';

import { isHttpError } from '@amalia/core/http/client';
import {
  type Adjustment,
  type CreateDatasetOverwriteRequest,
  type CreateStatementOverwriteRequest,
  type Overwrite,
  type Statement,
  type StatementForecast,
} from '@amalia/core/types';
import { OverwriteScopeEnum } from '@amalia/data-correction/overwrites/types';
import { toError } from '@amalia/ext/typescript';
import {
  StatementAdjustmentApiClient,
  StatementDatasetsApiClient,
  StatementsApiClient,
} from '@amalia/payout-calculation/statements/state';
import { type ComputedOverwrite } from '@amalia/payout-calculation/types';
import { type WorkflowStatementStateAction } from '@amalia/payout-collaboration/workflows/types';
import { type Period, type PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import { PeriodsApiClient } from '@amalia/payout-definition/state';

import { type ThunkResult } from '../types';

import { STATEMENTS_ACTIONS } from './constants';
import { selectLastStatementsFetchParams, selectStatements } from './selectors';
import {
  type StatementsSetStatementsSuccessAction,
  type StatementsClearStatementsAction,
  type StatementsErrorAction,
  type StatementsStartAction,
  type StatementsSetUserStatementsSuccessAction,
  type StatementsSetStatementSuccessAction,
  type StatementsClearStatementAction,
  type StatementsSetForecastedStatementSuccessAction,
  type StatementsSetCurrentPeriodSuccessAction,
  type StatementsProcessWorkflowStepAction,
  type StatementsCreateAdjustmentAction,
  type StatementDeleteAdjustmentAction,
  type StatementsEditAdjustmentAction,
  type StatementsCreateOverwriteAction,
  type StatementsCreateSimulatedOverwriteAction,
  type StatementsClearOverwriteAction,
  type StatementsClearSimulatedOverwriteAction,
} from './types';

const statementsStart = (): StatementsStartAction => ({
  type: STATEMENTS_ACTIONS.START,
});

const statementsError = (error: Error): StatementsErrorAction => ({
  type: STATEMENTS_ACTIONS.ERROR,
  error,
});

const setStatementsSuccess = (
  statements: Statement[],
  options: StatementsSetStatementsSuccessAction['payload']['options'],
): StatementsSetStatementsSuccessAction => ({
  type: STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS,
  payload: { statements, options },
});

export const clearStatements = (): StatementsClearStatementsAction => ({
  type: STATEMENTS_ACTIONS.CLEAR_STATEMENTS,
});

/// //////////
/// STATEMENTS
/// /////////
export const fetchStatementsThunkAction =
  (
    periodId?: string,
    planId?: string,
    teamId?: string,
    userId?: string,
    withKpiResults?: boolean,
    force: boolean = false,
  ): ThunkResult<Promise<StatementsErrorAction | StatementsSetStatementsSuccessAction>> =>
  async (dispatch, getState) => {
    dispatch(statementsStart());
    const lastOptions = selectLastStatementsFetchParams(getState());
    const statementsFromState = selectStatements(getState());

    const options = {
      periodId,
      planId,
      teamId,
      userId,
    };

    if (!force && isEqual(options, lastOptions)) {
      return dispatch(setStatementsSuccess(Object.values(statementsFromState), options));
    }
    try {
      const statements = await StatementsApiClient.findStatements(
        periodId,
        planId,
        teamId,
        userId ? [userId] : [],
        withKpiResults,
      );
      return dispatch(setStatementsSuccess(statements, options));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

export const getStatementsByIdThunkAction =
  (ids: string[]): ThunkResult<Promise<StatementsErrorAction | StatementsSetStatementsSuccessAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      const statements = await StatementsApiClient.getStatements(ids);
      return dispatch(setStatementsSuccess(statements, { ids }));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

const setUserStatementsSuccess = (userStatements: Statement[]): StatementsSetUserStatementsSuccessAction => ({
  type: STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS,
  payload: { userStatements },
});

export const fetchUserStatementsThunkAction =
  (
    periodId?: string,
    planId?: string,
    teamId?: string,
    userId?: string,
    withKpiResults?: boolean,
  ): ThunkResult<Promise<StatementsErrorAction | StatementsSetUserStatementsSuccessAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const statements = await StatementsApiClient.findStatements(
        periodId,
        planId,
        teamId,
        userId ? [userId] : [],
        withKpiResults,
      );
      return dispatch(setUserStatementsSuccess(statements));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

const setStatementSuccess = (statement: Statement): StatementsSetStatementSuccessAction => ({
  type: STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS,
  payload: { statement },
});

export const clearCurrentStatement = (): StatementsClearStatementAction => ({
  type: STATEMENTS_ACTIONS.CLEAR_STATEMENT,
});

const setForecastedStatementSuccess = (
  forecastedStatement: StatementForecast,
): StatementsSetForecastedStatementSuccessAction => ({
  type: STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS,
  payload: { forecastedStatement },
});

export const fetchStatementThunkAction =
  (id: string): ThunkResult<Promise<StatementsErrorAction | StatementsSetStatementSuccessAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const statement = await StatementsApiClient.getStatement(id);
      return dispatch(setStatementSuccess(statement));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

export const fetchForecastedStatementThunkAction =
  (
    forecastId: string,
    statementId: string,
    withObjectsToDisplay: boolean = false,
  ): ThunkResult<Promise<StatementsErrorAction | StatementsSetForecastedStatementSuccessAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const forecastedStatement = await StatementsApiClient.getForecastedStatement(
        forecastId,
        statementId,
        withObjectsToDisplay,
      );
      return dispatch(setForecastedStatementSuccess(forecastedStatement));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

/**
 * @deprecated use the React query function from statements.queries.ts instead.
 * @param planId
 * @param userId
 * @param periodId
 */
export const fetchStatementByPlanPeriodUserThunkAction =
  (
    planId: string,
    userId: string,
    periodId: string,
  ): ThunkResult<Promise<StatementsErrorAction | StatementsSetStatementSuccessAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const statement = await StatementsApiClient.findStatementByCriteria(periodId, planId, userId);
      return dispatch(setStatementSuccess(statement));
    } catch (error) {
      dispatch(clearCurrentStatement());
      if (isHttpError(error) && error.statusCode === 404) {
        return dispatch(statementsError(new Error('Not found')));
      }
      return dispatch(statementsError(toError(error)));
    }
  };

const setCurrentPeriodSuccess = (currentPeriod: Period): StatementsSetCurrentPeriodSuccessAction => ({
  type: STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS,
  payload: { currentPeriod },
});

export const setCurrentPeriodThunkAction =
  (currentPeriod: Period): ThunkResult<Promise<StatementsSetCurrentPeriodSuccessAction>> =>
  (dispatch) => {
    dispatch(statementsStart());
    return Promise.resolve(dispatch(setCurrentPeriodSuccess(currentPeriod)));
  };

/**
 * @deprecated: use period.queries.ts instead.
 * You need to pass the frequency defaulting to company.statementFrequency (legacy from redux -> react-query).
 */
export const fetchCurrentPeriodThunkAction =
  (
    currentDate: string | undefined,
    frequency: PeriodFrequencyEnum,
  ): ThunkResult<Promise<StatementsErrorAction | StatementsSetCurrentPeriodSuccessAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    const periodDateString = currentDate || moment.utc().format('YYYY-MM-DD');

    try {
      const currentPeriod = await PeriodsApiClient.getPeriodByDate(periodDateString, frequency);
      return dispatch(setCurrentPeriodSuccess(currentPeriod));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

/// ///////
/// REVIEW
/// //////
const processWorkflowAction = (
  statementId: string,
  workflowAction: WorkflowStatementStateAction,
  updatedStatement: Statement,
): StatementsProcessWorkflowStepAction => ({
  type: STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP,
  payload: { statementId, workflowAction, updatedStatement },
});

export const processWorkflowStepThunkAction =
  (
    statementId: string,
    workflowAction: WorkflowStatementStateAction,
    isNotify: boolean,
  ): ThunkResult<Promise<StatementsErrorAction | StatementsProcessWorkflowStepAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const updatedStatement = await StatementsApiClient.processWorkflowStep(statementId, workflowAction, isNotify);
      return dispatch(processWorkflowAction(statementId, workflowAction, updatedStatement));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

/// ////////////
/// ADJUSTEMENTS
/// ///////////
const createAdjustmentAction = (statementId: string, adjustment: Adjustment): StatementsCreateAdjustmentAction => ({
  type: STATEMENTS_ACTIONS.CREATE_ADJUSTMENT,
  payload: { statementId, adjustment },
});

export const createAdjustmentThunkAction =
  (
    statementId: string,
    adjustment: Adjustment,
  ): ThunkResult<Promise<StatementsCreateAdjustmentAction | StatementsErrorAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      await StatementAdjustmentApiClient.createStatementAdjustment(statementId, adjustment);
      return dispatch(createAdjustmentAction(statementId, adjustment));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

const deleteAdjustmentAction = (statementId: string, adjustmentId: string): StatementDeleteAdjustmentAction => ({
  type: STATEMENTS_ACTIONS.DELETE_ADJUSTMENT,
  payload: { statementId, adjustmentId },
});

export const deleteAdjustment =
  (
    statementId: string,
    adjustmentId: string,
  ): ThunkResult<Promise<StatementDeleteAdjustmentAction | StatementsErrorAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      await StatementAdjustmentApiClient.deleteStatementAdjustment(adjustmentId);
      return dispatch(deleteAdjustmentAction(statementId, adjustmentId));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

const editAdjustmentAction = (statementId: string, adjustment: Adjustment): StatementsEditAdjustmentAction => ({
  type: STATEMENTS_ACTIONS.EDIT_ADJUSTMENT,
  payload: { statementId, adjustment },
});

export const editAdjustmentThunkAction =
  (
    statementId: string,
    adjustment: Adjustment,
  ): ThunkResult<Promise<StatementsEditAdjustmentAction | StatementsErrorAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      await StatementAdjustmentApiClient.editStatementAdjustment(adjustment);
      return dispatch(editAdjustmentAction(statementId, adjustment));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

/// //////////
/// OVERWRITES
/// /////////
const createOverwriteAction = (overwrite: Overwrite): StatementsCreateOverwriteAction => ({
  type: STATEMENTS_ACTIONS.CREATE_OVERWRITE,
  payload: { overwrite },
});

const createSimulatedOverwriteAction = (overwrite: Overwrite): StatementsCreateSimulatedOverwriteAction => ({
  type: STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE,
  payload: { overwrite },
});

export const createKpiOverwriteThunkAction =
  (
    statementId: string,
    createStatementOverwriteRequest: CreateStatementOverwriteRequest,
  ): ThunkResult<
    Promise<StatementsCreateOverwriteAction | StatementsCreateSimulatedOverwriteAction | StatementsErrorAction>
  > =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      const overwrite = await StatementsApiClient.createOverwrite(statementId, createStatementOverwriteRequest);
      return overwrite.scope === OverwriteScopeEnum.FORECAST
        ? dispatch(createSimulatedOverwriteAction(overwrite))
        : dispatch(createOverwriteAction(overwrite));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

export const createDatasetOverwriteThunkAction =
  (
    statementId: string,
    datasetId: string,
    createStatementDatasetOverwriteRequest: CreateDatasetOverwriteRequest,
  ): ThunkResult<Promise<StatementsCreateOverwriteAction | StatementsErrorAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      const overwrite = await StatementDatasetsApiClient.createStatementDatasetOverwrite(
        statementId,
        datasetId,
        createStatementDatasetOverwriteRequest,
      );
      return dispatch(createOverwriteAction(overwrite));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };

const clearOverwriteAction = (overwrite: ComputedOverwrite | Overwrite): StatementsClearOverwriteAction => ({
  type: STATEMENTS_ACTIONS.CLEAR_OVERWRITE,
  payload: { overwrite },
});

const clearSimulatedOverwriteAction = (
  overwrite: ComputedOverwrite | Overwrite,
): StatementsClearSimulatedOverwriteAction => ({
  type: STATEMENTS_ACTIONS.CLEAR_SIMULATED_OVERWRITE,
  payload: { overwrite },
});

export const clearStatementOverwriteThunkAction =
  (
    statementId: string,
    overwrite: ComputedOverwrite | Overwrite,
  ): ThunkResult<
    Promise<StatementsClearOverwriteAction | StatementsClearSimulatedOverwriteAction | StatementsErrorAction>
  > =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      await StatementsApiClient.clearStatementOverwrite(statementId, overwrite.id);
      return overwrite.scope === OverwriteScopeEnum.FORECAST
        ? dispatch(clearSimulatedOverwriteAction(overwrite))
        : dispatch(clearOverwriteAction(overwrite));
    } catch (error) {
      return dispatch(statementsError(toError(error)));
    }
  };
