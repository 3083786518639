import { useQuery } from '@tanstack/react-query';

import { getScope } from './calculation-scope.api-client';
import { CALCULATION_SCOPE_QUERY_KEYS } from './calculation-scope.keys';

export const useCalculationScope = () =>
  useQuery({
    queryKey: [CALCULATION_SCOPE_QUERY_KEYS.CALCULATION_SCOPE],
    queryFn: getScope,
  });
