import { get, isEmpty, uniqBy } from 'lodash';

import { type DesignerAllObjects, type DesignerAvailableTypes } from '@amalia/amalia-lang/formula/components';
import { type AmaliaFunctionWithId } from '@amalia/amalia-lang/formula/evaluate/shared';
import { TokenType, type Variable } from '@amalia/amalia-lang/tokens/types';
import { assert } from '@amalia/ext/typescript';
import { type Filter, type Rule } from '@amalia/payout-definition/plans/types';

import { designerObjectsDetails } from '../../../utils/designer';

/** If two fields have the same translation in formula, keep the local one. */
const removeDuplicateFields = (fields: Variable[]) =>
  uniqBy(
    fields.toSorted((a, b) => (a.planId && !b.planId ? -1 : !a.planId && b.planId ? 1 : 0)),
    (field) => {
      assert(field.object, 'Field object is required');
      return `${field.object.machineName}.${field.machineName}`;
    },
  );

/** If two variables have the same translation in formula, keep the local one. */
const removeDuplicateVariables = (variables: Variable[]) =>
  uniqBy(
    variables.toSorted((a, b) => (a.planId && !b.planId ? -1 : !a.planId && b.planId ? 1 : 0)),
    'machineName',
  );

/** If two filters have the same translation in formula, keep the local one. */
const removeDuplicateFilters = (filters: Filter[]) =>
  uniqBy(
    filters.toSorted((a, b) => (a.planId && !b.planId ? -1 : !a.planId && b.planId ? 1 : 0)),
    'machineName',
  );

/** If two rules have the same translation in formula, keep the local one. */
const removeDuplicateRules = (rules: Rule[]) =>
  uniqBy(
    rules.toSorted((a, b) => (a.planId && !b.planId ? -1 : !a.planId && b.planId ? 1 : 0)),
    'machineName',
  );

const filterGlobalAndCurrentPlan = (variable: { planId?: string | null }, planId: string) =>
  !variable.planId || variable.planId === planId;

/**
 * Filter all objects to keep only those that match the current planId + global ones.
 * If a local variable and a global variable have the same translation into formula, keep the local one.
 *
 * @param allObjects All designer objects.
 * @param planId Current planId.
 * @returns New object with filtered variables, filters, fields and rules.
 */
export const filterDesignerObjectsForPlan = (allObjects: DesignerAllObjects, planId: string): DesignerAllObjects => ({
  ...allObjects,
  [TokenType.VARIABLE]: removeDuplicateVariables(
    (allObjects[TokenType.VARIABLE] as Variable[]).filter((variable) => filterGlobalAndCurrentPlan(variable, planId)),
  ),
  [TokenType.FILTER]: removeDuplicateFilters(
    (allObjects[TokenType.FILTER] as Filter[]).filter((filter) => filterGlobalAndCurrentPlan(filter, planId)),
  ),
  [TokenType.FIELD]: removeDuplicateFields(
    (allObjects[TokenType.FIELD] as Variable[]).filter((field) => filterGlobalAndCurrentPlan(field, planId)),
  ),
  [TokenType.RULE]: removeDuplicateRules(
    (allObjects[TokenType.RULE] as Rule[]).filter((rule) => filterGlobalAndCurrentPlan(rule, planId)),
  ),
});

const filterObjects = (
  currentObject: TokenType,
  objects: DesignerAvailableTypes[],
  filterOptions?: {
    planId?: string;
    ruleId?: string;
    needle?: string;
  },
): DesignerAvailableTypes[] => {
  const { planId, ruleId, needle } = filterOptions || {};

  const objectsApplicableToContext = objects
    // Filter once to grab object that match the current planId / ruleId.
    .filter((value) => {
      const objectPlanId = 'planId' in value ? value.planId : undefined;
      const objectRuleId = 'ruleId' in value ? value.ruleId : undefined;

      // Remove functions that should not appear in the library
      if (currentObject === TokenType.FUNCTION) {
        return !(value as AmaliaFunctionWithId).hiddenFromLibrary;
      }

      // Keep all values.
      if (isEmpty(planId) && isEmpty(ruleId)) {
        return true;
      }

      // Keep only global values.
      if (planId === 'global' && ruleId === 'global') {
        return !objectPlanId && !objectRuleId;
      }

      // Keep plan scoped values or global ones.
      if (!isEmpty(planId)) {
        return objectPlanId === planId || !objectPlanId;
      }

      // Keep rule scoped values or global ones.
      if (!isEmpty(ruleId) && 'ruleId' in value) {
        return objectRuleId === ruleId || !objectRuleId;
      }

      return false;
    });

  return needle
    ? // Filter again to find those who match current search.
      objectsApplicableToContext.filter((value) => {
        const haystack = (
          (designerObjectsDetails[currentObject].fieldsToSearchIn || ['name']) as (keyof typeof value)[]
        )
          .map((f) => get(value, f, ''))
          .join(',');
        return haystack.toLowerCase().includes(needle.toLowerCase());
      })
    : objectsApplicableToContext;
};

export const filterAllObjects = (
  allObjects: DesignerAllObjects,
  filterOptions?: {
    planId?: string;
    ruleId?: string;
    needle?: string;
  },
) =>
  Object.entries(allObjects).reduce<DesignerAllObjects>((acc, [key, objectsInCurrentKey]) => {
    acc[key as TokenType] = filterObjects(key as TokenType, objectsInCurrentKey, filterOptions);
    return acc;
  }, {} as DesignerAllObjects);
