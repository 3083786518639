import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import {
  useCreateConnector,
  useAuthenticateWithOAuth,
  useUpdateConnectorAuth,
} from '@amalia/data-capture/connectors/state';
import {
  type OAuthDataConnectorAuth,
  type DataConnector,
  type ConnectorResponse,
  DataConnectorCategories,
  DataConnectorTypes,
  AuthType,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, type FormikFormProps } from '@amalia/ext/formik';

type MicrosoftDynamicsConnectorAuthFormValues = {
  tenantId: string;
};

type MicrosoftDynamicsConnectorAuthFormikProps = FormikFormProps<
  MicrosoftDynamicsConnectorAuthFormValues,
  ConnectorResponse
>;

export type MicrosoftDynamicsConnectorAuthFormProps = Omit<
  MicrosoftDynamicsConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
};

export const MicrosoftDynamicsConnectorAuthForm = memo(function MicrosoftDynamicsConnectorAuthForm({
  dataConnector,
  children,
  ...props
}: MicrosoftDynamicsConnectorAuthFormProps) {
  const { formatMessage } = useIntl();

  const dataConnectorAuth = dataConnector?.auth as OAuthDataConnectorAuth | null | undefined;

  const { mutateAsync: authenticateWithOAuth } = useAuthenticateWithOAuth(
    Yup.object().shape({
      code: Yup.string().required('code is required'),
      state: Yup.string().required('state is required'),
    }),
  );
  const { mutateAsync: createConnector } = useCreateConnector();
  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth();

  const initialValues = useMemo(
    (): MicrosoftDynamicsConnectorAuthFormValues => ({
      tenantId: dataConnectorAuth?.tenantId || '',
    }),
    [dataConnectorAuth],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        tenantId: Yup.string()
          .matches(
            /^https:\/\/\w+\.\w+\.dynamics.com/u,
            formatMessage({ defaultMessage: 'Please enter a valid Microsoft Dynamics url.' }),
          )
          .required(),
      }),
    [formatMessage],
  );

  const handleSubmit: MicrosoftDynamicsConnectorAuthFormikProps['onSubmit'] = useCallback(
    async (values) => {
      const oauthQueryParams = await authenticateWithOAuth({
        connectorType: DataConnectorTypes.MSDYNAMICS,
        queryParams: {
          tenantId: values.tenantId,
        },
      });

      const authRequest = {
        code: oauthQueryParams.code,
        state: oauthQueryParams.state,
      };

      return !dataConnector
        ? createConnector({
            category: DataConnectorCategories.DATA,
            authType: AuthType.OAuth2_authorizationCode,
            type: DataConnectorTypes.MSDYNAMICS,
            auth: authRequest,
          })
        : updateConnectorAuth({
            id: dataConnector.id,
            type: DataConnectorTypes.MSDYNAMICS,
            auth: authRequest,
          });
    },
    [createConnector, dataConnector, authenticateWithOAuth, updateConnectorAuth],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
