import { useQuery } from '@tanstack/react-query';

import { type CalculationRequest } from '@amalia/core/types';

import { CalculationsApiClient } from '../api-client/calculations.api-client';

import { COMPUTE_ENGINE_KEYS } from './compute-engine.queryKeys';

export const useCalculationAnalyze = (calculationRequest?: CalculationRequest) =>
  useQuery({
    queryKey: [COMPUTE_ENGINE_KEYS, 'analyze'],
    queryFn: () => CalculationsApiClient.analyzeCalculation(calculationRequest!),
    enabled: !!calculationRequest,
  });
