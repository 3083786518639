import { useCallback, useMemo } from 'react';

import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';
import { StringUtils } from '@amalia/core/types';
import { ColumnHelper, Table } from '@amalia/design-system/components';

import { TableBuilderRowActionsCell } from '../cells/TableBuilderRowActionsCell';
import { TableBuilderRowValueCell } from '../cells/TableBuilderRowValueCell';
import { TableBuilderColumnActions } from '../columns/TableBuilderColumnActions';
import { rowKeySymbol, type TableBuilderDataGridRowData, type TableBuilderParsedRow } from '../TableBuilder.types';

import { useGenerateColumn } from './use-generate-column';

const columnHelper = new ColumnHelper<TableBuilderDataGridRowData>();

const mapDataGridRowToParsedRow = (
  row: TableBuilderDataGridRowData,
  columns: VariableFormatOptionsColumn[],
): TableBuilderParsedRow => columns.map((column) => row[column.machineName] ?? '0');

const mapDataGridRowsToParsedRows = (
  rows: TableBuilderDataGridRowData[],
  columns: VariableFormatOptionsColumn[],
): TableBuilderParsedRow[] => rows.map((row) => mapDataGridRowToParsedRow(row, columns));

/**
 * This hook takes the parsed rows and columns and adapts them to the DataGrid component.
 */
export const useTableBuilderDataGridAdapter = ({
  parsedRows,
  tableBuilderColumns,
  isReadonly,
  onChange,
  onChangeColumns,
}: {
  readonly parsedRows: TableBuilderParsedRow[];
  readonly tableBuilderColumns: VariableFormatOptionsColumn[];
  readonly isReadonly: boolean;
  readonly onChange?: (rows: TableBuilderParsedRow[]) => void;
  readonly onChangeColumns?: (columns: VariableFormatOptionsColumn[]) => void;
}) => {
  const generateColumn = useGenerateColumn();

  /** Build rows based on column machine names (we can't use indexes because we need to be able to remove columns). */
  const datagridRows = useMemo(
    () =>
      parsedRows.map((row, rowIndex) =>
        tableBuilderColumns.reduce<TableBuilderDataGridRowData>(
          (acc, column, colIndex) => {
            acc[column.machineName] = row[colIndex];
            return acc;
          },
          { [rowKeySymbol]: `${rowIndex}` },
        ),
      ),
    [parsedRows, tableBuilderColumns],
  );

  const handleCreateRow = useCallback(
    () => onChange?.([...parsedRows, tableBuilderColumns.map(() => '0')]),
    [onChange, parsedRows, tableBuilderColumns],
  );

  const handleChangeRow = useCallback(
    (
      key: TableBuilderDataGridRowData[typeof rowKeySymbol],
      column: VariableFormatOptionsColumn['machineName'],
      value: TableBuilderParsedRow[number],
    ) =>
      onChange?.(
        mapDataGridRowsToParsedRows(
          datagridRows.map((row) => (row[rowKeySymbol] === key ? { ...row, [column]: value } : row)),
          tableBuilderColumns,
        ),
      ),
    [onChange, datagridRows, tableBuilderColumns],
  );

  const handleDeleteRow = useCallback(
    (key: TableBuilderDataGridRowData[typeof rowKeySymbol]) =>
      onChange?.(
        mapDataGridRowsToParsedRows(
          datagridRows.filter((row) => row[rowKeySymbol] !== key),
          tableBuilderColumns,
        ),
      ),
    [onChange, datagridRows, tableBuilderColumns],
  );

  const handleCreateColumn = useCallback(() => {
    const newColumns = [...tableBuilderColumns, generateColumn(tableBuilderColumns)];

    onChangeColumns?.(newColumns);
    onChange?.(mapDataGridRowsToParsedRows(datagridRows, newColumns));
  }, [tableBuilderColumns, datagridRows, onChange, onChangeColumns, generateColumn]);

  const handleChangeColumn = useCallback(
    (
      machineName: VariableFormatOptionsColumn['machineName'],
      {
        name,
        format,
      }: {
        name: VariableFormatOptionsColumn['name'];
        format: VariableFormatOptionsColumn['format'];
      },
    ) => {
      const newColumns = tableBuilderColumns.map((column) =>
        column.machineName === machineName
          ? {
              name,
              machineName: StringUtils.camelCase(name),
              format,
            }
          : column,
      );

      onChangeColumns?.(newColumns);
    },
    [tableBuilderColumns, onChangeColumns],
  );

  const handleDeleteColumn = useCallback(
    (machineName: VariableFormatOptionsColumn['machineName']) => {
      const newColumns = tableBuilderColumns.filter((column) => column.machineName !== machineName);
      onChangeColumns?.(newColumns);
      onChange?.(mapDataGridRowsToParsedRows(datagridRows, newColumns));
    },
    [tableBuilderColumns, datagridRows, onChange, onChangeColumns],
  );

  const datagridColumns = useMemo(
    () =>
      [
        ...tableBuilderColumns.map((column) =>
          columnHelper.accessor(column.machineName, {
            id: column.machineName,
            header: column.name,
            actions: !isReadonly ? (
              <TableBuilderColumnActions
                allColumns={tableBuilderColumns}
                column={column}
                hideDeleteAction={tableBuilderColumns.length < 2}
                onChangeColumn={handleChangeColumn}
                onDeleteColumn={handleDeleteColumn}
              />
            ) : undefined,
            cell: ({ value, row }) => (
              <TableBuilderRowValueCell
                column={column}
                isReadonly={isReadonly}
                rowKey={row[rowKeySymbol]}
                value={value}
                onChange={handleChangeRow}
              />
            ),
          }),
        ),
        !isReadonly &&
          columnHelper.display({
            id: Table.Cell.Actions.columnId,
            header: '',
            size: 0,
            cell: ({ row }) => (
              <TableBuilderRowActionsCell
                disabled={datagridRows.length < 2}
                row={row}
                onDeleteRow={handleDeleteRow}
              />
            ),
          }),
      ].filter(Boolean),
    [
      isReadonly,
      tableBuilderColumns,
      datagridRows.length,
      handleDeleteRow,
      handleChangeRow,
      handleChangeColumn,
      handleDeleteColumn,
    ],
  );

  return {
    datagridColumns,
    datagridRows,
    onCreateRow: handleCreateRow,
    onCreateColumn: handleCreateColumn,
  };
};
