import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type Quota } from '@amalia/amalia-lang/tokens/types';
import { QuotasAssignmentsApiClient } from '@amalia/assignments/quotas/api-client';
import {
  type QuotaPlanAssignments,
  type QuotaTeamAssignments,
  type QuotaUserAssignments,
  type SearchQuotaAssignmentsRequest,
} from '@amalia/assignments/quotas/types';
import { assert } from '@amalia/ext/typescript';

import { QUOTA_ASSIGNMENTS_KEYS, QUOTA_ASSIGNMENTS_MUTATION_KEYS } from './queries.keys';

export const useQuotaAssignments = <
  TQuotaAssignments extends QuotaPlanAssignments | QuotaTeamAssignments | QuotaUserAssignments,
>(
  quotaId?: Quota['id'],
  { startDate, endDate, planIds }: Partial<SearchQuotaAssignmentsRequest> = {},
) =>
  useQuery({
    enabled: !!quotaId && !!startDate && !!endDate,
    queryKey: [QUOTA_ASSIGNMENTS_KEYS.QUOTA_ASSIGNMENTS, quotaId, startDate, endDate, planIds],
    queryFn: () => {
      assert(
        !!quotaId && !!startDate && !!endDate,
        'Query should be disabled if quotaId or startDate or endDate is not provided',
      );

      return QuotasAssignmentsApiClient.getQuotaAssignments<TQuotaAssignments>(quotaId, {
        startDate,
        endDate,
        planIds,
      });
    },
  });

export const useUpsertQuotaAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUOTA_ASSIGNMENTS_MUTATION_KEYS.UPSERT_QUOTA_ASSIGNMENT],
    mutationFn: QuotasAssignmentsApiClient.upsertQuotaAssignment,
    onSuccess: async (_, upsertQuotaRequest) => {
      await Promise.all(
        [
          upsertQuotaRequest.userId &&
            queryClient.invalidateQueries({
              queryKey: [QUOTA_ASSIGNMENTS_KEYS.USER_QUOTA_ASSIGNMENTS, upsertQuotaRequest.userId],
            }),
          queryClient.invalidateQueries({
            queryKey: [QUOTA_ASSIGNMENTS_KEYS.QUOTA_ASSIGNMENTS, upsertQuotaRequest.variableId],
          }),
        ].filter(Boolean),
      );
    },
  });
};

export const useUpsertQuotasAssignments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUOTA_ASSIGNMENTS_MUTATION_KEYS.UPSERT_QUOTAS_ASSIGNMENTS],
    mutationFn: QuotasAssignmentsApiClient.upsertQuotasAssignments,
    onSuccess: async (_, upsertQuotasRequest) => {
      await Promise.all(
        upsertQuotasRequest.quotas.map((upsertQuotaRequest) =>
          Promise.all(
            [
              upsertQuotaRequest.userId &&
                queryClient.invalidateQueries({
                  queryKey: [QUOTA_ASSIGNMENTS_KEYS.USER_QUOTA_ASSIGNMENTS, upsertQuotaRequest.userId],
                }),
              queryClient.invalidateQueries({
                queryKey: [QUOTA_ASSIGNMENTS_KEYS.QUOTA_ASSIGNMENTS, upsertQuotaRequest.variableId],
              }),
            ].filter(Boolean),
          ),
        ),
      );
    },
  });
};

export const useIsUpsertAssignmentLoading = () =>
  useIsMutating({ mutationKey: [QUOTA_ASSIGNMENTS_MUTATION_KEYS.UPSERT_QUOTA_ASSIGNMENT] }) +
    useIsMutating({ mutationKey: [QUOTA_ASSIGNMENTS_MUTATION_KEYS.UPSERT_QUOTAS_ASSIGNMENTS] }) >
  0;

export const useDeleteQuotaAssignments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUOTA_ASSIGNMENTS_MUTATION_KEYS.DELETE_QUOTA_ASSIGNMENTS],
    mutationFn: QuotasAssignmentsApiClient.deleteQuotaAssignments,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUOTA_ASSIGNMENTS_KEYS.USER_QUOTA_ASSIGNMENTS] }),
        queryClient.invalidateQueries({ queryKey: [QUOTA_ASSIGNMENTS_KEYS.QUOTA_ASSIGNMENTS] }),
      ]);
    },
  });
};

export const useDeleteQuotaAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUOTA_ASSIGNMENTS_MUTATION_KEYS.DELETE_QUOTA_ASSIGNMENT],
    mutationFn: QuotasAssignmentsApiClient.deleteQuotaAssignment,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUOTA_ASSIGNMENTS_KEYS.USER_QUOTA_ASSIGNMENTS] }),
        queryClient.invalidateQueries({ queryKey: [QUOTA_ASSIGNMENTS_KEYS.QUOTA_ASSIGNMENTS] }),
      ]);
    },
  });
};
