import { css } from '@emotion/react';
import { lazy, memo, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { CONNECTORS } from '@amalia/core/types';
import { type DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { Modal, Skeleton, Stack, TextLink, type ModalProps } from '@amalia/design-system/components';

const csmEmailAddress = 'csm@amalia.io';

const UnsupportedConnectorAuthFormModalSvg = lazy(async () => ({
  default: (await import('./UnsupportedConnectorAuthFormModal.svg')).ReactComponent,
}));

export type UnsupportedConnectorAuthFormModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> & {
  readonly connectorType: DataConnectorTypes;
};

export const UnsupportedConnectorAuthFormModal = memo(function UnsupportedConnectorAuthFormModal({
  connectorType,
  isOpen,
  onClose,
}: UnsupportedConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              defaultMessage="Connect to {connectorType}"
              values={{ connectorType: CONNECTORS[connectorType].name }}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Stack
            align="center"
            gap={48}
            css={css`
              padding: 32px;
            `}
          >
            <Suspense
              fallback={
                <Skeleton
                  css={css`
                    height: 189px;
                    width: 340px;
                  `}
                />
              }
            >
              <UnsupportedConnectorAuthFormModalSvg />
            </Suspense>

            <div
              css={css`
                text-align: center;
              `}
            >
              <FormattedMessage
                defaultMessage="Please contact your CSM at {emailAddress} for more information."
                values={{ emailAddress: <TextLink to={`mailto:${csmEmailAddress}`}>{csmEmailAddress}</TextLink> }}
              />
            </div>
          </Stack>
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction onClick={onClose}>
          <FormattedMessage defaultMessage="Got it" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
