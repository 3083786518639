import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Checkbox,
  FormikInput,
  FormikTextArea,
  FormLayout,
  Modal,
  type ModalProps,
} from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import { DatabaseConnectorAuthForm, type DatabaseConnectorAuthFormProps } from '../DatabaseConnectorAuthForm';

export type DatabaseConnectorAuthFormModalProps = Omit<DatabaseConnectorAuthFormProps, 'children' | 'onSubmitSuccess'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const DatabaseConnectorAuthFormModal = memo(function DatabaseConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: DatabaseConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <DatabaseConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ values, status, isValid, isSubmitting, setFieldValue }) => (
          <ConnectorAuthFormModalContent
            connectorType={props.connectorType}
            dataConnector={props.dataConnector}
            isSubmitting={isSubmitting}
            isValid={isValid}
            status={status as unknown}
          >
            <FormLayout>
              <FormLayout.Group>
                <FormikInput
                  autoFocus
                  required
                  label={<FormattedMessage defaultMessage="Host" />}
                  name="host"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Port" />}
                  name="port"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="User" />}
                  name="user"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Password" />}
                  name="password"
                  type="password"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Database" />}
                  name="database"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Schema" />}
                  name="schema"
                />
              </FormLayout.Group>

              <FormLayout.Group title={<FormattedMessage defaultMessage="SSH tunnel" />}>
                <Checkbox
                  checked={values.ssh !== null}
                  label={<FormattedMessage defaultMessage="Use SSH tunnel?" />}
                  name="useSSHTunnel"
                  onChange={(useSSHTunnel) => {
                    // Reset SSH values when disabling SSH tunnel.
                    setFieldValue(
                      'ssh',
                      useSSHTunnel
                        ? {
                            host: '',
                            port: '',
                            sshPrivateKey: '',
                            user: '',
                          }
                        : null,
                    ).catch(() => {});
                  }}
                />

                {values.ssh !== null && (
                  <Fragment>
                    <FormikInput
                      label={<FormattedMessage defaultMessage="Host" />}
                      name="ssh.host"
                    />

                    <FormikInput
                      label={<FormattedMessage defaultMessage="Port" />}
                      name="ssh.port"
                    />

                    <FormikInput
                      label={<FormattedMessage defaultMessage="User" />}
                      name="ssh.user"
                    />

                    <FormikTextArea
                      label={<FormattedMessage defaultMessage="Private key" />}
                      name="ssh.privateKey"
                      rows={4}
                    />
                  </Fragment>
                )}
              </FormLayout.Group>
            </FormLayout>
          </ConnectorAuthFormModalContent>
        )}
      </DatabaseConnectorAuthForm>
    </Modal>
  );
});
