import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { isUnprocessableEntityError } from '@amalia/core/http/client';
import { useCreateConnector, useUpdateConnectorAuth } from '@amalia/data-capture/connectors/state';
import {
  type DataConnector,
  type ConnectorResponse,
  DataConnectorCategories,
  AuthType,
  DataConnectorTypes,
  type SnowflakeConnectorAuth,
} from '@amalia/data-capture/connectors/types';
import { FormikForm, SubmissionError, type FormikFormProps } from '@amalia/ext/formik';

type SnowflakeConnectorAuthFormValues = {
  account: string;
  database: string;
  password: string;
  schema: string;
  username: string;
  warehouse?: string;
};

type SnowflakeConnectorAuthFormikProps = FormikFormProps<SnowflakeConnectorAuthFormValues, ConnectorResponse>;

export type SnowflakeConnectorAuthFormProps = Omit<
  SnowflakeConnectorAuthFormikProps,
  'enableReinitialize' | 'initialValues' | 'onSubmit' | 'validationSchema'
> & {
  readonly dataConnector?: DataConnector;
};

export const SnowflakeConnectorAuthForm = memo(function SnowflakeConnectorAuthForm({
  dataConnector,
  children,
  ...props
}: SnowflakeConnectorAuthFormProps) {
  const dataConnectorAuth = dataConnector?.auth as SnowflakeConnectorAuth | null | undefined;

  const { mutateAsync: createConnector } = useCreateConnector({
    shouldIgnoreError: isUnprocessableEntityError,
  });

  const { mutateAsync: updateConnectorAuth } = useUpdateConnectorAuth({
    shouldIgnoreError: isUnprocessableEntityError,
  });

  const initialValues = useMemo(
    (): SnowflakeConnectorAuthFormValues => ({
      account: dataConnectorAuth?.account || '',
      database: dataConnectorAuth?.database || '',
      password: dataConnectorAuth?.password || '',
      schema: dataConnectorAuth?.schema || '',
      username: dataConnectorAuth?.username || '',
      warehouse: dataConnectorAuth?.warehouse || '',
    }),
    [dataConnectorAuth],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        account: Yup.string().required(),
        database: Yup.string().required(),
        password: Yup.string().required(),
        schema: Yup.string().required(),
        username: Yup.string().required(),
        warehouse: Yup.string(),
      }),
    [],
  );

  const handleSubmit: SnowflakeConnectorAuthFormikProps['onSubmit'] = useCallback(
    async (values) => {
      try {
        const authRequest = {
          account: values.account,
          database: values.database,
          password: values.password,
          schema: values.schema,
          username: values.username,
          warehouse: values.warehouse || undefined,
        };

        return await (!dataConnector
          ? createConnector({
              type: DataConnectorTypes.SNOWFLAKE,
              category: DataConnectorCategories.DATA,
              authType: AuthType.Snowflake_Connection_Auth,
              auth: authRequest,
            })
          : updateConnectorAuth({
              id: dataConnector.id,
              type: DataConnectorTypes.SNOWFLAKE,
              auth: authRequest,
            }));
      } catch (err) {
        if (isUnprocessableEntityError(err)) {
          throw new SubmissionError(
            <FormattedMessage defaultMessage="Authentication failed. Please check your credentials." />,
          );
        }

        throw err;
      }
    },
    [createConnector, dataConnector, updateConnectorAuth],
  );

  return (
    <FormikForm
      {...props}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </FormikForm>
  );
});
