import { IconPercentage } from '@tabler/icons-react';
import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Input } from '@amalia/design-system/components';
import { type CurrencyValue } from '@amalia/kernel/monetary/types';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';
import { getThePercentageOfValue } from '../../overwrite.utils';

import { messages } from './selectors.messages';
import { type CommonOverwriteSelectorProps } from './selectors.types';

export const OVERWRITE_INPUT_TEST_ID = `${OverwriteFormField.PERCENTAGE}-input`;

export type PercentOverwriteSelectorProps = CommonOverwriteSelectorProps & {
  readonly formFieldContext: OverwriteFormField.NEW_VALUE | OverwriteFormField.PERCENTAGE;
};

export const PercentOverwriteSelector = memo(function PercentOverwriteSelector({
  currentObjectDetails,
  formFieldContext,
}: PercentOverwriteSelectorProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    async (newValue: string) => {
      // If the field type is percentage, we need to calculate the new value
      if (OverwriteFormField.NEW_VALUE === formFieldContext) {
        await setFieldValue(OverwriteFormField.NEW_VALUE, newValue);
      } else {
        // If the field type is number / currency, we also propose the new value based on the percentage
        await setFieldValue(OverwriteFormField.PERCENTAGE, newValue);
        setFieldValue(
          OverwriteFormField.NEW_VALUE,
          `${getThePercentageOfValue(currentObjectDetails.oldValue as CurrencyValue | number, Number(newValue))}`,
        ).catch(() => {});
      }
    },
    [currentObjectDetails, formFieldContext, setFieldValue],
  );

  return (
    <Input
      autoFocus
      data-testid={OVERWRITE_INPUT_TEST_ID}
      id={formFieldContext}
      name={formFieldContext}
      placeholder={formatMessage(messages[FormatsEnum.percent])}
      rightIcon={<IconPercentage />}
      size={Input.Size.SMALL}
      value={`${values[formFieldContext]}`}
      onChange={handleChange}
    />
  );
});
