import { IconDots, IconPencil, IconTrash } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';
import { MenuDropdown, Table } from '@amalia/design-system/components';
import { useBoolState } from '@amalia/ext/react/hooks';

import { EditColumnFormModal } from './forms/edit-column/modal/EditColumnFormModal';

export type TableBuilderColumnActionsProps = {
  readonly column: VariableFormatOptionsColumn;
  readonly allColumns: VariableFormatOptionsColumn[];
  readonly hideDeleteAction?: boolean;
  readonly onChangeColumn: (
    machineName: VariableFormatOptionsColumn['machineName'],
    {
      name,
      format,
    }: {
      name: VariableFormatOptionsColumn['name'];
      format: VariableFormatOptionsColumn['format'];
    },
  ) => void;
  readonly onDeleteColumn: (machineName: VariableFormatOptionsColumn['machineName']) => void;
};

export const TableBuilderColumnActions = memo(function TableBuilderColumnActions({
  column,
  allColumns,
  hideDeleteAction,
  onChangeColumn,
  onDeleteColumn,
}: TableBuilderColumnActionsProps) {
  const { formatMessage } = useIntl();

  const { isEditModalOpen, setEditModalOpenTrue, setEditModalOpenFalse } = useBoolState(false, 'editModalOpen');

  const handleDeleteColumn = useCallback(
    () => onDeleteColumn(column.machineName),
    [onDeleteColumn, column.machineName],
  );

  const handleChangeColumn = useCallback(
    ({
      name,
      format,
    }: {
      name: VariableFormatOptionsColumn['name'];
      format: VariableFormatOptionsColumn['format'];
    }) => {
      onChangeColumn(column.machineName, { name, format });
      setEditModalOpenFalse();
    },
    [onChangeColumn, column.machineName, setEditModalOpenFalse],
  );

  return (
    <Fragment>
      <MenuDropdown
        content={
          <div>
            <MenuDropdown.Item
              data-testid="edit-column"
              icon={<IconPencil />}
              label={<FormattedMessage defaultMessage="Edit column" />}
              onClick={setEditModalOpenTrue}
            />

            {!hideDeleteAction && (
              <MenuDropdown.Item
                data-testid="delete-column"
                icon={<IconTrash />}
                label={<FormattedMessage defaultMessage="Delete column" />}
                onClick={handleDeleteColumn}
              />
            )}
          </div>
        }
      >
        <Table.Column.Action
          icon={<IconDots />}
          label={formatMessage({
            defaultMessage: 'Open actions',
          })}
        />
      </MenuDropdown>

      <EditColumnFormModal
        allColumns={allColumns}
        column={column}
        isOpen={isEditModalOpen}
        onClose={setEditModalOpenFalse}
        onSubmitSuccess={handleChangeColumn}
      />
    </Fragment>
  );
});
