import { head, last } from 'lodash';

import { assert } from '@amalia/ext/typescript';
import {
  type WorkflowStatementState,
  WorkflowStatementStateAction,
} from '@amalia/payout-collaboration/workflows/types';

import { type ComputedStatement } from '../types/computedStatement';
import { type Statement } from '../types/statement';

/**
 * Get statement current workflow step.
 */
export const getCurrentWorkflowStep = (statement: Statement): WorkflowStatementState | undefined =>
  last(statement.workflowSteps);

/**
 * Check if statement is being reviewed (workflow started).
 */
export const isBeingReviewed = (statement: Statement) => {
  const currentStep = getCurrentWorkflowStep(statement);
  return (currentStep && currentStep.to > 0) || statement.workflowComplete || false;
};

// The reference statement is:
// - If all statmeents are completed, and if all are on the same workflow, one of the list
// - If some are not completed, the first that is not completed IF all that are not completed are on the same workflow
// /!\ Make sure plan is loaded
// /!\ Reference statement is NOT the statement with lower step. For that, use the following method "getLowestStatement"
export const getReferenceStatement = (statements: Statement[]): Statement | undefined => {
  assert(
    statements.every((s) => s.plan),
    'Plan missing in statement',
  );
  if (statements.every((s) => s.workflowComplete)) {
    if (statements.every((s) => s.plan!.workflowId === statements[0].plan!.workflowId)) {
      return statements[0];
    }
    return undefined;
  }

  // Check for non completed statements if they have the same worfklow or not
  if (statements.every((s) => s.workflowComplete || s.plan!.workflowId === statements[0].plan!.workflowId)) {
    // If that's the case, return the workflow from the first non completed statement
    return statements.find((s) => !s.workflowComplete);
  }
  // Else some statements are not completed and are not all on the same workflow
  return undefined;
};

// A lowest statement is a statement which workflow is the same as the reference statement, but with the lowest progression on its workflow
export const getLowestStatements = (
  statements: Statement[],
  action: WorkflowStatementStateAction,
  referenceStatement: Statement,
): Statement[] => {
  let lowestStatements: Statement[] = [];

  if ([WorkflowStatementStateAction.NEXT, WorkflowStatementStateAction.FORCE].includes(action)) {
    // If reference statement is completed and we want to do a next, there's a problem
    if (referenceStatement.workflowComplete) {
      throw new Error("Can't do next to already completed statements");
    }

    // Get the smaller step number
    const statementsOnSameWorkflowAndNotCompleted = statements.filter(
      (s) => !s.workflowComplete && s.plan!.workflowId === referenceStatement.plan!.workflowId,
    );

    let stepToFilterOn = Infinity;
    lowestStatements = [];

    // We forEach each statement to get the list of statements that is the least advanced (= on smaller step)
    statementsOnSameWorkflowAndNotCompleted.forEach((s) => {
      const stepOfStatement = last(s.workflowSteps || [])?.to || 0;
      if (stepOfStatement === stepToFilterOn) {
        // We find a statement on the same step, pushing it
        lowestStatements.push(s);
      }
      if (stepOfStatement < stepToFilterOn) {
        // We find a statement below step, replacing everything
        stepToFilterOn = stepOfStatement;
        lowestStatements = [s];
      }
    });
  }

  if (action === WorkflowStatementStateAction.PREVIOUS) {
    // Get the smaller step number
    const statementsOnSameWorkflow = statements.filter(
      (s) => s.plan!.workflowId === referenceStatement.plan!.workflowId,
    );

    if (statementsOnSameWorkflow.every((s) => s.workflowComplete)) {
      lowestStatements = statementsOnSameWorkflow;
    } else {
      let stepToFilterOn = Infinity;
      lowestStatements = [] as Statement[];

      // We forEach each statement to get the list of statements that is the least advanced (= on smaller step)
      statementsOnSameWorkflow
        .filter((s) => !s.workflowComplete)
        .forEach((s) => {
          const stepOfStatement = last(s.workflowSteps || [])?.to || 0;
          if (stepOfStatement === stepToFilterOn) {
            // We find a statement on the same step, pushing it
            lowestStatements.push(s);
          }
          if (stepOfStatement < stepToFilterOn) {
            // We find a statement below step, replacing everything
            stepToFilterOn = stepOfStatement;
            lowestStatements = [s];
          }
        });
    }
  }

  return lowestStatements;
};

/**
 * Check if all statements are on the same workflow AND same step
 * @param statementList
 * @returns
 */
// All statements have the same workflow
// and if (all statements are at step 0 / steps are empty / steps are null) OR (the last validated step has the same TO)
// and if all statements are not finished
export const areAllStatementWorkflowSameAndSameStep = (statementList: Statement[]): boolean => {
  // No statements => no review button
  if ((statementList || []).length === 0) {
    return false;
  }

  // If one is complete and one is not => don't show the button
  const isOneCompletedAndOneNotFinished =
    statementList.some((s) => s.workflowComplete) && statementList.some((s) => !s.workflowComplete);
  if (isOneCompletedAndOneNotFinished) {
    return false;
  }

  // If some statement is not on the same workflow => don't show the button
  const isSameWorkflow = statementList.every((s) => s.plan!.workflowId === statementList[0].plan!.workflowId);
  if (!isSameWorkflow) {
    return false;
  }

  // If all statements are not begun yet / back at 0 => show the button
  const areAllStatementsNotBegunYet = statementList.every(
    (s) =>
      !s.workflowSteps || s.workflowSteps.length === 0 || !last(s.workflowSteps) || last(s.workflowSteps)!.to === 0,
  );
  if (areAllStatementsNotBegunYet) {
    return true;
  }

  // Now get the state of the first statement in the list
  const stepTo = last(statementList[0]?.workflowSteps || [])?.to;

  // We already managed the case if all statements are empty / back at 0.
  // If we find there's no stepTo here, it means that first statement is empty and others are not
  if (!stepTo) {
    return false;
  }

  // Finally, if all statements are at the same step, we can show the button.
  return statementList.every(
    (s) => s.workflowSteps && s.workflowSteps.length > 0 && last(s.workflowSteps)!.to === stepTo,
  );
};

export const getStatementUserTeam = (computedStatement: ComputedStatement): { id: string; name: string } => {
  const teams = (computedStatement?.computedTeams || []).map((t) => ({
    id: t.teamId,
    name: t.name,
  }));
  const teamRollup =
    computedStatement?.teamUsedDuringCalculationId &&
    teams.find((team) => team.id === computedStatement?.teamUsedDuringCalculationId);

  return teamRollup || head(teams)!;
};
