import { useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { Input } from '@amalia/design-system/components';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

import { messages } from './selectors.messages';

export const OVERWRITE_INPUT_TEST_ID = `${OverwriteFormField.NEW_VALUE}-input`;

export const TextOverwriteSelector = memo(function TextOverwriteSelector() {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleInputChange = useCallback(
    (newValue: string) => setFieldValue(OverwriteFormField.NEW_VALUE, newValue),
    [setFieldValue],
  );

  return (
    <Input
      autoFocus
      data-testid={OVERWRITE_INPUT_TEST_ID}
      id={OverwriteFormField.NEW_VALUE}
      name={OverwriteFormField.NEW_VALUE}
      placeholder={formatMessage(messages[FormatsEnum.text])}
      size={Input.Size.SMALL}
      value={values.newValue}
      onChange={handleInputChange}
    />
  );
});
