import { css } from '@emotion/react';
import { IconPlus } from '@tabler/icons-react';
import { lazy, memo, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { getDataConnectorName } from '@amalia/core/types';
import { type DataConnector } from '@amalia/data-capture/connectors/types';
import { ButtonLink, Skeleton, Stack, Typography } from '@amalia/design-system/components';

const ConnectorAuthFormSuccessSvg = lazy(async () => ({
  default: (await import('./ConnectorAuthFormSuccess.svg')).ReactComponent,
}));

export type ConnectorAuthFormSuccessProps = {
  readonly dataConnector: Pick<DataConnector, 'id' | 'type'>;
};

export const ConnectorAuthFormSuccess = memo(function ConnectorAuthFormSuccess({
  dataConnector,
}: ConnectorAuthFormSuccessProps) {
  return (
    <Stack
      align="center"
      gap={48}
    >
      <Suspense
        fallback={
          <Skeleton
            css={css`
              height: 189px;
              width: 340px;
            `}
          />
        }
      >
        <ConnectorAuthFormSuccessSvg />
      </Suspense>

      <Stack
        align="center"
        gap={24}
      >
        <Stack
          css={css`
            text-align: center;
          `}
        >
          <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
            <FormattedMessage
              defaultMessage="Successfully connected to {connectorType}."
              values={{ connectorType: getDataConnectorName(dataConnector) }}
            />
          </Typography>

          <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>
            <FormattedMessage defaultMessage="You can now add data objects for this connector." />
          </Typography>
        </Stack>

        <ButtonLink
          icon={<IconPlus />}
          to={generatePath(routes.DATA_CONNECTOR, { connectorType: dataConnector.type })}
          variant={ButtonLink.Variant.PRIMARY}
        >
          <FormattedMessage defaultMessage="Add a data object" />
        </ButtonLink>
      </Stack>
    </Stack>
  );
});
