import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { FormikInput, FormLayout, Modal, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import { PersonioConnectorAuthForm, type PersonioConnectorAuthFormProps } from '../PersonioConnectorAuthForm';

export type PersonioConnectorAuthFormModalProps = Omit<PersonioConnectorAuthFormProps, 'children'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const PersonioConnectorAuthFormModal = memo(function PersonioConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: PersonioConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <PersonioConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ isValid, status, isSubmitting }) => (
          <ConnectorAuthFormModalContent
            connectorType={DataConnectorTypes.PERSONIO}
            dataConnector={props.dataConnector}
            isSubmitting={isSubmitting}
            isValid={isValid}
            status={status as unknown}
          >
            <FormLayout>
              <FormLayout.Group>
                <FormikInput
                  autoFocus
                  required
                  label={<FormattedMessage defaultMessage="Client ID" />}
                  name="clientId"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Client secret" />}
                  name="clientSecret"
                />
              </FormLayout.Group>
            </FormLayout>
          </ConnectorAuthFormModalContent>
        )}
      </PersonioConnectorAuthForm>
    </Modal>
  );
});
