import { isBefore } from 'date-fns';
import { times } from 'lodash';
import { useMemo, useCallback } from 'react';

import { convertTimestampToDate, formatDate, reformatDateString } from '@amalia/core/types';
import { type Period, PeriodFrequencyEnum } from '@amalia/payout-definition/periods/types';
import {
  type PayoutAndPerformanceChartStatistics,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amalia/reporting/custom-reports/shared';

import { type PayoutAndPerformanceChartProps } from '../PayoutAndPerformanceChart';

const getRecordOrEmptyRecord = (month: number, year: string, records: PayoutAndPerformanceChartStatisticsRecord[]) => {
  const monthString = `${month}`.padStart(2, '0');
  const dateString = `${monthString}/${year}`;
  return (
    records.find((r) => r.ruleMetricPeriod__month === dateString) ||
    ({
      ruleMetricPeriod__month: dateString,
      ruleMetricPayment__value: null,
    } as PayoutAndPerformanceChartStatisticsRecord)
  );
};

// The statement summary chart is always on the same year, so we can fill empty months/quarters with empty values so the chart does not seem so empty.

// This hook fills gaps in the dataset.
export const useFillCurrentYearPeriodsRecords = ({
  statistics,
  frequency,
  period,
  isRecordActiveFn,
  enabled = true,
}: {
  statistics?: PayoutAndPerformanceChartStatistics;
  frequency?: PeriodFrequencyEnum;
  period?: Period;
  isRecordActiveFn?: PayoutAndPerformanceChartProps['isRecordActiveFn'];
  enabled?: boolean;
}) => {
  const filledStatistics = useMemo(() => {
    if (!statistics || !period) {
      return null;
    }

    // Get year number of current period.
    const year = formatDate(convertTimestampToDate(period.startDate), 'YYYY');

    switch (frequency) {
      case PeriodFrequencyEnum.month:
        return {
          ...statistics,
          // For all 12 months, fill with null values if the record is not present.
          records: times(12).map((index) => getRecordOrEmptyRecord(index + 1, year, statistics.records)),
        };

      case PeriodFrequencyEnum.quarter:
        return {
          ...statistics,
          // For each quarter, fill with null values if the record is not present.
          records: times(4).map((index) => getRecordOrEmptyRecord((index + 1) * 3, year, statistics.records)),
        };

      // Ignore year and null frequencies.
      case PeriodFrequencyEnum.year:
      default:
        return statistics;
    }
  }, [statistics, frequency, period]);

  const isOriginalRecordFn = useCallback(
    (record: PayoutAndPerformanceChartStatisticsRecord) =>
      !!statistics?.records.find((r) => r.ruleMetricPeriod__month === record.ruleMetricPeriod__month),
    [statistics],
  );

  // This marks records after the current period as inactive.
  const isRecordBeforeEndOfPeriod: Required<PayoutAndPerformanceChartProps>['isRecordActiveFn'] = useCallback(
    (record) =>
      !period ||
      isBefore(
        new Date(reformatDateString(record.ruleMetricPeriod__month, 'MM/YYYY', 'YYYY-MM-DD')),
        new Date(formatDate(convertTimestampToDate(period.endDate), 'YYYY-MM-DD')),
      ),
    [period],
  );

  // This marks records added for padding as inactive.
  const isRecordActiveFnProxy: Required<PayoutAndPerformanceChartProps>['isRecordActiveFn'] = useCallback(
    (record) =>
      isOriginalRecordFn(record) &&
      isRecordBeforeEndOfPeriod(record) &&
      (!isRecordActiveFn || isRecordActiveFn(record)),
    [isOriginalRecordFn, isRecordBeforeEndOfPeriod, isRecordActiveFn],
  );

  return enabled
    ? { statistics: filledStatistics, isRecordActiveFn: isRecordActiveFnProxy }
    : { statistics, isRecordActiveFn };
};
