import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { FormikInput, Modal, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import {
  MicrosoftDynamicsConnectorAuthForm,
  type MicrosoftDynamicsConnectorAuthFormProps,
} from '../MicrosoftDynamicsConnectorAuthForm';

export type MicrosoftDynamicsConnectorAuthFormModalProps = Omit<MicrosoftDynamicsConnectorAuthFormProps, 'children'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const MicrosoftDynamicsConnectorAuthFormModal = memo(function MicrosoftDynamicsConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: MicrosoftDynamicsConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <MicrosoftDynamicsConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ isValid, status, isSubmitting }) => (
          <ConnectorAuthFormModalContent
            connectorType={DataConnectorTypes.MSDYNAMICS}
            dataConnector={props.dataConnector}
            isRunningOAuthFlow={isSubmitting}
            isSubmitting={isSubmitting}
            isValid={isValid}
            status={status as unknown}
          >
            <FormikInput
              autoFocus
              required
              label={<FormattedMessage defaultMessage="URL" />}
              name="tenantId"
              placeholder="https://my-company.crm12.dynamics.com" // eslint-disable-line formatjs/no-literal-string-in-jsx -- chill bro it's an url
            />
          </ConnectorAuthFormModalContent>
        )}
      </MicrosoftDynamicsConnectorAuthForm>
    </Modal>
  );
});
