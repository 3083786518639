import moment from 'moment';
import { memo, type MouseEventHandler } from 'react';

import { formatUserFullName, formatValueOrPrintRemovedLabel, type Overwrite } from '@amalia/core/types';
import {
  FieldValuePrettyFormat,
  getPrettyFormatProps,
  isFieldValuePrettyFormatCompatible,
  LabelVariant,
} from '@amalia/data-capture/fields/components';
import { type FormatsEnum, type PropertyRef } from '@amalia/data-capture/fields/types';
import { OverwriteTooltip } from '@amalia/data-correction/overwrites/components';
import { Tooltip } from '@amalia/design-system/components';
import { type StatementThread } from '@amalia/payout-collaboration/comments/types';

type RowsTableCellContentProps = {
  readonly overwrite: Overwrite;
  readonly formattedValue: number | string;
  readonly cellStatementThread: StatementThread;
  readonly clearOverwriteProxy: MouseEventHandler<HTMLButtonElement>;
  readonly isReadOnly: boolean;
  readonly formattedOverwriteValue: number | string;
  readonly formattedOverwriteSourceValue: number | string;
  readonly openStatementThread: () => void;
  readonly format: FormatsEnum;
  readonly propertyRef: PropertyRef;
  readonly truncatedValue: number | string;
  readonly rawValue: unknown;
};

export const RowsTableCellContent = memo(function RowsTableCellContent({
  overwrite,
  formattedValue,
  cellStatementThread,
  clearOverwriteProxy,
  isReadOnly,
  formattedOverwriteValue,
  formattedOverwriteSourceValue,
  openStatementThread,
  format,
  propertyRef,
  truncatedValue,
  rawValue,
}: RowsTableCellContentProps) {
  const cellContent = isFieldValuePrettyFormatCompatible(format, propertyRef) ? (
    <FieldValuePrettyFormat
      {...getPrettyFormatProps(format, rawValue, propertyRef)}
      valueRef={propertyRef}
      variant={overwrite ? LabelVariant.ACCENTUATED : LabelVariant.DEFAULT}
    />
  ) : overwrite ? (
    formatValueOrPrintRemovedLabel(formattedValue)
  ) : (
    formattedValue
  );

  if (overwrite) {
    return (
      <OverwriteTooltip
        author={formatUserFullName(overwrite.creator)}
        cellStatementThread={cellStatementThread}
        date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
        handleOnClick={clearOverwriteProxy}
        isReadOnly={isReadOnly}
        newValue={formattedValue}
        newValueRaw={formattedOverwriteValue}
        oldValue={formattedOverwriteSourceValue}
        openStatementThread={openStatementThread}
        valueFormat={format}
        valueRef={propertyRef}
      >
        <div data-is-overwritten="true">{cellContent}</div>
      </OverwriteTooltip>
    );
  }
  if (truncatedValue) {
    return (
      <Tooltip content={truncatedValue}>
        <div>{cellContent}</div>
      </Tooltip>
    );
  }
  return cellContent;
});
