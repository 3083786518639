export enum QUOTAS_KEYS {
  QUOTA_WITH_ASSIGNED_PLANS = 'quota-with-assigned-plans',
  QUOTAS_WITH_ASSIGNED_PLANS = 'quotas-with-assigned-plans',
}

export enum QUOTA_ASSIGNMENTS_KEYS {
  QUOTA_ASSIGNMENTS = 'quota-assignments',
  USER_QUOTA_ASSIGNMENTS = 'user-quota-assignments',
}

export enum QUOTA_ASSIGNMENTS_MUTATION_KEYS {
  UPSERT_QUOTA_ASSIGNMENT = 'upsert-quota-assignment',
  UPSERT_QUOTAS_ASSIGNMENTS = 'upsert-quotas-assignments',
  DELETE_QUOTA_ASSIGNMENTS = 'delete-quota-assignments',
  DELETE_QUOTA_ASSIGNMENT = 'delete-quota-assignment',
}
