import { Global, type Theme, css } from '@emotion/react';

const is1stApril = new Date().getMonth() === 3 && new Date().getDate() === 1;
export const robotoGFontsQS = is1stApril ? 'family=Comic+Neue:wght@400;500;700' : 'family=Roboto:wght@400;500;700';
export const robotoMonoGFontsQS = 'family=Roboto+Mono:wght@400';

export const DEFAULT_THEME_GLOBAL_STYLES: Theme['ds']['globalStyles'] = (
  <Global
    styles={(theme) => css`
      @import url('https://fonts.googleapis.com/css2?${robotoGFontsQS}&${robotoMonoGFontsQS}&display=swap');

      /* Reset gutters. */
      *,
      *::after,
      *::before {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }

      html,
      body {
        // Set in index.html. Do not set on #root because of z-index issues.
        background: var(--app-background-color);
      }

      html,
      body,
      #root {
        font-family: ${theme.ds.typographies.bodyBaseRegular.fontFamily};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        height: 100%;
        width: 100%;

        color: ${theme.ds.colors.gray[900]};
      }
    `}
  />
);
