import { isArrayNode } from 'mathjs';
import { useCallback, useMemo } from 'react';

import { commonMathJs } from '@amalia/amalia-lang/amalia-mathjs';
import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';

import { type TableBuilderParsedRow } from '../TableBuilder.types';

import { useGenerateColumn } from './use-generate-column';

const parsedRowToFormula = (row: TableBuilderParsedRow) => `[${row.join(',')}]`;
const parsedRowsToFormula = (rows: TableBuilderParsedRow[]) => `[${rows.map(parsedRowToFormula).join(',')}]`;

// 3 empty rows.
const EMPTY_TABLE: TableBuilderParsedRow[] = [['0'], ['0'], ['0']];

/**
 * This hook parses the formula string into a 2D table.
 * It also syncs the columns metadata with the parsed table (based on the first row length).
 */
export const useParseTableBuilderFormula = ({
  formula,
  columns: maybeColumns,
  onChange,
}: {
  readonly formula: AmaliaFormula;
  readonly columns?: VariableFormatOptionsColumn[];
  readonly onChange?: (formula: AmaliaFormula) => void;
}) => {
  const generateColumn = useGenerateColumn();

  /**
   * Parse the formula string into a 2D table.
   */
  const parsedRows = useMemo(() => {
    try {
      const parsedFormula = commonMathJs.parse(formula);

      if (!isArrayNode(parsedFormula)) {
        // Formula is invalid. Reset the table.
        return EMPTY_TABLE;
      }

      const rows: TableBuilderParsedRow[] = parsedFormula.items
        .map((row) => (isArrayNode(row) ? row.items.map((item) => item.toString()) : null))
        .filter(Boolean);

      return rows.length ? rows : EMPTY_TABLE;
    } catch (err) {
      // Formula is invalid. Reset the table.
      return EMPTY_TABLE;
    }
  }, [formula]);

  const sanitizedColumns = useMemo(() => {
    const realColumnsCount = parsedRows[0]?.length;
    const columns = [...(maybeColumns ?? [])];

    // If the columns count is less than the real columns count, fill the rest with empty columns.
    for (let i = columns.length; i < realColumnsCount; i++) {
      columns.push(generateColumn(columns));
    }

    // If the columns count is more than the real columns count, remove the extra columns.
    if (columns.length > realColumnsCount) {
      columns.splice(realColumnsCount);
    }

    // If there are no columns, generate a new one.
    return columns.length ? columns : [generateColumn(columns)];
  }, [maybeColumns, parsedRows, generateColumn]);

  const handleChange = useCallback(
    (rows: TableBuilderParsedRow[]) => onChange?.(parsedRowsToFormula(rows)),
    [onChange],
  );

  return {
    parsedRows,
    columns: sanitizedColumns,
    onChange: handleChange,
  };
};
