import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, type Interpolation, type Theme, useTheme } from '@emotion/react';
import { IconGripVertical } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, type MouseEventHandler, type ReactNode, useCallback } from 'react';

import { Tooltip, Typography, UnstyledButton, type UnstyledButtonProps } from '@amalia/design-system/components';
import { ForecastAmount } from '@amalia/payout-calculation/forecasts/components';

import * as styles from './KpiRowItem.styles';

export type KpiRowItemProps = Omit<UnstyledButtonProps, 'name' | 'value'> & {
  readonly kpiActions?: ReactNode;
  readonly kpiOnClick?: () => void;
  readonly kpiIcon?: ReactNode;
  readonly kpiStyles?: Interpolation<Theme>;
  readonly name: ReactNode;
  readonly dataKpiKey: string;
  readonly rawValue?: number | string;
  readonly value: ReactNode;
  readonly isForecasted?: boolean;
  readonly isDraggable?: boolean;
  readonly elementId?: string;
  readonly hideActions?: boolean;
  readonly onRightClick?: () => void;
};

export const KpiRowItem = memo(function KpiRowItem({
  kpiActions,
  kpiOnClick,
  kpiIcon,
  kpiStyles,
  name,
  dataKpiKey,
  rawValue,
  value,
  isForecasted = false,
  isDraggable = false,
  elementId,
  hideActions,
  onRightClick,
  ...buttonProps
}: KpiRowItemProps) {
  const theme = useTheme();
  const kpiContainerStyles = [styles.kpiContainer, styles.isButton(!!kpiOnClick), kpiStyles].filter(Boolean);

  const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging, transition } = useSortable({
    id: elementId!,
    disabled: !isDraggable,
  });

  const isKpiDragging = isDraggable && isDragging;

  const ValueComponent = isForecasted ? ForecastAmount : Typography;

  const onRightClickProxy: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
      onRightClick?.();
    },
    [onRightClick],
  );

  return (
    <div
      ref={setNodeRef}
      data-kpi={`${dataKpiKey} Container`}
      css={[
        styles.container,
        css`
          transform: ${CSS.Translate.toString(transform)};
          transition: ${[transition, theme.ds.transitions.default('box-shadow')].filter(Boolean).join(', ')};
          z-index: ${isKpiDragging ? 1 : 0};
          box-shadow: ${isKpiDragging ? theme.ds.shadows.hard : 'none'};
          background-color: ${isKpiDragging ? theme.ds.colors.gray[0] : 'transparent'};
        `,
      ]}
      onContextMenu={onRightClickProxy}
    >
      {!!isDraggable && (
        <div
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
          css={styles.grabHandle}
        >
          <IconGripVertical
            color={theme.ds.colors.gray[500]}
            size={16}
          />
        </div>
      )}
      <UnstyledButton
        {...buttonProps}
        css={kpiContainerStyles}
        data-is-forecasted={isForecasted}
        data-kpi={dataKpiKey}
        onClick={kpiOnClick || noop}
      >
        <div css={styles.nameContainer}>
          {kpiIcon}
          <Typography variant={Typography.Variant.BODY_LARGE_REGULAR}>{name}</Typography>
        </div>

        <div css={styles.divider} />

        <ValueComponent variant={Typography.Variant.BODY_LARGE_MEDIUM}>
          {rawValue ? (
            <Tooltip content={<span>{rawValue}</span>}>
              <span>{value}</span>
            </Tooltip>
          ) : (
            value
          )}
        </ValueComponent>
      </UnstyledButton>
      <div
        id="actions-container"
        css={[
          styles.actionsContainer,
          css`
            & > :not(:first-child) {
              visibility: ${isKpiDragging && 'hidden !important'};
            }
            & * {
              visibility: ${hideActions && 'hidden !important'};
            }
          `,
        ]}
      >
        {kpiActions}
      </div>
    </div>
  );
});
