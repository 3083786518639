import * as Yup from 'yup';

import { type CalculationParser, type CalculationScope } from '@amalia/amalia-lang/formula/evaluate/shared';
import { type AmaliaFormula } from '@amalia/amalia-lang/formula/types';
import { type CustomObjectDefinition } from '@amalia/data-capture/record-models/types';

export const yupValidateFormula = (calculationParser: CalculationParser, scope?: CalculationScope, object?: string) =>
  Yup.string().test({
    name: 'formulaValidation',
    test(value?: string) {
      const { path, createError } = this;

      // Eventually replace row with its equivalent definition.
      const formulaToEvaluate = object && value ? value.replace(/\$row/gu, object) : value;

      const result = calculationParser.formulaHasError(formulaToEvaluate as AmaliaFormula, scope);

      return result === ''
        ? true
        : createError({
            message: result,
            path,
          });
    },
  });

export const yupValidatePostFetchFormula = (
  calculationParser: CalculationParser,
  {
    scope,
    isRequired = true,
  }: {
    scope?: CalculationScope;
    isRequired?: boolean;
    includeDollarRows?: boolean;
  } = {},
) =>
  Yup.string().test({
    name: 'postFetchFormulaValidation',
    test(value?: string) {
      if (!isRequired && !value) {
        return true;
      }

      const { path, createError } = this;

      const result = calculationParser.formulaHasError(value as AmaliaFormula, scope);

      return (
        result === '' ||
        createError({
          message: result,
          path,
        })
      );
    },
  });

export const yupValidateCondition = (
  calculationParser: CalculationParser,
  scope?: CalculationScope,
  customObjects?: CustomObjectDefinition[],
  objectIdFieldName?: string,
  isRequired: boolean = true,
  mustContainKeywords?: string[],
) =>
  Yup.string().test({
    name: 'formulaValidationBoolean',
    test(value?: string | null) {
      if (!isRequired && (value === null || value === undefined || value === '')) {
        return true;
      }

      const { path, createError } = this;

      const keywordsError: string[] = [];
      (mustContainKeywords || []).forEach((keyword) => {
        if (!value?.includes(keyword)) {
          keywordsError.push(keyword);
        }
      });
      if (keywordsError.length > 0) {
        const keywordErrorMapper = (k: string) => `"${k}"`;
        const keywordErrorsFormatted = keywordsError.length > 1 ? 'are' : 'is';
        return createError({
          message: `${keywordsError.map(keywordErrorMapper).join(', ')} ${keywordErrorsFormatted} mandatory`,
          path,
        });
      }

      // Eventually replace row with its equivalent definition.
      const customObjectMachineName = objectIdFieldName
        ? customObjects?.find(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- thanks yup
            (co) => co.id === this.parent[objectIdFieldName] || co.machineName === this.parent[objectIdFieldName],
          )?.machineName
        : null;

      const formulaToEvaluate =
        customObjectMachineName && value ? value.replace(/\$row/gu, customObjectMachineName) : value;

      // Validate first that formula doesn't contain error, return the error if that's the case.
      const hasError = calculationParser.formulaHasError(formulaToEvaluate as AmaliaFormula, scope);
      if (hasError !== '') {
        return createError({
          message: hasError,
          path,
        });
      }

      // Evaluate the formula with the stubs and get results.
      const result = calculationParser.computeFormula(formulaToEvaluate as AmaliaFormula, scope);

      // Result should be a boolean, or corresponding number (0 or 1) or else it's invalid.
      return result === true || result === false || result === 0 || result === 1
        ? true
        : createError({
            message: 'Returned value is not a boolean (or 0 / 1), thus is not applicable to a filter / link',
            path,
          });
    },
  });
