import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { DataConnectorTypes } from '@amalia/data-capture/connectors/types';
import { FormikInput, FormLayout, Modal, type ModalProps } from '@amalia/design-system/components';

import { ConnectorAuthFormModalContent } from '../../common/modal-content/ConnectorAuthFormModalContent';
import { SnowflakeConnectorAuthForm, type SnowflakeConnectorAuthFormProps } from '../SnowflakeConnectorAuthForm';

export type SnowflakeConnectorAuthFormModalProps = Omit<SnowflakeConnectorAuthFormProps, 'children'> &
  Pick<ModalProps, 'isOpen' | 'onClose'>;

export const SnowflakeConnectorAuthFormModal = memo(function SnowflakeConnectorAuthFormModal({
  isOpen,
  onClose,
  ...props
}: SnowflakeConnectorAuthFormModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <SnowflakeConnectorAuthForm
        {...props}
        onSubmitSuccess={props.dataConnector ? onClose : undefined}
      >
        {({ isValid, status, isSubmitting }) => (
          <ConnectorAuthFormModalContent
            connectorType={DataConnectorTypes.SNOWFLAKE}
            dataConnector={props.dataConnector}
            isSubmitting={isSubmitting}
            isValid={isValid}
            status={status as unknown}
          >
            <FormLayout>
              <FormLayout.Group>
                <FormikInput
                  autoFocus
                  required
                  label={<FormattedMessage defaultMessage="Account" />}
                  name="account"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Username" />}
                  name="username"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Password" />}
                  name="password"
                />

                <FormikInput
                  label={<FormattedMessage defaultMessage="Warehouse" />}
                  name="warehouse"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Database" />}
                  name="database"
                />

                <FormikInput
                  required
                  label={<FormattedMessage defaultMessage="Schema" />}
                  name="schema"
                />
              </FormLayout.Group>
            </FormLayout>
          </ConnectorAuthFormModalContent>
        )}
      </SnowflakeConnectorAuthForm>
    </Modal>
  );
});
