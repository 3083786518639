import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTokenFormatOptions } from '@amalia/data-capture/fields/components';
import { FormikInput, FormikSelect, FormLayout, Modal, type ModalProps } from '@amalia/design-system/components';
import { FormikForm } from '@amalia/ext/formik';

import { EditColumnForm, type EditColumnFormProps } from '../EditColumnForm';

export type EditColumnFormModalProps = Omit<EditColumnFormProps, 'children'> & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const EditColumnFormModal = memo(function EditColumnFormModal({
  isOpen,
  onClose,
  ...props
}: EditColumnFormModalProps) {
  const formatOptions = useTokenFormatOptions();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <EditColumnForm {...props}>
        {({ isSubmitting, isValid }) => (
          <FormikForm.FormDisplayContents>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage defaultMessage="Edit column" />
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <FormLayout>
                  <FormLayout.Group>
                    <FormikInput
                      required
                      label={<FormattedMessage defaultMessage="Name" />}
                      name="name"
                    />

                    <FormikSelect
                      required
                      id="format"
                      label={<FormattedMessage defaultMessage="Format" />}
                      name="format"
                      options={formatOptions}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Modal.Body>
            </Modal.Content>

            <Modal.Actions>
              <Modal.CancelAction />

              <Modal.MainAction
                disabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                <FormattedMessage defaultMessage="Confirm" />
              </Modal.MainAction>
            </Modal.Actions>
          </FormikForm.FormDisplayContents>
        )}
      </EditColumnForm>
    </Modal>
  );
});
