import { useIsFetching } from '@tanstack/react-query';
import { useState } from 'react';

import { QUOTAS_KEYS } from '@amalia/assignments/quotas/state';
import { CUSTOM_OBJECT_DEFINITION_KEYS } from '@amalia/data-capture/record-models/state';
import { useBoolState } from '@amalia/ext/react/hooks';
import { CUSTOM_REPORTS_KEYS } from '@amalia/reporting/custom-reports/state';
import { DASHBOARDS_QUERY_KEYS } from '@amalia/reporting/dashboards-v2/state';
import { SUPER_ADMIN_QUERY_KEYS } from '@amalia/superadmin/state';
import { TEAM_QUERY_KEYS } from '@amalia/tenants/teams/state';
import { USER_PROFILE_QUERY_KEYS } from '@amalia/tenants/users/profile/state';

import { type PagesEnum } from './commands/shortcut-commands.types';

const SHORTCUT_COMMANDS_QUERY_KEYS = [
  CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS,
  CUSTOM_REPORTS_KEYS.CUSTOM_REPORTS,
  SUPER_ADMIN_QUERY_KEYS.SA_LIST_PLANS,
  USER_PROFILE_QUERY_KEYS.PROFILE,
  SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES,
  TEAM_QUERY_KEYS.TEAMS,
  DASHBOARDS_QUERY_KEYS.DASHBOARDS,
  QUOTAS_KEYS.QUOTAS_WITH_ASSIGNED_PLANS,
] as const;

export const useShortcutCommands = () => {
  const [search, setSearch] = useState('');
  const [pages, setPages] = useState<PagesEnum[]>([]);
  const { isCommandsModalOpen, setCommandsModalOpen, toggleCommandsModalOpen, setCommandsModalOpenFalse } =
    useBoolState(false, 'commandsModalOpen');
  const page = pages[pages.length - 1];

  const isCommandsLoading =
    useIsFetching({
      predicate: (query) =>
        SHORTCUT_COMMANDS_QUERY_KEYS.includes(query.queryKey[0] as (typeof SHORTCUT_COMMANDS_QUERY_KEYS)[number]),
    }) > 0;

  return {
    search,
    setSearch,
    pages,
    setPages,
    page,
    isCommandsModalOpen,
    setCommandsModalOpen,
    toggleCommandsModalOpen,
    setCommandsModalOpenFalse,
    isCommandsLoading,
  };
};
