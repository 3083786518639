import { subject } from '@casl/ability';
import { isEmpty, noop } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import { RightSidebarPortal } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { useShallowObjectMemo } from '@amalia/ext/react/hooks';
import { fetchUsers, selectCurrentStatement, selectUsersMap, useThunkDispatch } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext, useCurrentUser } from '@amalia/kernel/auth/state';
import {
  CommentDrawerPresentation,
  CommentMessage,
  EditableStatementCommentContext,
  type EditableStatementCommentContextValue,
  NoCommentsFound,
  useCommentScope,
} from '@amalia/payout-collaboration/comments/components';
import {
  useReviewStatementThreadMutation,
  useStatementThreadMessages,
} from '@amalia/payout-collaboration/comments/state';
import {
  type CommentThreadMessage,
  type MessageContent,
  type StatementThread,
  type StatementThreadScope,
} from '@amalia/payout-collaboration/comments/types';
import { type UsersMap } from '@amalia/tenants/users/types';

interface CommentDrawerContainerProps {
  readonly statementThread?: StatementThread;
  readonly statementThreadScope?: StatementThreadScope;
  readonly handleNewMessage: (messageContent: MessageContent[], scope?: StatementThreadScope) => Promise<void>;
  readonly closeDrawer: () => void;
}

// Shows an entire comment message thread
export const CommentDrawerContainer = memo(function CommentDrawerContainer({
  statementThread,
  statementThreadScope,
  handleNewMessage,
  closeDrawer,
}: CommentDrawerContainerProps) {
  const dispatch = useThunkDispatch();

  const statement = useSelector(selectCurrentStatement);
  const navigate = useNavigate();
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
  const { data: currentUser } = useCurrentUser();

  const [editedCommentId, setEditedCommentId] = useState<string | undefined>(undefined);
  const [editedCommentContent, setEditedCommentContent] = useState<MessageContent[]>([]);

  // Get the users map to have messages' authors infos
  const threadUsers: UsersMap = useSelector(selectUsersMap);

  const closeOpenedThread = useCallback(() => {
    closeDrawer();
    // change the URL to close it.
    // If there's an threadId, it will dispatch an action to close it
    navigate(generatePath(routes.STATEMENT, { id: statement.id }));
  }, [navigate, statement.id, closeDrawer]);

  // Sorted comments
  const { data: sortedComments } = useStatementThreadMessages(statement?.id, statementThread?.id);
  useEffect(() => {
    // If we have a thread, load related data
    if (!isEmpty(sortedComments)) {
      // Compute authors ids and fetch associated users
      const userIds = sortedComments.map((msg: CommentThreadMessage) => msg.authorId);

      dispatch(fetchUsers(userIds)).catch(noop);
    }
  }, [sortedComments, dispatch]);

  // Current thread scope (variable / cell), undefined if the scope is global
  const scope = statementThread?.computedScope || statementThreadScope || undefined;

  // Handle new comment form
  const handleNewComment = useCallback(
    async (commentMessage: MessageContent[]): Promise<void> => {
      // Add a message on this thread
      await Promise.all([
        // Post the message.
        handleNewMessage(commentMessage, scope),
        // Also fetch me, now than i'm part of the discussion.
        dispatch(fetchUsers([currentUser.id])),
      ]);
    },
    [handleNewMessage, currentUser, dispatch, scope],
  );

  useEffect(() => {
    const scrollToBottom = () => {
      endOfMessagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    scrollToBottom();
  }, [sortedComments]);

  const { mutateAsync: reviewStatementThread } = useReviewStatementThreadMutation();
  const handleReview = async (checked: boolean) => {
    if (!statement?.id || !statementThread?.id) {
      return;
    }

    // Mark thread as reviewed.
    await reviewStatementThread({
      statementId: statement.id,
      statementThreadId: statementThread.id,
      isReviewed: checked,
    });
  };

  const threadDetails = useCommentScope(statement, scope);

  const ability = useAbilityContext();

  const canReviewThread = useMemo(
    () =>
      statementThread?.id &&
      ability.can(
        ActionsEnum.review_statement_thread,
        subject(SubjectsEnum.Statement, { statement, isReviewed: !statementThread.thread.isReviewed }),
      ),
    [statement, statementThread, ability],
  );

  const canAddComment = useMemo(
    () => ability.can(ActionsEnum.add_statement_comments, subject(SubjectsEnum.Statement, statement)),
    [statement, ability],
  );

  const editableContextValues: EditableStatementCommentContextValue = useShallowObjectMemo({
    statementThreadId: statementThread?.id,
    editedCommentId,
    editedCommentContent,
    setEditedCommentId,
    setEditedCommentContent,
  });

  return (
    <RightSidebarPortal>
      <EditableStatementCommentContext.Provider value={editableContextValues}>
        <CommentDrawerPresentation
          canAddComment={canAddComment}
          canReviewThread={canReviewThread}
          closeOpenedThread={closeOpenedThread}
          commentScope={threadDetails}
          handleNewComment={handleNewComment}
          isReviewed={statementThread?.thread.isReviewed || false}
          setIsReviewed={handleReview}
        >
          {sortedComments?.map((message: CommentThreadMessage) => (
            <CommentMessage
              key={message.id}
              author={threadUsers[message.authorId]}
              message={message}
              statementId={statement.id}
              statementThreadId={statementThread?.id}
            />
          ))}
          <div
            ref={endOfMessagesRef}
            style={{ paddingBottom: '15px' }}
          />

          {!sortedComments?.length && <NoCommentsFound />}
        </CommentDrawerPresentation>
      </EditableStatementCommentContext.Provider>
    </RightSidebarPortal>
  );
});
