import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { CUSTOM_OBJECTS_KEYS } from '@amalia/data-capture/records/state';

import { CustomObjectDefinitionsApiClient } from '../api-client/custom-object-definitions.api-client';

import { CUSTOM_OBJECT_DEFINITION_KEYS } from './queries.keys';

export const useRealCustomObjectDefinitions = () =>
  useQuery({
    queryKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS, 'list-real'],
    queryFn: CustomObjectDefinitionsApiClient.list,
  });

export const useVirtualCustomObjectDefinitions = () =>
  useQuery({
    queryKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS, 'list-virtual'],
    queryFn: CustomObjectDefinitionsApiClient.listVirtual,
  });

export const useCustomObjectDefinitions = () => {
  const realResult = useRealCustomObjectDefinitions();
  const virtualResult = useVirtualCustomObjectDefinitions();

  return useMemo(
    () => ({
      data: [...(realResult.data || []), ...(virtualResult.data || [])],
      isError: realResult.isError || virtualResult.isError,
    }),
    [realResult, virtualResult],
  );
};

export const useDeleteCustomObjectDefinition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS, 'delete'],
    mutationFn: (args: Parameters<(typeof CustomObjectDefinitionsApiClient)['delete']>) =>
      CustomObjectDefinitionsApiClient.delete(...args),
    onSuccess: async (_, [objectDefinition]) => {
      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECT_DEFINITION_KEYS.CUSTOM_OBJECT_DEFINITIONS],
      });

      await queryClient.invalidateQueries({
        queryKey: [CUSTOM_OBJECTS_KEYS.CUSTOM_OBJECTS_PAGE, objectDefinition.machineName],
      });
    },
  });
};
