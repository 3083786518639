import { type IntlShape } from 'react-intl';
import * as Yup from 'yup';

import {
  FormulaBuilderBlockType,
  FormulaBuilderFunctionCategory,
  type ValueOrAttribute,
  type FormulaBuilderFunctionBlockUser,
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
} from '@amalia/amalia-lang/formula/types';
import { isEnum } from '@amalia/ext/typescript';

import { yupFormulaBuilderFunctionStringArgs } from './yupValidatorFormulaBuilderFunctionString';

const yupFormulaBuilderFunctionUserNoArgs = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttribute) => yupFormulaBuilderFunctionStringArgs(arg)))
      .max(1)
      .min(1),
    caseSensitive: Yup.boolean().required(),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.USER]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderUserOperatorNoArgs)),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

const yupFormulaBuilderFunctionUserOneArg = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttribute) => yupFormulaBuilderFunctionStringArgs(arg)))
      .length(2),
    caseSensitive: Yup.boolean().required(),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.USER]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderUserOperatorOneArg)),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

/** Date function formulaBuilder validator */
export const yupFormulaBuilderFunctionUser = (userBlock: FormulaBuilderFunctionBlockUser, intl: IntlShape) => {
  switch (true) {
    // null is needed here because as long as we don't have an operator
    // we don't know which validator to use
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- it's a validator, it validates.
    case userBlock.operator === null:
      return Yup.object().shape({
        isDraft: Yup.boolean().required().oneOf([false]),
      });
    case isEnum(userBlock.operator, FormulaBuilderUserOperatorNoArgs):
      return yupFormulaBuilderFunctionUserNoArgs();
    case isEnum(userBlock.operator, FormulaBuilderUserOperatorOneArg):
      return yupFormulaBuilderFunctionUserOneArg();
    default:
      throw new Error(intl.formatMessage({ defaultMessage: 'User function block operator not handled for now' }));
  }
};
