import { css } from '@emotion/react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { Breadcrumbs, PageHeader, Stack, Typography } from '@amalia/design-system/components';
import { TypographyVariant } from '@amalia/design-system/meta';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { useAbilityContext } from '@amalia/kernel/auth/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';

import { ReactComponent as NoDashboardIllustration } from './assets/nodashboard.svg';

export const DashboardV2DetailsForbiddenLayout = memo(function DashboardV2DetailsForbiddenLayout() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const authenticatedContext = useAbilityContext();
  const { isFeatureEnabled: isCustomDashboardEnabled } = useFeatureFlag(CompanyFeatureFlags.CUSTOM_DASHBOARDS);

  const handleClickGoBack = useCallback(() => {
    if (isCustomDashboardEnabled && authenticatedContext.can(ActionsEnum.view, SubjectsEnum.DashboardsV2)) {
      navigate(generatePath(routes.DASHBOARDS_V2));
    } else {
      navigate(generatePath(routes.HOME));
    }
  }, [authenticatedContext, isCustomDashboardEnabled, navigate]);

  return (
    <Fragment>
      <PageHeader>
        <PageHeader.Row
          left={
            <Breadcrumbs
              back={
                <Breadcrumbs.BackButton
                  label={formatMessage({ defaultMessage: 'Go back' })}
                  css={css`
                    height: 36px;
                  `}
                  onClick={handleClickGoBack}
                />
              }
            />
          }
        />
      </PageHeader>
      <Stack
        align="center"
        gap={24}
        justify="center"
        css={css`
          margin-top: 104px;
        `}
      >
        <NoDashboardIllustration />
        <Typography variant={TypographyVariant.HEADING_2_MEDIUM}>
          <FormattedMessage defaultMessage="You do not have access to this dashboard." />
        </Typography>
      </Stack>
    </Fragment>
  );
});
