import styled from '@emotion/styled/macro';
import { IconCopy, IconPencil } from '@tabler/icons-react';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { Dropdown } from '@amalia/design-system/components';
import { useClipboard } from '@amalia/design-system/ext';

import { type FormulaEditorToken } from '../../../../types/formulaEditorToken';
import { useFormulaEditorContext } from '../FormulaEditor.context';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 8px;
`;

type TokenNodePopoverActions = {
  readonly token: FormulaEditorToken;
};

export const TokenNodePopoverActions = memo(function TokenNodePopoverActions({ token }: TokenNodePopoverActions) {
  const { openTokenInTabHandler } = useFormulaEditorContext();
  const { formatMessage } = useIntl();
  const { copy } = useClipboard();

  const openInTabHandler = useCallback(() => {
    openTokenInTabHandler(token);
  }, [openTokenInTabHandler, token]);

  const copyToClipboardHandler = useCallback(() => {
    if (!token.tooltipContent.title) {
      return;
    }

    copy(token.tooltipContent.title);
  }, [copy, token]);
  return (
    <ActionsContainer>
      <Dropdown.ItemButton
        icon={<IconCopy />}
        label={formatMessage({ defaultMessage: 'Copy machine name to clipboard' })}
        onClick={copyToClipboardHandler}
      />

      <Dropdown.ItemButton
        icon={<IconPencil />}
        label={formatMessage({ defaultMessage: 'Open in tab' })}
        onClick={openInTabHandler}
      />
    </ActionsContainer>
  );
});
