import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { type VariableFormatOptionsColumn } from '@amalia/amalia-lang/tokens/types';
import { StringUtils } from '@amalia/core/types';
import { FormatsEnum } from '@amalia/data-capture/fields/types';

export const columnExists = (columns: VariableFormatOptionsColumn[], name: string) =>
  columns.some((column) => column.name === name || column.machineName === StringUtils.camelCase(name));

export const useGenerateColumn = () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (columns: VariableFormatOptionsColumn[]) => {
      let i = 0;
      let name: string;

      do {
        name = formatMessage({ defaultMessage: 'Column {index}' }, { index: ++i });
      } while (columnExists(columns, name));

      return {
        name,
        machineName: StringUtils.camelCase(name),
        format: FormatsEnum.percent,
      };
    },
    [formatMessage],
  );
};
